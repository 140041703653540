import { useEffect } from "react";

const PublicOfferCourier = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">УБЛИЧНАЯ ОФЕРТА ОКАЗАНИЯ УСЛУГ КУРЬЕРАМИ
        ТОО "DOM FOOD (ДОМ ФУД)"</h1>
      <p className="mt-4">
        Настоящая оферта Товарищества с ограниченной ответственностью «DOM FOOD
        (ДОМ ФУД)» (далее — Администратор), БИН 240940031813, является публичной офертой,
        согласно которой Администратор предлагает заключить договор с использованием
        информационных технологий на изложенных в ней условиях с любым лицом, принявшим
        оферту.
        Настоящая Публичная оферта содержит все существенные условия, признанные
        таковыми законодательством, а также условия, необходимые для оказания услуг по
        размещению Продуктов питания на онлайн Платформе DOM FOOD (ДОМ ФУД), их
        реализации и продвижению.
        Настоящая Публичная оферта устанавливает существенные условия, необходимые
        для организации доставки Продуктов питания от Поваров к Покупателям, с учётом
        обязательных норм законодательства Республики Казахстан и требований онлайн
        Платформы.
        Администратор оставляет за собой право вносить изменения в настоящую Публичную
        оферту и условия оказания услуг в порядке, предусмотренном в настоящей Публичной
        оферте.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center"> 1 ПОНЯТИЯ И ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕЙ
        ПУБЛИЧНОЙ ОФЕРТЕ</h3>
      <p><br/></p>
      <p>
        <p><br/></p>1.1. Услуги – доставка Курьерами Продуктов питания от Поваров к Покупателям,
        зарегистрированным на онлайн Платформе, на территории Республики Казахстан.
        <p><br/></p>1.2. Публичная оферта – публичное предложение Администратора, содержащее все
        условия для оказания Услуг, которое становится обязательным для обеих Сторон с момента
        его Акцепта Курьером.
        <p><br/></p> 1.3. Акцепт – согласие Курьера на условия настоящей Публичной оферты,
        подтвержденное регистрацией на онлайн Платформе или принятием Заказа на доставку
        Продуктов питания от Поваров к Покупателям.
        <p><br/></p> 1.4. Информационная технология – совокупность методов, производственных
        процессов и программно-технических средств, объединённых в технологический комплекс,
        обеспечивающий сбор, создание, хранение, накопление, обработку, поиск, вывод,
        копирование, передачу и распространение информации.
        <p><br/></p>1.5. Онлайн Платформа – интернет-ресурс https://dom-food.com/ и https://domfood.kz/
        Администратора, содержащий информацию в текстовом, графическом, аудиовизуальном
        или ином виде, размещённую на аппаратно-программном комплексе, имеющем
        уникальный сетевой адрес и (или) доменное имя, функционирующем в Интернете.
        <p><br/></p>1.6. Покупатель – лицо, посетившее онлайн Платформу для ознакомления и/или
        покупки Продуктов питания, изготовленных Поваром, с целью регистрации и оформления
        Заказа.
        <p><br/></p>1.7. Курьер – лицо, зарегистрированное на онлайн Платформе, принимающее Заказы
        на доставку Продуктов питания от Поваров к Покупателям и оказывающее курьерские
        услуги в соответствии с условиями настоящей Публичной оферты.
        <p><br/></p>1.8. Заказ – оформленная заявка Покупателя на покупку Продуктов питания,
        оформленная в соответствии с условиями Публичной оферты и размещённая на Платформе.
        <p><br/></p> 1.9. Продукты питания – продукция, изготовленная Поваром (готовые блюда еды,
        безалкогольные напитки, сырые мясные продукты и полуфабрикаты из них),
        приготовленные Поварами, прошедшие предварительное согласование с Администратором
        на соответствие стандартам качества, и предназначенные для доставки Покупателю.
        <p><br/></p>1.10. Повар – ресторан, розничный магазин, повар, или иное лицо, предлагающий в
        качестве продавца свои Продукты питания (готовые блюда еды, безалкогольные напитки,сырые мясные продукты и полуфабрикаты из них), приготовленные Поварами, и
        принявший условия Публичной оферты Администратора посредством Акцепта.
        <p><br/></p>1.11. Стороны – Администратор и Курьер.
        <p><br/></p>1.12. Персональные данные – любая информация о Курьере, обрабатываемая в
        рамках выполнения условий настоящей Публичной оферты.
        <p><br/></p>1.13. Сбор и Обработка персональных данных – получение, хранение, изменение,
        использование, дополнение, распространение, обезличивание, блокирование и
        уничтожение персональных данных в соответствии с законодательством.
        <p><br/></p>1.14. Третьи лица – лица, связанные как в настоящее время, так и в будущем с
        Администратором в рамках сбора, обработки и защиты персональных данных Курьера.
        <p><br/></p>1.15. Правила оказания курьерских услуг – приложение к настоящей Публичной
        оферте, содержащее все правила оказания услуг, требования, права и обязанности Курьера.
        Эти правила являются неотъемлемой частью настоящей Публичной оферты.
        <p><br/></p> 1.16. Маркетинговые услуги – действия, направленные на продвижение продукции
        на онлайн Платформе (акции, скидки, реклама).
        <p><br/></p>1.17. Комиссия – процент от стоимости доставки Заказа от Поваров к Покупателям,
        удерживаемый Администратором, а также оплата сопутствующих услуг.
        <p><br/></p>1.18. Тариф – фиксированная стоимость или система расчета стоимости доставки
        Заказа от Поваров к Покупателям, установленная Администратором для Курьера, которая
        может зависеть от расстояния, времени выполнения заказа и других факторов.
        <p><br/></p>1.19. Акция (Скидка) – специальное предложение, доступное Курьерам в
        определённый период времени для привлечения Покупателей и увеличения продаж.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ПРЕДМЕТ ПУБЛИЧНОЙ ОФЕРТЫ</h3>
      <p><br/></p>
      <p>
        <p><br/></p> 2.1. Обязательства Сторон: Услуги включают доставку Продуктов питания от
        Поваров к Покупателям в соответствии с условиями Администратора. Курьер принимает
        условия настоящей Публичной оферты в полном объёме посредством Акцепта.
        <p><br/></p> 2.2. Порядок совершения Акцепта: Предложение Администратора считается
        принятым Курьером с момента совершения им любого из следующих действий:
        <p><br/></p> 2.2.1. Совершение Курьером действий, свидетельствующих о принятии условий
        настоящей Публичной оферты, в частности, проставление отметки (галочки) в
        соответствующем поле на онлайн Платформе.
        <p><br/></p> 2.2.2. Регистрация Курьера на онлайн Платформе.
        <p><br/></p>  2.2.3. Принятие Заказа на доставку Продуктов питания через сервисы онлайн
        Платформы.
        <p><br/></p> 2.3. Правовые последствия Акцепта:
        С момента совершения Акцепта считаются выполненными следующие условия:
        <p><br/></p>  2.3.1. Курьер ознакомлен с условиями настоящей Публичной оферты и Правилами,
        являющимися неотъемлемой частью настоящей Публичной оферты, согласен с ними и
        принимает их в полном объёме.
        <p><br/></p> 2.3.2. Акцепт Администратором получен и считается принятым.
        <p><br/></p> 2.3.3. Настоящая Публичная оферта считается заключённой и не требует
        двустороннего подписания, имея юридическую силу в электронном виде.
        <p><br/></p>2.3.4. Настоящая Публичная оферта имеет юридическую силу в соответствии с
        законодательством Республики Казахстан, а письменная форма сделки считается
        соблюдённой.
        <p><br/></p>2.4. Право на отзыв предложения: Администратор имеет право отозвать своё
        предложение о заключении настоящей Публичной оферты в любое время, при этом это не
        освобождает Курьера от обязательств по уже принятым Заказам. В случае отзыва
        настоящей Публичной оферты Администратор может разместить уведомление на сервисе
        онлайн Платформы с указанием точного времени приостановки действия настоящей
        Публичной оферты, не менее чем за 12 часов до отзыва, если иное не указано в условияхнастоящей Публичной оферты.
        <p><br/></p>2.5. Временная недоступность Услуг: Администратор оставляет за собой право
        приостанавливать оказание Услуг или временно закрывать доступ к онлайн Платформе в
        случае проведения профилактических или иных технических работ. Администратор может
        уведомлять Курьера о таких приостановках через онлайн Платформу или приложение, если
        это необходимо.
        <p><br/></p> 2.6. Прекращение действия настоящей Публичной оферты по инициативе
        Администратора: Администратор имеет право прекратить оказание Услуг и действие
        настоящей Публичной оферты в одностороннем порядке, если Курьер систематически
        нарушает правила онлайн Платформы или обязательства, предусмотренные настоящей
        Публичной офертой. В таком случае Администратор уведомляет Курьера об этом решении
        через сервис онлайн Платформы или по электронной почте.
        <p><br/></p> 2.7. Изменения условий настоящей Публичной оферты: Администратор оставляет
        за собой право вносить изменения в условия настоящей Публичной оферты без
        обязательного уведомления Курьера, если это не противоречит законодательству
        Республики Казахстан и условиям настоящей Публичной оферты. Любые изменения
        вступают в силу с момента их публикации на онлайн Платформе, и Курьер считается
        согласным с ними, если продолжает пользоваться Услугами, и осуществлять доставку,
        после вступления изменений в силу.
        <p><br/></p>2.8. Ответственность Курьера за качество и своевременность доставки: Курьер
        обязуется доставлять Продукты питания в соответствии с указанными сроками и
        стандартами. В случае нарушений Администратор вправе применить штрафные санкции
        или ограничить доступ к онлайн Платформе.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ</h3>
      <p><br/></p>
      <p>3.1. Правила пользования онлайн Платформой являются обязательными для
        исполнения Курьерами. Правила использования онлайн Платформой доступны по ссылке:
        https://dom-food.com/polzovatelskoe_soglashenie/.к</p>
      <div id="table-scroll">

      <table  className="services-table mt-4" border="1" cellPadding="10">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги (виды)</th>
          <th>Особенности оказываемой Услуги, сведения об основных свойствах Услуги</th>
          <th>Срок оказания Услуги и порядок его исчисления</th>
          <th>Наличие или отсутствие гарантийного срока</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Регистрация в личном кабинете на онлайн Платформе</td>
          <td>Курьер регистрирует свой профиль для получения доступа к онлайн Платформе и начала работы</td>
          <td>Регистрация осуществляется автоматически после подтверждения данных</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Принятие заказов через онлайн Платформу</td>
          <td>Курьер принимает заказы, размещенные через онлайн Платформу, и обязуется доставить их в срок</td>
          <td>Срок выполнения зависит от заказа, но не превышает указанный при его размещении</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Доставка Продуктов питания</td>
          <td>Курьер обязуется доставить продукцию от повара к Покупателю, следуя всем условиям транспортировки</td>
          <td>Время доставки указано при размещении заказа</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Подтверждение выполненных заказов</td>
          <td>Курьер обязан предоставлять отчет о доставке через приложение, подтверждая выполнение заказа</td>
          <td>Подтверждение осуществляется после выполнения доставки</td>
          <td>Отсутствует</td>
        </tr>
        </tbody>
      </table>
      </div>
      <p>3.2. Регулярность оказания услуг: Доставка заказов осуществляется на регулярной
        основе, до момента завершения сотрудничества между Сторонами. Заказы оформляются в
        онлайн Платформе на постоянной основе.</p>
      <p>
        <p><br/></p> 3.3. Неограниченное количество Заказов: В рамках исполнения настоящей
        Публичной оферты Курьером может быть осуществлено неограниченное количество
        доставок Заказов до Покупателей. Выполнение каждого отдельного Заказа не прекращает
        действия настоящей Публичной оферты.
        <p><br/></p>3.4. Оформление Заказов: Курьер принимает Заказы, выполняя действия,
        предусмотренные Правилами, которые являются неотъемлемой частью настоящей
        Публичной оферты.
        <p><br/></p> 3.5. Привлечение третьих лиц для выполнения Услуг: Курьер, принимая условия
        настоящей Публичной оферты, соглашается на возможность привлечения
        Администратором третьих лиц.
        <p><br/></p>  3.6. Взаимодействие с Поваром и Покупателем: Курьер обязан получать заказ у
        Повара и обеспечивать его качественную доставку Покупателю через сервисы онлайн
        Платформы. Курьеры также обязаны следовать инструкциям по доставке, указанным на
        онлайн Платформе.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4.СТОИМОСТЬ, СРОКИ И ПОРЯДОК ОПЛАТЫ УСЛУГ</h3>
      <div id="table-scroll">

      <table className="payment-table my-4">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги (вид)</th>
          <th>Единица измерения (объем, количество и (или) комплектность Услуги)</th>
          <th>Стоимость Услуги</th>
          <th>Комментарии</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Доставка заказов до Покупателя</td>
          <td>шт./комплект</td>
          <td>Варируется в зависимости от расстояния и условий доставки</td>
          <td>Курьер забирает продукцию у повара и доставляет Покупателю</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Регистрация и принятие заказов</td>
          <td>действие</td>
          <td>Включено в общую стоимость Заказов</td>
          <td>Доступ предоставляется после регистрации на Платформе</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Бонусы и премии за выполнение доставки</td>
          <td>бонус / заказ</td>
          <td>Зависит от условий кампаний</td>
          <td>Дополнительные премии за выполнение заказов в пиковые периоды</td>
        </tr>
        </tbody>
      </table>
      </div>
      <p>
        <p><br/></p>  4.1. Оказание услуг и расчёты:
        • Курьер оказывает услуги доставки, включая принятие и транспортировку заказов
        от Поваров до Покупателей. Курьер получает оплату за каждую выполненную доставку,
        исходя из стоимости Заказа, указанной в приложении на момент принятия Заказа.
        • Курьер получает ту стоимость за доставку, которая отображается в системе на
        момент принятия Заказа. Конкретные тарифы могут варьироваться в зависимости от ряда
        факторов, таких как расстояние, загруженность или срочность выполнения Заказа.
        <p><br/></p>4.2. Принципы ценообразования и комиссии
        • Курьер не видит комиссию или тарифной наценки, установленной
        Администратором. Они получают фиксированную сумму за каждую доставку,
        отображённую в Заказе.
        • Администратор оставляет за собой право изменять условия ценообразования и
        комиссии, что не будет отображаться Курьерам. Вся информация о комиссиях используется
        исключительно для внутреннего учёта.
        <p><br/></p>  4.3. Порядок и сроки выплат:
        • Все выплаты Курьерам производятся безналичным способом в течение 1 (одного)
        календарного дня после выполнения и подтверждения доставки в системе. В отдельных
        случаях, например, при выходных и праздничных днях или технических задержках,
        выплата может быть произведена в конце недели либо на следующий рабочий день.
        • Оплата услуг производится следующими способами:
        . Платёжными картами (VISA или MasterCard).
        o Переводы через платёжные терминалы и системы (например, Kaspi, Halyk Bank,
        QIWI).
        o Перечисление на электронные кошельки или счета.
        <p><br/></p>  4.4. Задержки в выплатах: В случае технических сбоев, блокировки платёжных
        систем или других форс-мажорных обстоятельств, выплаты могут быть временно
        приостановлены. Администратор уведомляет Курьера о задержках и принимает все
        необходимые меры для их устранения в кратчайшие сроки.
        <p><br/></p>  4.5. Бонусы и премии: Администратор вправе предоставлять бонусы и премии за
        выполнение Заказов в периоды высокой нагрузки, праздники или при других
        обстоятельствах, определённых онлайн Платформой. Информация о бонусах будет
        отображаться в личном кабинете Курьера на момент принятия Заказа.
        <p><br/></p> 4.6. Налогообложение:
        <p><br/></p>• Курьер самостоятельно несёт ответственность за уплату налогов в соответствии
        со своим статусом (самозанятый или ИП). Если Курьер пожелает, чтобы Администратор
        удерживала и уплачивала налоги, необходимо заключить отдельное соглашение, в котором
        будут оговорены все детали.
        <p><br/></p>• Администратор не несёт ответственности за налоговые обязательства Курьеров и
        оставляет за собой право запросить подтверждение уплаты налогов.
        <p><br/></p>4.7. Изменение условий выплат и комиссий: Администратор оставляет за собой
        право вносить изменения в условия выплат и внутренние комиссии, уведомляя Курьера
        через личный кабинет или электронную почту. Курьер соглашается с такими изменениями,
        продолжая пользоваться услугами после их вступления в силу.
        <p><br/></p> 4.8. Политика отмены и отказа от заказов:
        • Курьер имеет право отказаться от выполнения заказа в случае непредвиденных
        обстоятельств, таких как поломка транспортного средства, срочные личные обстоятельства
        или другие форс-мажорные ситуации. В этом случае он обязан уведомить Администратора
        через приложение незамедлительно.
        <p><br/></p>  4.9. Взаимодействие с Покупателями и Поварами: Курьер обязуется соблюдать
        вежливость и корректность при взаимодействии с Покупателями и Поварами. В случае
        поступления жалоб на поведение курьера Администратор вправе применить штрафные
        санкции или приостановить его доступ к онлайн Платформе.
        <p><br/></p> 4.10. Право на изменение маршрутов доставки: Администратор оставляет за собой
        право корректировать маршруты и условия доставки в зависимости от загруженности
        Курьеров и логистических требований. Курьер обязуется следовать всем инструкциям
        Администратора, указанным в приложении, для оптимизации логистики.
        <p><br/></p>  44.11. Сохранность Заказов при доставке: Курьер несёт полную ответственность за
        сохранность Продуктов питания при транспортировке. В случае повреждения или порчи
        Продуктов питания курьер обязан немедленно уведомить Администратора и предоставить
        доказательства (например, фотографии). Администратор может наложить санкции занесоблюдение правил транспортировки.
        <p><br/></p>  4.12. Обработка данных и конфиденциальность:
        <p><br/></p>  • Курьер обязуется соблюдать требования конфиденциальности, в том числе не
        разглашать личные данные Покупателей и Поваров, адреса доставки и иные сведения,
        полученные в рамках выполнения Заказов.
        <p><br/></p> • В случае нарушения условий конфиденциальности Курьер несёт ответственность
        в соответствии с настоящей Публичной оферты и законодательством Республики
        Казахстан.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">5. ПРИЕМКА-ПЕРЕДАЧА ОКАЗАННЫХ УСЛУГ</h3>
      <p>
        <p><br/></p> 5.1. Подтверждение выполненных доставок: Каждая завершённая доставка должна
        быть подтверждена Курьером через сервис онлайн Платформы. После подтверждения заказ
        считается завершенным, и эта информация фиксируется в сервисе онлайн Платформы и
        отображается в личном кабинете Курьера.
        <p><br/></p> 5.2. Ежемесячные отчеты: Администратор предоставляет Курьерам отчёт о
        выполненных доставках не позднее 10 (десятого) числа каждого месяца. В отчёте
        указываются все заказы за предыдущий месяц, включая информацию о маршрутах, времени
        выполнения, итоговых суммах и удержанных комиссиях.
        <p><br/></p>5.3. Акт выполненных работ (по запросу):
        <p><br/></p> • В случае необходимости Администратор предоставляет Акт выполненных работ,
        подтверждающий оказание услуг за определённый период.
        <p><br/></p> • Акт может быть оформлен в электронной или бумажной форме и подписан
        обеими Сторонами.
        <p><br/></p> • Подписанный экземпляр Акта остаётся у каждой из сторон.
        <p><br/></p> 5.4. Согласование и подписание Акта:
        <p><br/></p>  • Курьер обязан подписать Акт в течение 5 (пяти) календарных дней с момента его
        получения либо направить Администратору обоснованный отказ.
        <p><br/></p>• Если Курьер не предоставляет подписанный Акт или обоснованный отказ в
        установленный срок, услуги считаются оказанными и принятыми без претензий.
        <p><br/></p>5.5. Корректировка спорных заказов: При возникновении споров по конкретным
        доставкам Администратор может инициировать пересмотр отчётов. Обнаруженные
        недочёты устраняются с последующим пересмотром соответствующих сумм в отчёте.
        <p><br/></p> 5.6. Документы для налоговой и финансовой отчётности: По запросу Курьера
        Администратор может предоставить документы, необходимые для ведения бухгалтерской
        и налоговой отчётности, такие как сводные отчёты и подтверждения выполненных
        доставок.
        <p><br/></p>  5.7. Устранение нарушений и повторная передача услуг:
        <p><br/></p>• Если выявлены недочёты в процессе доставки, Администратор вправе
        инициировать повторное выполнение услуг.
        <p><br/></p> • Курьер обязан устранить выявленные нарушения в течение согласованного срока,
        в противном случае его доступ к онлайн Платформе может быть приостановлен.
        <p><br/></p> 5.8. Оценка качества и обратная связь: Курьеры получают регулярные оценки от
        Покупателей. Высокие рейтинги могут привести к бонусам, а низкие оценки — к
        приостановке доступа к онлайн Платформе и обязательной корректировке работы.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</h3>
      <p>
        <p><br/></p>6.1. Освобождение от ответственности: Сторона освобождается от ответственности
        за частичное или полное неисполнение обязательств, если оно вызвано обстоятельствами
        непреодолимой силы, возникшими после заключения настоящей Публичной оферты и
        обладающими чрезвычайным характером, который невозможно было предвидеть или
        предотвратить.
        <p><br/></p>6.2. Примеры обстоятельств непреодолимой силы: К таким обстоятельствамотносятся:
        <p><br/></p>• Природные катаклизмы: наводнения, пожары, землетрясения, ураганы, и
        другие природные явления.
        <p><br/></p>• Социальные и политические факторы: эпидемии, пандемии, забастовки,
        военные конфликты или вооруженные столкновения.
        <p><br/></p>• Действия государственных органов: введение карантина, изменения в
        законодательстве, запреты, блокировки счетов или любые решения властей, делающие
        исполнение обязательств невозможным.
        <p><br/></p>6.3. Обязательство уведомления: Сторона, столкнувшаяся с обстоятельствами
        непреодолимой силы, обязана уведомить другую Сторону в письменной форме в течение 5
        (пяти) календарных дней с момента их наступления. В уведомлении должна содержаться
        следующая информация:
        <p><br/></p>• Описание характера возникших обстоятельств.
        <p><br/></p> • Оценку их влияния на выполнение обязательств.
        <p><br/></p>• Ожидаемые сроки устранения или окончания обстоятельств.
        <p><br/></p> 6.4. Подтверждение обстоятельств: Сторона, ссылающаяся на обстоятельства
        непреодолимой силы, обязана предоставить официальные документы, подтверждающие
        наличие этих обстоятельств. Обстоятельства, имеющие общеизвестный характер, могут
        быть освобождены от документального подтверждения, однако уведомление о них должно
        быть направлено в течение 24 (двадцати четырёх) часов.
        <p><br/></p> 6.5. Утрата права на освобождение: Если Сторона не направит документы или
        уведомление в установленный срок, она теряет право ссылаться на форс-мажор как
        основание для освобождения от ответственности за неисполнение обязательств.
        <p><br/></p> 6.6. Продление сроков: При наступлении обстоятельств непреодолимой силы срок
        выполнения обязательств продлевается на время их действия и устранения последствий.
        Если они продолжаются более 30 (тридцати) календарных дней, Стороны обязаны
        провести переговоры для поиска альтернативных решений. В случае, если соглашение не
        достигнуто, договор может быть расторгнут с уведомлением другой Стороны.
        <p><br/></p> 6.7. Финансовые обязательства: Финансовые обязательства, возникшие до
        наступления форс-мажорных обстоятельств, подлежат исполнению в полном объёме. В
        случае расторжения договора выплаты по уже завершённым заказам остаются в силе.
        <p><br/></p>6.8. Приоритет выполнения: После устранения форс-мажорных обстоятельств
        Стороны обязаны возобновить выполнение обязательств в кратчайшие сроки, исходя из
        приоритетности ранее неисполненных обязательств.
        <p><br/></p>6.9. Взаимные уведомления: Стороны обязаны уведомлять друг друга о снятии
        ограничений, вызванных форс-мажором, и о готовности к возобновлению исполнения
        обязательств.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. РАЗРЕШЕНИЕ СПОРОВ</h3>
    <p>
      <p><br/></p>7.1. Досудебное урегулирование споров: Все споры и разногласия, возникающие
      между Сторонами в процессе исполнения настоящей Оферты, подлежат обязательному
      досудебному урегулированию. Стороны обязуются предпринять разумные и
      добросовестные усилия для урегулирования споров путём переговоров и предъявления
      претензий до обращения в судебные органы.
      <p><br/></p> 7.2. Стороны определяют следующий обязательный порядок досудебного
      урегулирования спора:
      <p><br/></p>7.2.1. Подача претензии: Претензия подаётся в письменной форме, подписывается
      уполномоченным представителем и направляется другой Стороне способом, позволяющим
      зафиксировать её получение.
      <p><br/></p>7.2.2. Сроки рассмотрения претензии:
      <p><br/></p> • Претензия рассматривается в течение 10 (десяти) календарных дней с момента
      её получения Стороной. <p><br/></p>7.2.3. Ответ на претензию: Ответ на претензию предоставляется в письменной
      форме с мотивированным обоснованием и, при необходимости, предложением по
      урегулированию спора.
      <p><br/></p> 7.3. Судебное разбирательство: В случае, если стороны не пришли к соглашению в
      досудебном порядке, спор подлежит рассмотрению в судебном порядке в
      Специализированном межрайонном экономическом суде города Астаны (договорная
      подсудность).
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p> 8.1. Изменение и дополнение условий настоящей Публичной оферты:
        Администратор оставляет за собой право вносить изменения в условия настоящей
        Публичной оферты. Все изменения вступают в силу с момента их публикации на онлайн
        Платформе, если не указано иное. Продолжение использования Платформы после
        вступления изменений в силу считается согласием с новыми условиями.
        <p><br/></p> 8.2. Отказ от ответственности: Администратор не несет ответственности за убытки,
        вызванные временной недоступностью онлайн Платформы или нарушениями, вызванными
        форс-мажорными обстоятельствами, сбоями в работе платформы, действиями третьих лиц
        или иных факторов, не зависящих от Администрации.
</p>
    </div>
  )
}

export default PublicOfferCourier
