'use client'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import Header from '../images/header.png'

export default function CustomModalNew({ children, isOpenMobileNav, setIsOpenMobileNav }) {
  return (
    <Dialog open={isOpenMobileNav} onClose={setIsOpenMobileNav} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
        <div className="flex max-h-full h-full items-start justify-center text-center sm:items-center items-center pt-[80px]">
          <DialogPanel
            transition
            className="modalDiv h-auto mx-[15px] md:mx-0 relative transform overflow-inherit pt-[4%] flex justify-center bg-white pt-5 text-left shadow-xl transition-all data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-[570px] py-6 data-[closed]:sm:translate-y-0 "
          >
            <div style={{
              backgroundImage: `url(${Header})`
            }}
                   className="ddd bg-contain bg-fixed bg-no-repeat bg-tom w-[63%] px-5 pt-3 md:pt-7 -top-[19%] sm:-top-[80px] extraSm:-top-[12%] h-[120px] items-center justify-start absolute flex-col flex items-center text-center bg-no-repeat">
              <p className="extraSm_:text-3xl text-[18px] text-white font-bold font-custom">Присоединяйтесь к Dom Food!</p>
            </div>
            <div className="text-center">
              <div className="mt-2">
                {children}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
