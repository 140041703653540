import Image1 from './../images/new-1.jpg'
import image2 from './../images/new-2.jpg'
import image3 from './../images/new-4.png'
import image4 from './../images/new-3.png'
import Process from './../images/proces.png'
import CoverHow from "../images/coverHow.png";

const data = [
  {icon: Image1, title: 'Заполнение заявки', subtitle: 'Зарегистрируйтесь на сайте DomFood, заполнив форму регистрации. Укажите информацию о себе и о том, какую еду вы готовите.'},
  {icon: image2, title: 'Создание меню', subtitle: 'Создайте своё меню и добавьте его на DomFood. Опишите каждое блюдо, укажите цены и добавьте аппетитные фото. Мы поможем вам привлечь гостей, предоставив всю необходимую поддержку в продвижении.'}
]

const dataTwo = [
  {icon: image3, title: 'Загрузка документов', subtitle: 'Загрузите нужные документы, чтобы подтвердить ваш профессионализм и соблюдение санитарных стандартов. Это быстро и легко!'},
  {icon: image4, title: 'Начало приема заказов', subtitle: 'Как только ваша заявка будет одобрена, начинайте принимать заказы. Готовьте ваши фирменные блюда, а мы позаботимся о доставке прямо до дверей ваших клиентов.'}
]

const gridItemStyle = {
  backgroundImage: `url(${CoverHow})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative'
}

const HowItsWork = () => {
  return (
    <div className="mt-10 lg:mt-40">
        <div className="flex justify-center">
          <div className="w-[330px] text-nowrap">
            <p className="gradient-text text-xl md:text-4xl font-bold text-center md:text-start">Как это работает?</p>
          </div>
        </div>
          <div className="px-4 md:px-[30px] lg:px-[50px] bg-[#ffffff] mt-4 md:mt-20">
            <div style={gridItemStyle} className="flex flex-col rounded-xl md:flex-row px-4 md:px-[30px] lg:px-[60px] xl:px-[160px] justify-center md:justify-around py-6 md:py-[115px]">
              <div className="space-y-10 w-full md:w-[70%]">
                {data.map((item,index) => {
                  return (
                    <div className="shadow-xl w-full md:w-[320px] lg:w-[384px] flex items-center flex-col bg-white max-h-[350px] md:max-h-[403px] min-h-[350px] md:min-h-[403px] rounded-2xl py-4 md:py-[20px] px-4 lg:px-[32px]" key={index}>
                      <img className="max-w-[100px] md:max-w-[120px] max-h-[100px] md:max-h-[120px]" src={item.icon} alt="svg" />
                      <p className='gradient-text text-[26px] md:text-2xl mt-[10px] font-bold'>{item.title}</p>
                      <p className="text-base md:text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                    </div>
                  )
                })}
              </div>
               <img className="hidden xl:block absolute" src={Process} alt="png" />
              <div className="space-y-10 mt-8 md:mt-[210px]">
                {dataTwo.map((item,index) => {
                  return (
                    <div className="shadow-xl w-full md:w-[320px] lg:w-[384px] flex items-center flex-col bg-white max-h-[350px] md:max-h-[403px] min-h-[350px] md:min-h-[403px] rounded-2xl py-4 md:py-[20px] px-4 lg:px-[32px]" key={index}>
                      <img className="max-w-[100px] md:max-w-[120px] max-h-[100px] md:max-h-[120px]" src={item.icon} alt="svg" />
                      <p className='gradient-text text-xl md:text-2xl mt-[10px] font-bold'>{item.title}</p>
                      <p className="text-base md:text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                    </div>
                  )
                })}
              </div>
          </div>
          </div>
      </div>
  )
}

export default HowItsWork

