import Bes from '../images/Free.svg'
import Mon from '../images/Money.svg'
import Auto from '../images/Group 2608658.svg'
import Bez from '../images/M.svg'
import FooderImage from '../images/fooder.png'
import Cover from "../images/cover.png"
import {useWindowSize} from "../useWindowSize";


const foodersDataOne = [
  {title: 'Бесплатно', icon: Bes, subtitle: 'Зарегистрируйтесь на DomFood абсолютно бесплатно и наслаждайтесь одними из самых низких комиссий. Предлагайте свои блюда широкой аудитории и зарабатывайте больше с каждым заказом.'},
  {title: 'Монетизация', icon: Mon, subtitle: 'Получайте заказы легко и с удовольствием. Готовьте то, что любите, и зарабатывайте на своём увлечении ещё больше с DomFood.'}
]

const foodersDataTwo = [
  {title: 'Автоматизация', icon: Auto, subtitle: 'Выкладывайте свои блюда на DomFood и пусть наша платформа помогает вам находить новых клиентов. Сосредоточьтесь на кулинарии, а остальное мы возьмём на себя.'},
  {title: 'Без затрат на рекламу', icon: Bez, subtitle: 'Забудьте о расходах на рекламу. Мы займемся продвижением ваших блюд, чтобы вы могли полностью погрузиться в кулинарию.'}
]

const ForFooder = () => {

  const width = useWindowSize().width

  const gridItemStyle = {
    backgroundImage: `url(${Cover})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    mixBlendMode: 'soft-light',
    position: 'relative'
  }

  return (
    <div style={width > 1024 ? gridItemStyle : {}} className="w-full h-[80%]">
      <div className="flex w-full justify-center mt-9 md:mt-14 xl:mt-[100px]">
        <div className='w-[315px] text-center'>
        <p className="text-center font-bold text-5xl gradient-text h-[55px]">Для поваров</p>
        </div>
      </div>
    <div className="flex flex-col md:flex-row px-4 md:px-14 xl:px-[110px] justify-between items-center mt-8">
      <div>
        {foodersDataOne.map((item,index) => {
          return (
            <div className="w-[350px] min-h-[300px] md:min-h-[355px] items-center flex flex-col" key={index}>
                <img className="w-28" src={item.icon} alt="svg"/>
                <p className="font-semibold text-xl mt-4">{item.title}</p>
                <p className="mt-4 text-center">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
      <div className="block md:hidden lg:block">
        <img src={FooderImage} alt="png" />
      </div>
      <div>
        {foodersDataTwo.map((item,index) => {
          return (
            <div className="w-[350px] min-h-[300px] md:min-h-[355px] items-center flex flex-col" key={index}>
              <img className="w-28" src={item.icon} alt="svg"/>
              <p className="font-semibold text-xl mt-4">{item.title}</p>
              <p className="mt-4 text-center">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
    </div>
      <div className="hidden md:flex justify-center lg:hidden">
        <img src={FooderImage} alt="png" />
      </div>
    </div>
  )
}

export default ForFooder