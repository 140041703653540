import {useEffect} from "react";

const ReturnPolicy = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПОЛИТИКА ВОЗВРАТА</h1>
      <h3 className="font-bold text-xl mt-4">1. Общие положения</h3>
      <p>1.1. Настоящая Политика возврата разработана в соответствии с требованиями
        Гражданского кодекса Республики Казахстан и Закона Республики Казахстан «О
        защите прав потребителей» и регулирует порядок возврата и обмена Продуктов питания,
        приобретенных через онлайн Платформу DOM FOOD (ДОМ ФУД).</p>
      <p><br/></p>

      <h3 className="font-bold text-xl mt-4">1.2. В настоящей Политике применяются следующие термины и определения:</h3>
      <p><br/></p>
      <p>1.2.1. Продукты питания – готовые блюда еды, безалкогольные напитки, сырые
        мясные продукты и полуфабрикаты из них, приготовленные Поварами Платформы по
        заказу Пользователя, посредством онлайн Платформы;
        <p><br/></p>  1.2.2. Онлайн Платформа – интернет-сайт, размещенный в домене https://dom-
        food.com и https://domfood.kz, который соединяет Покупателей, Поваров и Курьеров-
        Поваров с целью реализации товаров и услуг Поваров Платформы Покупателям;
        <p><br/></p> 1.2.3. Покупатель — дееспособное физическое лицо, достигшее 18 лет,
        присоединившееся к Пользовательскому соглашению в собственном интересе либо
        юридическое лицо в лице своего представителя, выступающего от имени и в интересах
        представляемого им юридического лица, которые оформляют заказы на товары и услуги
        через онлайн Платформу;</p>
      <p><br/></p> 1.2.4. Администратор Платформы/Администратор — Общество с ограниченной
      ответственностью DOM FOOD «ДОМ ФУД», юридическое лицо, созданное по
      законодательству Республики Армения и зарегистрированное по адресу: Республика
      Армения, город Ереван, ул. Чайковского, дом 34, квартира 19. Кентрон, 0010 и
      Товарищество с ограниченной ответственностью «DOM FOOD (ДОМ ФУД)», БИН
      240940031813, местонахождения: Республика Казахстан, город Алматы, Алмалинский
      район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008;
      <p><br/></p> 1.2.5. Повар – ресторан, розничный магазин, повар, или иного рода Повар,
      подписавшее Поварское соглашение с Администратором и предлагающее в качестве
      продавца свои Продукты питания – блюда готовой еды, а также услуги доставки (или без
      доставки) для продажи через онлайн Платформу. Также к Поварам относится курьер-Повар,
      подписавший Поварское соглашение с Администратором и являющийся физическим или
      юридическим лицом либо его представителем, оказывающим услуги доставки через онлайн
      Платформу.
      <p><br/></p>


      <h6 className="font-bold text-xl mt-4">1.3. Вопросы возврата регулируются на основании следующих принципов:</h6>
      <p><br/></p>
      <p><p><br/></p> 11.3.1. Соблюдение прав Покупателя в соответствии с законодательством
        Республики Казахстан;
        <p><br/></p> 1.3.2. Обеспечение прозрачности и добросовестности в урегулировании возвратов
        и обменов Продуктов питания;
      </p>      <p><br/></p>

      <p> <strong> 1.4. </strong> Администратор обязуется оперативно реагировать на все обращения и претензии.</p>

      <p className="font-bold text-xl mt-4">2. Условия возврата и обмена Продуктов питания ненадлежащего качества</p>
      <p className="font-bold text-xl mt-4">2.1. Продукты питания подлежат возврату или обмену в случае продажи таких
        товаров с истекшим сроком годности или обнаружения Покупателем недостатков.</p>
      <p><br/></p>
    <p>2.2. В случае обнаружения Покупателем недостатков Продуктов питания и
      предъявления им требования о замене, Повар обязан заменить их незамедлительно. В
      случае необходимости проведения дополнительной проверки (экспертизы) качества
      Продуктов питания, замена должна быть произведена в течение тридцати календарных
      дней с момента предъявления соответствующего требования. Если у Повара в момент
      предъявления требования отсутствует необходимый для замены Продуктов питания,
      замена должна быть проведена в течение 10 (десяти) календарных дней со дня
      предъявления такого требования.</p>
      <p><br/></p>
      <p><strong> 2.3. </strong>Продукты питания подлежат обмену или возврату при условии:</p>
      <p><br/></p>
      <p><strong></strong> 2.3.1. Покупатель должен направить через онлайн Платформу заявление на возврат
        или обмен Продуктов питания с указанием причины;</p>
      <p>2.3.2. Продукты питания не были в употреблении и сохранили товарный вид и
        потребительские свойства.
        <p><br/></p> 2.3.3. Сохранена оригинальная упаковка, пломбы, ярлыки и полная комплектация.
        <p><br/></p> 2.3.4. Необходимо предоставить документы, подтверждающие факт покупки
        (товарный или кассовый чек).</p>
      <p>
       2.4. Покупатель вправе обменять Продукты питания на другие Продукты питания по
         соглашению сторон, между Пользователем и Поваром. При этом, в случае разницы в цене,
         то производится перерасчет.
      </p>
      <p><strong> 2.5. Место возврата и обмена: возврат и обмен Продуктов питания осуществляется
        по местонахождению Повара или в месте, определенном соглашением сторон, через
        курьерские службы;</strong></p>
      <p>2.6. Порядок возврата стоимости:
        <p><br/></p> 2.6.1. При возврате Продуктов питания Покупателю возвращается полная
        стоимость данного товара в течение 10 рабочих дней после получения Продуктов питания
        Поваром и проверки его состояния.
        <p><br/></p> 2.6.2. В случае обмена Продуктов питания на Продукты питания с большей
        стоимостью Покупатель должен доплатить разницу.</p>
      <p><strong>2.6.3. Если Продукты питания дешевле, разница возвращается Покупателю.</strong></p>
      <p>2.7.1. Индивидуальные заказы и Продукты питания, изготовленные по поручению
        Пользователя, не подлежат возврату, если они соответствуют заявленным требованиям и
        не имеют недостатков.</p>
      <p>2.8. Проверка Продуктов питания при получении: Покупатель обязан проверить
        Продукты питания в момент доставки. Претензии по товарному виду и количеству
        принимаются только во время получения Продуктов питания.</p>

      <p>3. Заключительные положения
        <p><br/></p>  3.1. Применимое законодательство: Все отношения, возникающие в рамках
        настоящей Политики, регулируются Гражданским кодексом Республики Казахстан,
        Законом Республики Казахстан «О защите прав потребителей», а также иными
        нормативно-правовыми актами, действующими на территории Республики Казахстан.
        <p><br/></p> 3.2. Изменение условий Политики:
        <p><br/></p> 3.2.1. Администратор оставляет за собой право вносить изменения в Политику без
        предварительного уведомления.
        <p><br/></p> 3.2.2. Обновленная версия Политики вступает в силу с момента ее размещения
        на сайтах https://dom-food.com и https://domfood.kz, если иное не предусмотрено в новой
        редакцией</p>
      <p><br/></p>
      <p>3.2.3. Все изменения распространяются на правоотношения, возникшие после
        вступления изменений в силу.</p>
      <p>3.3. Контактная информация для вопросов по возврату и обмену Продуктов
        питания:</p>
      <p>
        <p><br/></p> 3.3.1. E-mail: support@dom-food.com.
        <p><br/></p> 3.3.2. Телефон: +7 777 9001033.
        <p><br/></p> Адрес для переписки: Республика Казахстан, г. Алматы, Алмалинский район,
        ул. Карасай Батыра, дом 193Б, индекс 050008.
      </p>
      <p>3.4. Порядок урегулирования споров:</p>
      <p><br/></p>
      <p>3.4.1. Споры, возникающие между Пользователем и Поваром (или
        Администратором) относительно возврата или обмена Продуктов питания, подлежат
        урегулированию в претензионном порядке.
        <p><br/></p> 3.4.2. Претензия направляется на адрес Администратора через онлайн Платформу.</p>
      <p>3.4.3. Повар обязан рассмотреть претензию в сроки, установленные настоящей
        Политикой и предоставить письменный ответ Покупателю.</p>
      <p><br/></p>
      <p>3.5. Передача спора в судебные органы: В случае, если стороны не пришли к
        соглашению в досудебном порядке, спор подлежит рассмотрению в судебном порядке в
        Специализированном межрайонном экономическом суде города Астаны (договорная
        подсудность).
        <p><br/></p> 3.6. Администратор не отвечает за исполнение Поваром принятых обязательств по
        Пользовательскому соглашению в части возврата или обмена Продуктов питания, включая
        уплату неустойки, судебных издержек и других убытков Покупателя, вызванные
        неисполнением или ненадлежащим исполнением обязательства Поваром.</p>
      <p><br/></p>
      <p>3.7. Администратор не несет ответственности в случае неисполнения и/или
        ненадлежащего исполнения Поваром обязательств по продаже Покупателю Продуктов
        питания надлежащего качества, в том числе в связи с предоставлением последним
        недостоверных и/или недействительных данных о себе, а равно невыполнением им условий
        настоящей Публичной оферты, при этом за такие нарушения прямая ответственность лежит
        непосредственно на Поваре.</p>
      <p><strong>3.8. Вся коммуникация Повара с Покупателем должна осуществляться через сервис
        онлайн Платформы.</strong></p>
      <p>3.9. Форс-мажорные обстоятельства (обстоятельства непреодолимой силы):
        <p><br/></p> 3.9.1. Повар не несет ответственность за задержки или невозможность выполнения
        обязательств по возврату или обмену продуктов питания, если это вызвано форс-
        мажорными обстоятельствами, такими как:
        - Природные катаклизмы (землетрясения, наводнения и др.);
        - Военные действия или массовые беспорядки;
        - Ограничения со стороны государственных органов, введение чрезвычайного
        положения или блокировка финансовых операций.
        <p><br/></p> 3.9.2. В случае наступления форс-мажорных обстоятельств платформа обязуется
        уведомить Покупателей в кратчайшие сроки.
        <p><br/></p> 3.10. Действительность положений политики:</p>
      <p><br/></p>
      <p><strong>3.10.1. Если какое-либо положение настоящей Политики будет признано
        недействительным или утратившим силу, это не влияет на действительность остальных
        положений.</strong></p>
      <p>3.10.2. Положения настоящей Политики, противоречащие законодательству,
        автоматически заменяются нормами, соответствующими действующим нормативным
        актам Республики Казахстан.</p>
      <p>
        <p><br/></p> 3.11. Настоящая Политика как часть Покупательского соглашения:
        <p><br/></p> 3.11.1. Настоящая
        Политика
        возврата
        является
        неотъемлемой
        частью
        Пользовательского соглашения, размещенного на сайтах DOM FOOD. Оформляя заказ,
        Покупатель подтверждает свое согласие с условиями настоящей Политики.
        <p><br/></p> 3.11.2. В случае несогласия с условиями возврата Покупатель обязан прекратить
        использование онлайн Платформы и воздержаться от оформления заказов на ней.
      </p>
    </div>
  )
}

export default ReturnPolicy;
