import { useState } from "react";
import { Disclosure } from '@headlessui/react';
import ArrowLeft from './../images/arrowLeft.png';
import ArrowTop from './../images/arrowTop.png';

const data = [
  {
    title: 'Как осуществляется бесплатная регистрация на платформе DomFood?',
    subtitle: 'Регистрация на DomFood полностью бесплатна для всех. Чтобы зарегистрироваться, просто перейдите на наш сайт и заполните форму заявки, указав свои ФИО, номер телефона и адрес электронной почты. После отправки формы мы свяжемся с вами в течение 10 минут для подтверждения информации. Регистрация позволяет вам создать свое меню и начать принимать заказы без скрытых платежей или комиссий.'
  },
  {
    title: 'Какие юридические обязательства возникают при использовании платформы DomFood?',
    subtitle: 'При регистрации на платформе DomFood повара заключают договор с компанией, в котором описаны все условия сотрудничества, включая обязательства сторон. Этот договор юридически обязывает DomFood предоставлять платформу для размещения блюд, обработки заказов и рекламную поддержку, а поваров — соблюдать стандарты качества и санитарные нормы. Все документы и соглашения соответствуют законодательству Республики Казахстан и обеспечивают защиту прав как поваров, так и клиентов.'
  },
  {
    title: 'В каких городах действует платформа DomFood?',
    subtitle: 'На данный момент платформа DomFood доступна в следующих городах Казахстана: Атырау, Астана и Алматы. Мы активно работаем над расширением нашей географии и в скором времени планируем охватить другие города и регионы. Повара, проживающие в этих городах, могут зарегистрироваться и начать работать с нами уже сегодня, предоставляя свои блюда широкой аудитории.'
  },
  {
    title: 'Как работает курьерская служба DomFood?',
    subtitle: 'DomFood обеспечивает полностью автоматизированную систему доставки. После того, как повар принимает заказ, наша курьерская служба берет на себя ответственность за доставку блюд клиенту. Курьеры DomFood проходят тщательный отбор и обучение для обеспечения высокого уровня сервиса. Мы также предоставляем возможность отслеживания доставки в реальном времени, что позволяет поварам и клиентам всегда быть в курсе статуса заказа. Это делает процесс доставки быстрым, надежным и удобным для всех сторон.'
  },
];

const QuestionsBlock = () => {
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) => {
      const updatedOpenItems = [...prevOpenItems];
      updatedOpenItems[index] = !updatedOpenItems[index];
      return updatedOpenItems;
    });
  };

  return (
    <div className="mt-9 space-y-4 md:space-y-10 w-full px-4 md:px-[100px] lg:px-[160px] xl:px-[272px]">
      <div className="flex justify-center">
        <div className="w-[500px] text-nowrap text-center">
          <p className="gradient-text text-[22px] md:text-2xl lg:text-3xl font-bold">Часто задаваемые вопросы</p>
        </div>
      </div>
      {data.map((orderInner, index) => {
        const isOpen = openItems[index] || false;
        return (

          <Disclosure className="rounded-2xl shadow-md p-4 md:p-6" key={index} as="div">
            <Disclosure.Button
              onClick={() => toggleItem(index)}
              as="div"
              className="flex items-center justify-between w-full max-h-[90px] md:max-h-full cursor-pointer"
            >
              <p className="text-[#333F51] font-bold text-base lg:text-xl">
                {orderInner.title}
              </p>
              <div>
                <img
                  className=""
                  src={isOpen ? ArrowTop : ArrowLeft}
                  alt="arrow icon"
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel
              style={{ whiteSpace: 'pre-line' }}
              className="text-sm text-gray-500 mt-4"
            >
              <div className="text-base leading-7 font-medium max-w-[800px]">
                <p className="text-[#556987]">{orderInner.subtitle}</p>
              </div>
            </Disclosure.Panel>
          </Disclosure>
        );
      })}
    </div>
  );
};

export default QuestionsBlock;
