import {useEffect} from "react";

const RulesCourier = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПРАВИЛА
        ОКАЗАНИЯ КУРЬЕРСКИХ УСЛУГ</h1>
      <p className="mt-4">
        Настоящие Правила оказания курьерских услуг (далее — Правила) являются неотъемлемой
        частью и приложением к Публичной оферте оказания курьерских услуг.
        Принятие условий настоящих Правил осуществляется Курьером путем присоединения к
        условиям Публичной оферты и проставления галочки в соответствующем поле на сайтах
        https://dom-food.com/ и https://domfood.kz/.
        Такое действие считается полным и безоговорочным принятием условий настоящих Правил и
        обязательным условием для исполнения принятых на себя обязательств.
        Настоящие Правила вступают в силу с момента присоединения Курьера к Публичной оферте.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p> 1.1. Услуги доставки: Курьер оказывает услуги доставки заказов, оформленных Покупателем
        через онлайн Платформу, в качестве самозанятого лица или индивидуального предпринимателя.
        Курьер обязуется самостоятельно уплачивать налоги с доходов, полученных от выполнения заказов.
        Курьер соглашается с тем, что Администратор может удерживать определённый процент с оплаты
        услуг Курьера.
        <p><br/></p> 1.2. Предоставление заказов и контроль: Администратор посредством онлайн Платформы
        предоставляет заказы Курьеру, а также осуществляет контроль за их выполнением, включая
        соблюдение сроков, качество доставки и сохранность Продуктов питания.
        <p><br/></p> 1.3. Соблюдение условий Публичной оферты: Курьер обязуется соблюдать условия
        Публичной оферты, включая сроки доставки, сохранность Продуктов питания и стандарты
        качества, установленные Администратором.
        <p><br/></p> 1.4. Регистрация и использование приложения: Курьер должен быть зарегистрирован на
        онлайн Платформе и использовать приложение для подтверждения получения и выполнения
        заказов. Все действия по приёму и передача заказов должны фиксироваться через онлайн
        Платформу.
        <p><br/></p>1.5. Гибкость рабочего времени: Курьер имеет гибкость в выборе рабочего времени, но
        обязан соблюдать согласованные сроки доставки для каждого заказа. В случае невозможности
        выполнить доставку в срок Курьер обязан незамедлительно уведомить Администратора через
        сервис онлайн Платформы.
        <p><br/></p>1.6. Коммуникация с Покупателями и Поварами: Курьер имеет право взаимодействовать с
        Покупателями и Поварами через сервис онлайн Платформы для уточнения деталей доставки, а
        также для уточнения адреса и других условий доставки. Вся коммуникация должна вестись вежливо
        и в соответствии с условиями настоящих Правил и Публичной оферты.
        <p><br/></p>1.7. Соблюдение стандартов Платформы: Курьер обязуется соблюдать все правила и
        политики Администрации, включая стандарты обслуживания, качество доставки и
        взаимодействие с Покупателями.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ОБЯЗАННОСТИ КУРЬЕРА</h3>
        <p>
          <p><br/></p> 2.1. Доставка заказов:
          <p><br/></p>•
          Своевременная доставка: Курьер обязан своевременно забирать заказы у Поваров и
          доставлять их Покупателям в сроки, указанные в приложении Платформы. В случае
          непредвиденных обстоятельств, препятствующих соблюдению сроков, Курьер обязан
          незамедлительно уведомить Администратора.
          <p><br/></p>•
          Сохранность и качество Продуктов питания: Курьер должен обеспечить сохранность
          и качество Продуктов питания во время доставки. Это включает соблюдение правил
          транспортировки и использования надлежащих упаковочных материалов для предотвращения
          повреждения Продуктов питания. Курьер несёт ответственность за любые повреждения, нарушение
          целостности упаковки, несоответствие количества или потерю качества Продуктов питания,
          произошедшие во время доставки.
          <p><br/></p> 2.2. Использование транспортных средств:
          <p><br/></p>• Личный транспорт: Курьер обязан использовать личный транспорт для выполнения
          заказов. Администратор не несет ответственности за техническое состояние транспортного средства
          Курьера, его исправность или соответствие применимым требованиям.• Соблюдение правил дорожного движения: Курьер обязан соблюдать все правила
          дорожного движения и законы Республики Казахстан, касающиеся безопасного передвижения по
          дорогам во время выполнения заказов.
          <p><br/></p> 2.3. Подтверждение доставки:
          <p><br/></p> •
          Фиксация забора заказ: Курьер обязан сделать фотографию заказа в момент его
          получения от Повара. Данная фотография должна быть загружена в сервис онлайн Платформы и
          может использоваться для разрешения возможных споров. Фотография должна быть сделана таким
          образом, чтобы на ней отчётливо просматривалось место передачи и сам заказ.
          <p><br/></p> •
          Фиксация выполнения доставки: После завершения доставки Курьер обязан
          подтвердить выполнение заказа в онлайн Платформе, указав, что заказ успешно передан
          Покупателю.
          <p><br/></p> •
          Фотографирование передачи заказа: Курьер также обязан сделать фотографию,
          подтверждающую передачу заказа Покупателю. Данная фотография должна быть загружена в
          сервис онлайн Платформы и может использоваться для разрешения возможных споров. Фотография
          должна быть сделана таким образом, чтобы было чётко видно место передачи и сам заказ.
          <p><br/></p>  2.4. Соблюдение санитарных норм:
          <p><br/></p> •
          Чистота и гигиена: Курьер обязан соблюдать санитарные и гигиенические нормы при
          доставке Продуктов питания, включая поддержание чистоты транспортного средства,
          используемого для доставки. Курьер обязан гарантировать, что Продукты питания размещены в
          безопасных условиях и что ничего не угрожает их качеству во время транспортировки. Для
          перевозки должны использоваться чистые сумки или контейнеры.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. СРОКИ И УСЛОВИЯ ДОСТАВКИ ПРОДУКТОВ ПИТАНИЯ</h3>
        <p>
          <p><br/></p> 3.1. Соблюдение сроков доставки:
          <p><br/></p> •
          Своевременность доставки: Вся информация о сроках и ожидаемом времени доставки
          будет доступна в сервисе онлайн Платформы.
          <p><br/></p> •
          Уведомление о задержках: В случае, если Курьер понимает, что не сможет доставить
          заказ вовремя (например, из-за задержек на дорогах, погодных условий или других непредвиденных
          обстоятельств), он обязан незамедлительно уведомить Администратора через сервис онлайн
          Платформы, чтобы Администратор мог своевременно уведомить Покупателя.
          <p><br/></p>  3.2. Задержки и уведомления:
          <p><br/></p>  •
          Задержки, не зависящие от Курьера: Если доставка задерживается по причинам, не
          зависящим от Курьера (например, погодные условия или иные обстоятельства форс-мажора),
          Администратор обязуется уведомить Покупателя о задержке, чтобы уменьшить его недовольство.
          <p><br/></p>•
          Задержки по вине Курьера: В случае, если задержка произошла по вине Курьера,
          Администратор оставляет за собой право применить штрафные санкции или удержать сумму
          штрафа из оплаты Курьера. Размер и условия штрафов определяются Администратором.
          <p><br/></p> 3.3. Точность адреса и соблюдение инструкций:
          <p><br/></p> •
          Проверка адреса доставки: Курьер обязан тщательно проверять адрес доставки,
          указанный в сервисе онлайн Платформы. В случае, если адрес неясен или неполон, Курьер должен
          связаться с Покупателем или уведомить Администратора для получения уточнений.
          <p><br/></p>  •
          Соблюдение инструкций Покупателя: Курьер обязан следовать дополнительным
          инструкциям от Покупателя, если они были указаны при оформлении заказа. Это может включать
          такие действия, как оставление заказа у двери, на ресепшене или в другом указанном месте. Курьер
          обязан удостовериться в том, что все инструкции соблюдены в точности.
          <p><br/></p> 3.4. Условия доставки:
          <p><br/></p>  •
          Сохранность Продуктов питания: Курьер несёт полную ответственность за
          сохранность Продуктов питания во время доставки, включая соблюдение всех необходимых
          температурных и санитарных условий, чтобы обеспечить качество Продуктов питания. Это
          включает использование специализированных сумок для поддержания температуры (например,
          термосумок для горячих блюд).
          <p><br/></p>  •
          Компенсация убытков: Если Продукты питания были повреждены или испорчены в
          процессе доставки, Курьер несёт ответственность за убытки, возникшие по его вине. В таких
          случаях Администратор может потребовать компенсацию убытков, что может быть удержано из
          оплаты Курьера.
          <p><br/></p>3.5. Ответственность за непринятые заказы:•
          Непринятые заказы: Если Покупатель недоступен по указанному адресу и не отвечает
          на звонки или сообщения, Курьер обязан уведомить Администратора через сервис онлайн
          Платформы. Администратор предоставит дальнейшие инструкции, включая возможное
          возвращение заказа Повару или хранение заказа до уточнения деталей.
          <p><br/></p> 3.6. Ответственность за использование личных данных: Защита персональных данных
          Покупателей: Курьер обязан обеспечивать сохранность и конфиденциальность персональных
          данных Покупателей, таких как адреса и контактные телефоны, полученные в процессе выполнения
          заказа. Курьер не имеет права передавать эти данные третьим лицам или использовать их для личных
          целей, не связанных с выполнением доставки. Нарушение этого обязательства может повлечь за
          собой юридическую ответственность, включая штрафы и возмещения убытков, а также
          прекращения сотрудничества.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. СИСТЕМА ОПЛАТЫ УСЛУГ</h3>
      <p>
        <p><br/></p> 4.1. Система оплаты: Курьер получает оплату за каждую выполненную доставку в
        соответствии с тарифами, установленными Администратором. Тарифы могут зависеть от
        различных факторов, таких как расстояние, время выполнения заказа, загруженность дорог и другие
        условия, определяемые Администратором.
        <p><br/></p> 4.2. Установление стоимости доставки: Стоимость доставки устанавливается
        Администратором на основе множества параметров, включая расстояние от пункта получения до
        Покупателя, время выполнения заказа, тип транспортного средства и прочие факторы.
        Администратор оставляет за собой право изменять стоимость доставки в зависимости от текущей
        загруженности и иных факторов, влияющих на процесс доставки.
        <p><br/></p>4.3. Комиссия курьера: Курьер получает оплату за выполненные доставки за вычетом
        комиссии, установленной Администратором. Размер комиссии может меняться в зависимости от
        условий доставки, включая загруженность и расстояние. Курьер будет заранее уведомлен о любых
        изменениях в тарифах и комиссиях. Все изменения вступают в силу с момента уведомления
        Курьера через сервис онлайн Платформы.
        <p><br/></p> 4.4. Окончательная стоимость для Покупателя: Окончательная стоимость доставки,
        которую видит Покупатель при оформлении заказа, включает в себя все применимые сборы и
        комиссии. Эта стоимость фиксируется на момент оформления заказа и не подлежит изменению
        после его акцепта Покупателем.
        <p><br/></p>4.5. Периодичность выплат: Оплата выполненных доставок осуществляется на
        еженедельной основе. В случае возникновения технических сбоев или иных обстоятельств,
        приводящих к задержке выплат, Администратор обязуется уведомить Курьера и предоставить
        информацию о предполагаемой дате выплаты. В свою очередь, Курьер соглашается с возможностью
        временных задержек выплат в случае форс-мажорных обстоятельств.
        <p><br/></p>4.6. Удержания и налоги:
        <p><br/></p>•
        Самостоятельная уплата налогов: Курьер, работающий как самозанятое лицо, обязан
        самостоятельно уплачивать налоги с доходов, полученных за оказанные услуги по доставке
        Продуктов питания, в соответствии с законодательством Республики Казахстан.
        <p><br/></p> •
        Удержание налогов Платформой: Если Администратор берёт на себя обязанность по
        удержанию определённого процента от выплат Курьера для уплаты налогов, размер такого
        удержания будет согласован с Курьером.
        <p><br/></p>4.7. Штрафы и удержания: В случае нарушения условий настоящих Правил и Публичной
        оферты (в том числе опоздание при доставке Покупателю, повреждение Продуктов питания,
        несоблюдение санитарных норм), Администратор оставляет за собой право применять штрафные
        санкции, включая удержание до 80% от суммы доставки в качестве неустойки. Конкретный размер
        штрафа зависит от тяжести нарушения и будет определяться Администратором.
        <p><br/></p> 4.8. Форс-мажор: В случае возникновения форс-мажорных обстоятельств, таких как
        блокировка счетов Администрации, технические сбои или задержки в банках, Администратор
        обязуется уведомить Курьеров о возможных задержках выплат и указать предполагаемые сроки. Обе
        Стороны соглашаются, что не будут подавать претензии по данному факту, пока не будут устранены
        все препятствия к выплатам.
        <p><br/></p>4.9. Бонусы за выполнение: Курьеры могут получать дополнительные премии за успешное
        выполнение определенного количества доставок или работу в периоды высокой загруженности,например, в часы пик или в праздничные дни. Бонусы устанавливает Администратор и выплачивает
        их в дополнение к основным тарифам.
        <p><br/></p>4.10. Прозрачность выплат: Администратор обязуется предоставить Курьерам полную
        информацию о каждой выплате, включая данные о выполненных доставках, произведенных
        удержаниях и наложенных штрафах (если применимо). Информация будет доступна в личном
        кабинете Курьера в сервисе онлайн Платформы.
        <p><br/></p> 4.11. Механизм разрешения споров по оплате: В случае возникновения споров по оплате
        Курьер имеет право обратиться в службу поддержки Администратора.
        Администратор обязуется рассмотреть жалобу и предоставить письменный ответ Курьеру в
        течение 5 (пяти) рабочих дней с момента обращения. Все споры по оплате решаются на основании
        данных, зарегистрированных в сервисе онлайн Платформы.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. РАЗРЕШЕНИЕ СПОРОВ</h3>

      <p>
        <p><br/></p> 5.1. Досудебное урегулирование споров:
        <p><br/></p> 5.1.1. Досудебное урегулирование споров между Сторонами осуществляется в обязательном
        порядке путём проведения переговоров и предъявления претензий.
        <p><br/></p>5.1.2. Стороны обязуются предпринять все разумные меры для урегулирования спорных
        вопросов до обращения в судебные органы, чтобы минимизировать риски и затраты.
        <p><br/></p>5.2. Порядок досудебного урегулирования: Стороны договорились о следующем
        обязательном порядке досудебного урегулирования споров:
        <p><br/></p> 5.2.1. Форма и содержание претензии: Претензия должна быть представлена в письменной
        форме и подписана должным образом уполномоченным лицом Стороны, предъявляющей
        претензию.
        <p><br/></p> •
        Претензия подлежит рассмотрению в течение 10 (десяти) календарных дней с даты её
        получения другой Стороной.
        <p><br/></p> •
        По результатам рассмотрения претензии Сторона, получившая претензию, обязана
        уведомить Сторону, предъявившую претензию, в письменной форме, указав мотивы принятого
        решения и, при необходимости, предложения о порядке дальнейшего урегулирования спора.
        <p><br/></p> 5.3. Судебное разбирательство: В случае, если стороны не пришли к соглашению в
        досудебном порядке, спор подлежит рассмотрению в судебном порядке в Специализированном
        межрайонном экономическом суде города Астаны (договорная подсудность).
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ В ПРАВИЛА</h3>
    <p>
      <p><br/></p> 6.1. Право на изменение правил: Администратор оставляет за собой право вносить
      изменения и дополнения в настоящие Правила.
      <p><br/></p>  6.2. Уведомление о внесении изменений: Изменения и дополнения вступают в силу с
      момента их публикации на онлайн Платформе. Администратор обязуется уведомить Курьера о
      внесённых изменениях посредством уведомления через сервис онлайн Платформы или через
      электронную почту не менее чем за 5 (пять) календарных дней до вступления изменений в силу.
      <p><br/></p>6.3. Принятие изменений Курьером: Если Курьер продолжает предоставлять услуги после
      вступления изменений в силу, это считается согласием с новыми условиями. В случае несогласия с
      внесёнными изменениями Курьер вправе прекратить предоставление услуг и уведомить об этом
      Администратора в установленном порядке.
    </p>
    </div>
    )
}
export default RulesCourier