import { useFormik } from 'formik';
import axios from 'axios';
import { useState } from 'react';

const RemoveAccount = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    onSubmit: async (values) => {
      try {
        setErrorMessage('');
        const response = await axios.post('https://api.dom-food.com/check-user', {
          phone: values.phone,
        });
        console.log(response.data);
        setErrorMessage(response.data.message);
        if (response.data.success) {
          setErrorMessage(response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 404) {
          setErrorMessage("This phone number doesn't have an account or it's removed");
        } else {
          setErrorMessage('An error occurred. Please try again later.');
        }
      }
    }
  });

  const handleChangeInternal = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-xl md:text-3xl">
        Проверьте, удалены ли данные вашего аккаунта.
      </h1>
      <form onSubmit={formik.handleSubmit} className="mt-4 md:mt-10">
        <div>
          <p className="text-[#111827] leading-6 text-sm md:text-xl font-light mb-4">
            Мобильный номер
          </p>
          <input
            name='phone'
            value={formik.values.phone}
            onChange={handleChangeInternal}
            className="pl-4 border border-black h-[46px] w-full md:w-1/2 bg-secondary"
            type="text"
          />
        </div>
        <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2">
          Отправить
        </button>
      </form>

      {errorMessage && (
        <div className="mt-4 text-center text-red-500">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default RemoveAccount;
