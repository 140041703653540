import ArrowTop from './../images/contactImage.png';
import { useFormik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import React, {useState} from "react";
import ErrorMassage from "./ErrorMassage";

const ContactUsBlock = () => {
  const [errors, setErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      message: '',
    },

    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://api.dom-food.com/page/contact', values);
        console.log('Form submitted successfully:', response.data);
        toast.success('Your message has been send, waiting for answer from admin')
      } catch (error) {
        setErrors(error.response.data.errors);
        console.error('Error submitting form:', error);
      }
    },
  });


  return (
    <div className="space-y-10 w-full mt-[80px]">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-x-0 md:gap-x-[100px] items-center">
        <div className="col-span-2 min-w-[300px] md:min-w-[300px] lg:min-w-[550px]">
          <img className="w-full" src={ArrowTop} alt="png" />
        </div>
        <form onSubmit={formik.handleSubmit} className="col-span-3 px-4">
          <p className="text-[#252B33] text-[30px] lg:text-4xl xl:text-5xl font-bold ml-12 lg:ml-24">Свяжитесь с нами!</p>
          <div className="space-y-6 flex flex-col mt-10 md:mt-[54px]">
            <input
              name="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
              placeholder="ФИО*"
            />
            { errors.full_name && <ErrorMassage message={errors.full_name} /> }

            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
              placeholder="Ваш e-mail*"
            />
            { errors.email && <ErrorMassage message={errors.email} /> }

            <input
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
              placeholder="Телефон*"
            />
            { errors.phone && <ErrorMassage message={errors.phone} /> }

            <textarea
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              className="hover:border-orange-500 border border-1 border-gray-400 rounded-[30px] max-w-[650px] px-[20px] flex items-center justify-center min-h-[130px] py-[20px]" rows="4"  cols="50"
              placeholder="Сообщение*"
            />
            { errors.message && <ErrorMassage message={errors.message} /> }

            <div className="flex justify-center max-w-[650px]">
             <button className="mt-3.5 rounded-[30px] h-[52px] max-w-[165px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold">Отправить</button>
            </div>
            <p className="text-xs md:text-base text-[#333F51] text-center md:text-start">Или вы можете позвонить нам сами: +7 777 900 10 33</p>
          </div>
        </form>
        </div>
    </div>
  );
};

export default ContactUsBlock;
