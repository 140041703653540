import React from "react";
import Web from "../images/web.svg";
import Watsapp from "../images/watsapp.svg";
import Instagram from "../images/instagram.svg";
import Phone from "../images/phone.svg";
import Email from "../images/email.svg";
import {Link as RouterLink} from "react-router-dom";

const menu = [
  { title: 'Главная' },
  { title: 'О нас' },
  { title: 'Условия' },
  { title: 'Возможности' },
  { title: 'FAQ' },
  { title: 'Обратная связь' }
];

const iconsBlock = [
  { title: 'WhatsApp', icon: Watsapp, url: '+77779001033' },
  { title: 'Instagram', icon: Instagram, url: 'https://www.instagram.com/domfood.kaz/profilecard/?igsh=a3c5azJmYm9hdjd2' },
  { title: 'Phone', icon: Phone, url: '+77779001033' },
  { title: 'Email', icon: Email, url: 'support@dom-food.com' }
];

const MobileMenu = ({ setIsOpenMobileNav }) => {
  return (
    <>
      <div className='lg:flex gap-x-[25px] space-y-[20px]'>
        {menu.map((item, index) => {
          return (
            <div key={index}>
              <p className="text-[#333F51]">{item.title}</p>
            </div>
          );
        })}
      </div>
      <div className='flex justify-center gap-x-[14px] mt-[38px]'>
        {iconsBlock.map((item, index) => {
          if (item.title === 'Email') {
            return (
              <RouterLink key={index} target="_blank" to={`mailto:${item.url}`}>
                <img src={item.icon} alt="svg" />
              </RouterLink>
            );
          }
          if (item.title === 'WhatsApp') {
            return (
              <RouterLink key={index} target="_blank" to={`https://api.whatsapp.com/send?phone=${item.url}`}>
                <img src={item.icon} alt="svg" />
              </RouterLink>
            );
          }
          if (item.title === 'Phone') {
            return (
              <RouterLink key={index} target="_blank" to={`tel:${item.url}`}>
                <img src={item.icon} alt="svg" />
              </RouterLink>
            );
          }
          return (
            <RouterLink key={index} target="_blank" to={item.url}>
              <img src={item.icon} alt="svg" />
            </RouterLink>
          );
        })}
      </div>
      <div className="flex justify-center">
      <div onClick={() => setIsOpenMobileNav(true)}
           className="mt-[38px] max-w-[135px] flex justify-center items-center border rounded-[30px] h-[41px] bg-gradient-to-r from-[#DE4333] to-[#FDA110]">
        <button className="m-px border rounded-[30px] h-[35px] px-8 bg-white">Я повар!</button>
      </div>
      </div>
    </>
  )
}

export default MobileMenu
