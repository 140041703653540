import Mobile from './../images/mobile.png'
import Clock from './../images/clock.png'
import Arrow from './../images/arrow.png'
import FoodImage from "../images/food.png";

const About = () => {
  return (
    <>
      <div className="block md:hidden col-span-1">
        <img src={FoodImage} alt="svg" />
      </div>
    <div className="mt-4 md:mt-0 bg-[#ffffff] flex flex-col pr-4 md:pr-10 xl:pr-20 pl-4 md:pl-0 md:grid grid-cols-1 md:grid-cols-6 items-center gap-x-1 md:gap-x-6 xl:gap-x-16">
      <div className="hidden md:block col-span-1">
        <img src={FoodImage} alt="svg" />
      </div>
      <div className="col-span-2">
          <p className="font-bold text-[30px] from-[#DE4333] to-[#FDA110] gradient-text mb-4 mt-6 text-center">О нас</p>
          <div className="text-start">
            <p className="text-base md:text-xl font-customNew"><span className="font-bold text-[22px] from-[#DE4333] to-[#FDA110] gradient-text">DomFood</span> — уникальное приложение, которое объединяет домашних поваров по всему Казахстану для доставки вкуснейших блюд прямо к вашему порогу. </p>
             <p className="text-base md:text-xl font-customNew">Талантливые повара из  <span className="font-bold">Атырау,</span> <span className="font-bold">Астана и </span>  <span className="font-bold">Алматы</span> предлагают свои
               кулинарные шедевры. С DomFood вы откроете для себя мир домашних гаcтрономических удовольствий, приготовленных с душой и профессиональным мастерством, не покидая уют вашего дома.</p>
          </div>
      </div>
      <div className="flex md:hidden items-center">
        <div className="my-20 my:mt-0 ml-6 flex items-center justify-center col-span-2 bg-gradient-to-r from-[#DE4333] to-[#FDA110] rounded-full w-[280px] md:w-[350px] lg:w-[455px] h-[280px] md:h-[350px] lg:h-[455px]">
          <img className="h-[360px] lg:h-[507px]" src={Mobile} alt="png" />
        </div>
        <div className="flex h-[300px] flex-col h-full justify-between">
          <img className="w-[80px] h-[80px] relative" src={Clock} alt="png" />
          <img className="w-[80px] h-[150px]" src={Arrow} alt="png" />
        </div>
      </div>

        <div className="my-20 my:mt-0 ml-6 md:flex hidden items-center justify-center col-span-2 bg-gradient-to-r from-[#DE4333] to-[#FDA110] rounded-full w-[280px] md:w-[350px] lg:w-[455px] h-[280px] md:h-[350px] lg:h-[455px]">
          <img className="h-[360px] lg:h-[507px]" src={Mobile} alt="png" />
        </div>
        <div className="col-span-1 md:flex flex-col h-full justify-between hidden">
          <img className="w-[80px] h-[80px] relative" src={Clock} alt="png" />
          <img className="w-[80px] h-[150px]" src={Arrow} alt="png" />
        </div>
    </div>
    </>
  )
}

export default About
