import {useEffect} from "react";

const UserAgreement = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">Пользовательское соглашение</h1>
      <h3 className="font-bold text-2xl mt-4 text-center">1. Общие условия</h3>
<p>
  <p><br/></p>1.1. Настоящее Пользовательское соглашение (далее – «Соглашение») определяет
  условия использования онлайн Платформы DOM FOOD (ДОМ ФУД) (далее –
  «Платформа»), а также права и обязанности Покупателей и Администратора Платформы.
  Действующая редакция Соглашения размещена на официальных сайтах https://dom-
  food.com и https://domfood.kz.
  <p><br/></p>1.2. Применение к третьим лицам: Настоящее Соглашение распространяется на
  отношения, которые могут затрагивать права и интересы третьих лиц, не являющихся
  Покупателями, если такие права и интересы затрагиваются действиями Покупателей.
  <p><br/></p>1.3. Предмет соглашения: Администратор Платформы предоставляет Покупателю
  доступ к использованию функционала Платформы.
  <p><br/></p>1.4. Использование Платформы: Использование Платформы в любой форме
  (просмотр материалов, регистрация, оформление заказа) означает присоединение к
  настоящему Соглашению в соответствии со ст. 395 и 396 Гражданского кодекса
  Республики Казахстан.
  <p><br/></p>1.5. Принятие условий настоящего Соглашения:
  <p><br/></p> 1.5.1. Покупатель подтверждает, что:
  <p><br/></p>•
  Ознакомился с условиями настоящего Соглашения до начала использования
  Платформы.
  <p><br/></p>•
  Принимает условия настоящего Соглашения без ограничений и обязуется их
  соблюдать.
  <p><br/></p> •
  Прекращает использование Платформы в случае несогласия с условиями
  настоящего Соглашения.
  <p><br/></p> 1.6. Право на изменение соглашения: Администратор Платформы вправе вносить в
  одностороннем порядке изменения и дополнения в настоящее Соглашение без
  предварительного уведомления Покупателей. Новая редакция вступает в силу с момента
  размещения на Платформе или направления уведомления на email Покупателя, указанный
  при регистрации.
  <p><br/></p>1.7. В настоящем Соглашении применяются следующие термины и определения:
  <p><br/></p>1.7.1. Продукты питания – готовые блюда еды, сырые мясные продукты и
  полуфабрикаты из них, безалкогольные напитки, приготовленные Поварами по заказу
  Покупателя, посредством Платформы.
  <p><br/></p>1.7.2. Онлайн Платформа — интернет-сайт, размещенный в домене https://dom-
  food.com и https://domfood.kz, который соединяет Покупателей, Поваров и Курьеров с
  целью реализации Продуктов питания и услуг Поваров ее Покупателям.
  <p><br/></p>1.7.3. Покупатель — дееспособное физическое лицо, достигшее 18 лет,
  присоединившееся к настоящему Соглашению в собственном интересе либо юридическое
  лицо в лице своего представителя, выступающего от имени и в интересах представляемого
  им юридического лица, которые оформляют заказы на Продукты питания и услуги через
  Платформу.
  <p><br/></p>1.7.4. Администратор Платформы/Администратор — Общество с ограниченной
  ответственностью DOM FOOD «ДОМ ФУД», юридическое лицо, созданное по
  законодательству Республики Армения и зарегистрированное по адресу: Республика
  Армения, город Ереван, ул. Чайковского, дом 34, квартира 19. Кентрон, 0010 и
  Товарищество с ограниченной ответственностью «DOM FOOD (ДОМ ФУД)», БИН
  240940031813, местонахождение: Республика Казахстан, город Алматы, Алмалинский
  район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008.
  <p><br/></p> 1.7.5. Сервис — комплекс услуг, предоставляемые Покупателю с использованием
  Платформы.  <p><br/></p> 1.7.6. Аккаунт/учетная запись — это персональная страница Покупателя или
  личный кабинет, который создается после регистрации на Платформе.
  <p><br/></p>1.7.7. Авторизация – вход Покупателя в профиль в Личный Кабинет с помощью
  логина и пароля.
  <p><br/></p>1.7.8. Пароль, присваиваемый Покупателю по итогам завершения процесса
  регистрации.
  <p><br/></p>1.7.9. Соглашение — настоящее соглашение со всеми дополнениями и
  изменениями.
  <p><br/></p>1.7.10. Повар — ресторан, розничный магазин, частный повар или иной партнер,
  подписавший партнерское соглашение с Администратором Платформы и предлагающий в
  качестве продавца свои готовые блюда еды, безалкогольные напитки, сырые мясные
  продукты и полуфабрикаты из них, и связанные, в связи с этим услуги доставки или без
  доставки для их продажи через онлайн Платформу.
  <p><br/></p> 1.7.11. Курьер — физическое или юридическое лицо, либо его представитель,
  подписавший партнерское соглашение с Платформой и оказывающий услуги доставки.
  <p><br/></p>1.8. Обращения, предложения и претензии физических и юридических лиц к
  Администратору Платформы в связи с настоящим Соглашением и всеми вопросами по
  функционированию Платформы, нарушениями прав и интересов третьих лиц при
  использовании Платформы, а также для запросов уполномоченных лиц могут быть
  направлены через Платформу, либо на почтовый адрес Администратора Платформы,
  указанный в пункте 1.7 настоящего раздела.
  <p><br/></p> 1.9. В отношении функционирования и развития Платформы Администратор
  руководствуется законодательством Республики Казахстан, настоящим Соглашением и
  иными специальными документами, которые разработаны или могут быть разработаны и
  приняты Администратором в целях регламентации предоставления Покупателям доступа к
  отдельному функционалу Платформы.
  <p><br/></p>1.10.
  Никакие положения настоящего Соглашения не предоставляют Покупателю
  право на использование фирменного наименования, товарных знаков, доменных имен
  и иных отличительных знаков Администратора и иных Покупателей. Право на
  использование фирменного наименования, товарных знаков, доменных имен и иных
  отличительных знаков Администратора, а также иных Покупателей может быть
  предоставлено исключительно по письменному соглашению с Администратором и
  иными Покупателями соответственно.
  <p><br/></p>1.11.
  Покупатель соглашается получать от Платформы и/или через сервисы
  третьих лиц (email, SMS, WhatsApp и другие каналы) сервисные сообщения, включая
  уведомления о запуске или отключении функций Платформы, а также приглашения к
  участию в опросах, в том числе от партнёров Администратора.
  <p><br/></p>1.12. В случае обнаружения факта нарушения Покупателем законных прав и
  интересов Администратора Платформы и/или третьих лиц, действующего
  законодательства Республики Казахстан, а также положений настоящего Соглашения
  Администратор Платформы вправе по своему выбору принимать следующие меры как по
  отдельности, так и при необходимости совместно в соответствии с политикой
  Администратора Платформы и в зависимости от характера и частоты соответствующих
  нарушений:
  <p><br/></p> 1.12.1. Удалить аккаунт Покупателя, включая персональные данные, и/или
  заблокировать доступ к Платформе.
  <p><br/></p>1.12.2. Взыскать расходы, которые произведены или должны быть произведены
  Администратором, реальный ущерб, а также неполученные доходы, которые это лицо
  получило бы при обычных условиях оборота, если бы его право не было нарушено
  (упущенная выгода).
  <p><br/></p>1.13. Настоящее Соглашение составлено на русском и казахском языках. При
  необходимости и по усмотрению Администратора, Соглашение может быть переведено наиные языки. В случае разночтений, основным считается текст Соглашения, изложенный на
  русском языке.
  <p><br/></p> 1.14. Администратор Платформы не хранит платежные данные Покупателей — их
  обработка и хранение осуществляется поставщиком платежных услуг.
  <p><br/></p>1.15. Администратор оставляет за собой право обновлять функционал Платформы,
  добавлять или удалять услуги и функции, информируя об этом Покупателей заранее через
  доступные каналы связи (электронная почта, уведомления в приложении). Внесённые
  изменения вступают в силу с момента их опубликования.
  <p><br/></p> 1.16. Посредством Платформы строго запрещена реализация алкогольной продукции,
  а также продукции, содержащей алкоголь; сигареты и иная табачная продукция,
  наркотические и иные вещества, запрещенные или ограниченные в обороте на территории
  Республики Казахстан; живые насекомые, животные, пресмыкающиеся. По усмотрению
  Администратора Платформы, к реализации могут быть запрещены и иные товары.
  <p><br/></p>1.17. Покупатели подтверждают понимание того, что Платформа является
  сервисом приложением между Покупателем и Поваром, и Администратором не
  принимает на себя никакой ответственности, вытекающей из информации,
  предоставленной Покупателями и Поварами, а также не принимает на себя никакой
  ответственности за любые убытки или ущерб, которые могут быть понесены Покупателями
  в связи с осуществлением Поварами своей деятельности посредством Платформы, а также
  с нарушением настоящих Общих условий.
  <p><br/></p> 1.18.
  При оформлении Покупателем заказа, Платформа не является стороной
  отношений между Покупателем и Поварами, а лишь предоставляет интернет-площадку в
  виде Платформы (сервисное приложение), позволяющую Сторонам общаться через
  Администратора, размещать объявления, оформлять заказы, а также приобретать Продукты
  питания в необходимом количестве.
  <p><br/></p> 1.19. Администратор Платформы не делает никаких заверений или
  подтверждений относительно своих Поваров их личности или опыта. Поэтому
  Администратор Платформы рекомендует Покупателям всегда руководствоваться
  здравым смыслом и сохранять бдительность при выборе Продуктов питания и
  размещении любых заказов.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. Создание учетных записей</h3>
      <p>
        <p><br/></p> 2.1.1. Регистрация несовершеннолетних лиц, даже через их законных
        представителей, не допускается.
        <p><br/></p>  2.1.2. Администратор Платформы имеет право запросить документы для
        подтверждения возраста, личности, а также статуса Покупателя как индивидуального
        предпринимателя или юридического лица.
        <p><br/></p>2.1.3. Предоставление таких документов не накладывает на Администратора
        обязанности по проверке достоверности информации, и Администратор не несет
        ответственности за возможную недостоверность предоставленных данных или
        незаконные действия Покупателя.
        <p><br/></p> 2.2. Учетные записи: Каждый Покупатель может зарегистрировать только один
        аккаунт. Создание и использование нескольких учетных записей запрещено.
        <p><br/></p> 2.3. Регистрация и согласие с настоящим Соглашением: Для создания
        пользовательской страницы (личного аккаунта) необходимо пройти первичную
        регистрацию на Платформе либо на сайтах https://dom-food.com или https://domfood.kz и
        подтвердить согласие с условиями Соглашения путем нажатия кнопки «Согласиться и
        получить доступ».
        <p><br/></p> 2.4. Процедура регистрации и создание учетной записи:
        <p><br/></p>  2.4.1. Регистрация является бесплатной.2.4.2. Покупатель обязан указать действующий номер телефона, который будет
        использоваться для подтверждения регистрации и получения уведомлений. На этот номер
        будет отправлено SMS с кодом подтверждения.
        <p><br/></p>  2.4.3. После подтверждения регистрации Покупатель может создать личный
        аккаунт, указав имя, фамилию, адрес, электронную почту и, при желании, добавить
        фотографию.
        <p><br/></p> 2.5. Конфиденциальность данных для авторизации:
        <p><br/></p> 2.5.1. При регистрации Покупатель создает уникальные логин и пароль, которые
        являются конфиденциальными. Администратор Платформы рекомендует не использовать
        одинаковые учетные данные на других сайтах и платформах.
        <p><br/></p>  2.5.2. Администратор не несет ответственности за несанкционированный доступ к
        учетной записи Покупателя.
        <p><br/></p>  2.6. Точность и актуальность данных:
        <p><br/></p> 2.6.1. При регистрации на Платформе Покупатель обязан предоставить актуальные
        и достоверные контактные данные, включая имя и фамилию, электронную почту, номер
        телефона, иную информацию, согласно заполняемой заявке (для физического лица), или
        полное наименование, БИН и адрес местонахождения, электронную почту и номер
        телефона, а также полные ФИО, и контактный номер своего представителя, а также иную
        информацию, согласно заполняемой заявке, - для юридического лица.
        <p><br/></p> 2.6.2. В случае предоставления ложной информации или нарушения правил
        регистрации, Администратор вправе немедленно заблокировать учетную запись.
        <p><br/></p> 2.7. Проверка данных Администратором: Администратор Платформы не проверяет
        (и не обязана проверять) личность Покупателей или правдивость, актуальность, полноту
        и/или подлинность предоставленных ими данных. Администратор Платформы вправе в
        любой момент запросить у Покупателя подтверждающие документы для проверки
        актуальности данных. Не предоставление документации полностью или частично может
        быть приравнено к предоставлению недостоверной информации и влечет немедленную
        блокировку аккаунта Покупателя.
        <p><br/></p> 2.8. Обеспечение безопасности учетной записи: Покупатель несет полную
        ответственность за сохранность логина и пароля. Передача данных третьим лицам
        запрещена. При выявлении несанкционированного доступа Покупатель должен
        немедленно уведомить Администратора.
        <p><br/></p> 2.9. Запрет на использование чужих имен и оскорбительных выражений:
        Покупатель не имеет права выбирать или использовать имя другого Покупателя или имя,
        которое нарушает права на товарный знак или другие права собственности какой-либо
        третьей стороны, использование которого является или может быть незаконным, что может
        вызвать путаницу в отношении личности Покупателя или его связи с другими
        организациями. Также Покупателю запрещено применять любые оскорбительные или
        вульгарные слова или непристойные слова и выражения при регистрации аккаунта. В
        противном случае Администратор Платформы оставляет за собой право удалить любое
        вульгарное, неуместное или оскорбительное имя Покупателя с дальнейшей его
        блокировкой.
        <p><br/></p> 2.10. Покупатель при регистрации на Платформе обязуется принять условия политик,
        соглашений и правил, являющихся неотъемлемой частью настоящего Соглашения,
        размещенных на сайте https://dom-food.com и https://domfood.kz. В случае несогласия
        Покупатель обязан прекратить использование Платформы и воздержаться от оформления
        заказов на Платформе.
        <p><br/></p>2.11. Запрещенные действия с аккаунтом:
        <p><br/></p> 2.11.1. Покупателям запрещено использование своего аккаунте в незаконных целях,
        а также с целью нанесения ущерба правам и интересам третьих лиц или Платформе, ее
        содержимому и сервисам. Кроме того, Покупателям запрещено препятствовать
        нормальному использованию Платформы другими Покупателями. <p><br/></p> 2.11.2. Лица, нарушившие вышеуказанные обязательства, несут персональную
        полную ответственность за любые причиненные убытки или ущерб. При этом
        Администратор Платформы вправе удалить аккаунт такого Покупателя или заблокировать
        его, а в необходимых случаях – передать всю информацию в правоохранительные
        органы.
        <p><br/></p> 2.12. Администратор Платформы вправе самостоятельно удалять также отзывы и
        оценки Покупателей о Продуктах питания и услугах, реализуемых посредством
        Платформы, в случаях нарушения Покупателями правил этики общения, если оставленные
        отзывы и оценки носят оскорбительный, унижающий характер, ненормативную лексику.
        Однако, Администратор вправе осуществить проверку фактов, указанных Покупателем на
        Платформе, и в случае их подтверждения, удалить аккаунт Повара.
        <p><br/></p> 2.13. Покупателям категорически запрещено:
        <p><br/></p> 2.13.1. Использовать без специального на то разрешения Администратора
        Платформы автоматизированные скрипты (программы, боты, краулеры) для сбора
        информации на Платформе и/или взаимодействия с Платформой и его функционалом.
        <p><br/></p> 2.13.2. Осуществлять незаконные обработку, включая сбор, персональных данных
        других лиц.
        <p><br/></p>2.13.3. Осуществлять использование Платформы иным способом, кроме как через
        интерфейс, предоставленный Администратором Платформы на соответствующем Ресурсе,
        за исключением случаев, когда такие действия были прямо разрешены Покупателю в
        соответствии с отдельным соглашением с Администратором.
        <p><br/></p> 2.13.4. Воспроизводить, дублировать, копировать, продавать, осуществлять
        торговые операции и перепродавать доступ к использованию Платформы для каких-либо
        целей.
        <p><br/></p> 2.13.5. Использовать Платформу в целях иных, чем личное некоммерческого
        использование.
        <p><br/></p> 2.14. Удаление и блокировка учетной записи: Покупатели могут добровольно
        удалить свою учетную запись на Платформе. Кроме того, Администратор оставляет за
        собой право приостановить и/или деактивировать любую учетную запись Покупателя в
        случае, если Администратору станет известно о том, что Покупатель не соблюдает все
        основные требования к регистрации, или если у Администратора имеются основания
        подозревать мошенническое или незаконное использование учетной записи Покупателя,
        включая использование учетной записи Покупателя способом, противоречащим условиям
        настоящего Соглашения.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. Оформление заказов</h3>
    <p>
      <p><br/></p>  3.1. Учет характеристик Продуктов питания:
      <p><br/></p> 3.1.1. При выборе Продуктов питания на Платформе Покупатель обязан учитывать
      их состав, способ приготовления, вид упаковки и наличие противопоказаний.
      <p><br/></p>  3.1.2. В случае необходимости уточнения состава или других характеристик,
      Покупатель должен написать Администратору через сервис Платформы.
      <p><br/></p>  3.1.3. Повар несет полную ответственность за предоставление точной
      информации о составе, весе, упаковке, количестве, и сроках готовности Продуктов питания.
      <p><br/></p>  3.1.4. Администратор не несет ответственность за качество и количество
      Продуктов питания, предоставленных Поварами.
      <p><br/></p>  3.2. Ценообразование и наценка:
      <p><br/></p>   3.2.1. Цены, применимые к каждому продукту питания, устанавливаются Поварами
      по их собственному усмотрению, и Администратор Платформы не имеет права
      вмешиваться в их предпринимательскую деятельность, в том числе ценовую политику. При
      этом, Администратор может устанавливать наценку на Продукты питания,
      предоставляемые Поварами, в результате чего конечная цена для Покупателей будет
      определяться с учетом такой наценки.  <p><br/></p>  3.3. При оформлении заказа, Покупателю необходимо обратить внимание, что в
      некоторых случаях цена на Продукты питания, указанная Поварами, может не включать в
      себя стоимость упаковки, доставки, которая может взиматься с Покупателя дополнительно,
      а также включать в себя наценку Администратора.
      <p><br/></p>  3.4. Процесс оформления заказа: Покупатель может оформлять заказы на Продукты
      питания и услуги через Платформу, следуя инструкциям, предоставленным на Платформе
      или в приложении по адресу https://dom-food.com и https://domfood.kz/.
      <p><br/></p>  3.5. Оформление заказа считается завершенным только после его подтверждения
      Покупателем посредством приложения и оплаты через Платформу. При этом Покупатель
      обязуется указать верные данные своей банковской карты (при необходимости).
      Обслуживающим банком возможно взимание комиссионного вознаграждения при
      осуществлении платежа, за которое Администратор не отвечает.
      <p><br/></p>  3.6. Покупатель обязуется оплатить заказ в соответствии с установленными
      Поварами тарифами и выбранным способом оплаты.
      <p><br/></p>  3.7. Повар, принявший заказ, обязан приготовить, упаковать и передать его в
      соответствии с указанными условиями. Платформа предоставляет Покупателю
      информацию о Продуктах питания, ценах, условиях и сроках доставки, а также
      возможность выбора удобного времени получения заказа.
      <p><br/></p>  3.8. При заказе услуг доставки Платформа уведомляет Курьера о том, что доступна
      возможность доставки. Порядок заказа услуги доставки и оплата размещены на сайте
      https://dom-food.com и https://domfood.kz/.
      <p><br/></p> 3.9. Если Покупатель заказывает доставку заказа, заказ доставляется в место,
      указанное Покупателем при оформлении заказа.
      <p><br/></p>  3.9.1. Покупатель должен быть доступен для приема звонков по номеру телефона,
      указанному им в заявке при оформлении заказа.
      <p><br/></p>  3.9.2. Если предоставленный Покупателем номер телефона недоступен, доставка
      может быть отменена, а с Покупателя может взиматься полная стоимость заказа.
      <p><br/></p>   3.10. Ответственность за точность данных:
      <p><br/></p>   3.10.1. Покупатели несут единоличную ответственность за предоставление точных
      адресов получения и доставки заказа.
      <p><br/></p>   3.10.2. Если Покупатель отсутствует по указанному адресу доставки и,
      следовательно, доставка заказа не представляется возможной, Покупатель обязуется
      оплатить полную стоимость услуги курьерской доставки и стоимость Продуктов питания.
      <p><br/></p>   3.10.3. Получение Покупателем заказа по указанному адресу доставки считается
      подтверждением факта передачи заказа.
      <p><br/></p>   3.10.4. Если заказ выполнен неправильно либо содержит некачественные Продукты
      питания, Покупатель после получения заказа должен немедленно связаться с Поваром через
      сервисы Платформы, а также известить об этом Администратора Платформы через сервисы
      Платформы.
      <p><br/></p>   3.10.5. Если в процессе оформления заказа Покупатель обнаружил ошибку, то
      Покупатель может связаться со службой поддержки Платформы путем отправки письма на
      электронную почту support@dom-food.com для исправления ошибок в заказе до момента
      принятия заказа к выполнению. В случае обнаружения Покупателем ошибки после
      принятия заказа Поваром к исполнению, ответственность за все последствия полностью
      ложится на Покупателя.
      <p><br/></p>  3.11. Уведомления о статусе заказа: Покупатель будет получать уведомления о
      статусе заказа (подтверждение, готовность, отправка, доставка, контактные данные
      курьера) через приложение или по электронной почте, указанной Покупателем при
      регистрации.
      <p><br/></p> 3.12. Время доставки: Время доставки каждого заказа рассчитывается
      индивидуально программными вычислительными ресурсами, исходя из региона доставки.
      Покупатель принимает и соглашается, что любое время доставки является расчетным иприблизительным временем. Администратор и Повара не могут гарантировать, что заказ
      будет доставлен Покупателю строго в указанное время. Сроки доставки Продуктов питания
      зависят от таких факторов, как транспортные пробки, часы пик, форс-мажорные
      обстоятельства (например, наличие ДТП по пути следования курьера) и погодные условия.
      <p><br/></p>    3.13. Компенсация за задержку доставки: При задержке доставки Покупатель
      может потребовать снижения стоимости доставки или компенсации в размере до 10% от
      стоимости заказа либо получить скидку при оформлении нового заказа. Размер такой
      скидки определяется Администратором Платформы.
      <p><br/></p>  3.14. Оплата заказов:
      <p><br/></p>  3.14.1. Оплата производится через Платформу в форме 100% предоплаты.
      <p><br/></p>  3.14.2. Доступны различные способы оплаты, включая банковские карты и
      электронные кошельки.
      <p><br/></p> 3.14.3. Карты не хранятся на серверах Платформы, а платежи обрабатываются через
      внешнюю платежную платформу.
      <p><br/></p>  3.15. Валюта расчетов: Расчеты на Платформе осуществляются в тенге (KZT).
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. Отмена заказа и отказ от заказа</h3>
      <p>
        <p><br/></p>  4.1. Право на отмену до подтверждения:
        <p><br/></p>  4.1.1. Покупатель имеет право полностью или частично отменить заказ до момента
        его подтверждения Поваром, то есть до принятия заказа к исполнению. Отмена заказа
        подразумевает также отмену доставки Продуктов питания.
        <p><br/></p>  4.1.2. После подтверждения заказа Поваром изменить или отменить заказ
        невозможно. В связи с этим Покупатель обязан проверить правильность своего выбора и
        данных в заявке на заказ перед его размещением.
        <p><br/></p>  4.1.3. Ответственность за правильность указанных данных в заявке целиком лежит
        на Покупателе.
        <p><br/></p>  4.2. Право Администратора на отмену заказа: Администратор Платформы вправе
        отменить заказ Покупателя в случае нарушения условий настоящего Соглашения,
        технического сбоя, ошибки в системе или невозможности выполнения заказа по
        объективным причинам (например, недоступности Повара или отсутствия нужных
        ингредиентов). Администратор уведомляет Покупателя о такой отмене по доступным
        контактным данным.
        <p><br/></p>   4.3. Возврат Продуктов питания ненадлежащего качества:
        <p><br/></p> 4.3.1. В случае получения Продуктов питания ненадлежащего качества Покупатель
        имеет право на возврат Продуктов питания Повару.
        <p><br/></p>  4.3.2. Для этого Покупателю необходимо незамедлительно связаться с Партнером,
        у которого были приобретены Продукты питания, и уведомить Администратора
        Платформы о данном факте.
        <p><br/></p>  4.3.3. Сообщение должно быть подкреплено фото- и видеоматериалами,
        подтверждающими факт некачественного блюда. В случае отравления Продуктами
        питания, необходимо предоставить медицинские документы, а затем — заключение
        экспертизы, подтверждающее ненадлежащее качество Продуктов питания.
        <p><br/></p>  4.3.4. Возврат и обмен Продуктов питания производится в соответствии с
        Политикой возврата и обмена продуктов питания, являющейся неотъемлемой частью
        настоящего Соглашения.
        <p><br/></p>  4.4. Неполный или несоответствующий заказ: Если Продукты питания доставлены
        не полностью, не соответствует заявленному наименованию, или упаковка не соответствует
        требованиям, Покупатель вправе отказаться от заказа с полным возвратом суммы или
        потребовать уменьшения цены заказа.
        <p><br/></p>  4.5. В случае отмены заказа после его принятия к исполнению возврат средств не
        производится.4.6. Дополнительная информация: Администратор оставляет за собой право
        запросить дополнительную информацию у Покупателя для подтверждения претензий,
        включая описание проблемы и документы, подтверждающие обоснованность претензий.
        <p><br/></p>  4.7. Ожидания по вкусу: Несоответствие вкусовых предпочтений Покупателя не
        признается фактом предоставления Продуктов питания ненадлежащего качества и не
        является основанием для возврата.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. Порядок возврата средств Покупателю</h3>
    <p>
      <p><br/></p> 5.1. Возврат средств при отмене заказа:
      <p><br/></p> 5.1.1. В случае отмены или отзыва заказа до его подтверждения Поваром, все
      произведенные Покупателем платежи, включая плату за доставку, подлежат возврату в
      полном объеме в соответствии с Политикой возврата, размещенной на сайте https://dom-
      food.com и https://domfood.kz/.
      <p><br/></p> 5.1.2. Возврат денежных средств осуществляется на банковскую карту
      Покупателя, с которой была произведена оплата, в течение 10 (десяти) календарных дней с
      момента получения Покупателем уведомления об отмене заказа.
      <p><br/></p>5.1.3. В случае частичной отмены заказа возврат средств производится только за
      отмененную часть заказа. Прочие платежи возврату не подлежат.
      <p><br/></p>  5.2. Комиссия за обработку платежей: Платформа не взимает комиссию за оплату
      заказов, однако платежные системы и банки могут удерживать комиссию за обработку
      транзакций. Такие комиссии не подлежат возврату, за исключением случаев,
      предусмотренных правилами конкретной платежной системы.
      <p><br/></p> 5.3. Сроки возврата при форс-мажорных обстоятельствах: В случае
      возникновения форс-мажорных обстоятельств, таких как технические сбои или блокировка
      банковских счетов, сроки возврата могут быть увеличены до 30 (тридцати) календарных
      дней. Платформа уведомляет Покупателя о причинах задержки и предполагаемых сроках
      возврата.
      <p><br/></p>  5.4. Уведомление о возврате средств: Платформа уведомляет Покупателя о возврате
      средств путем отправки уведомления на электронный адрес, указанный при регистрации,
      либо иным доступным способом (например, через SMS или уведомление в приложении).
      <p><br/></p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. Права и обязанности Покупателей</h3>
    <p>
      <p><br/></p> 6.1. Права Покупателей:
      <p><br/></p> 6.1.1. Покупатели имеют право на доступ к информации о Продуктах питания и
      услугах, размещенных на Платформе, включая цены, условия доставки и отзывы других
      Покупателей.
      <p><br/></p> 6.1.2. Покупатели вправе получать Продукты питания, соответствующие
      описанию на Платформе.
      <p><br/></p> 6.1.3. Покупатели имеют право на возврат средств в случае некачественного
      приготовления Продуктов питания, доставки испорченных Продуктов питания или
      нарушения условий доставки, согласно Политике возврата и обмена Продуктов питания,
      являющейся неотъемлемой частью настоящего Соглашения.
      <p><br/></p>6.1.4. Покупатели имеют право обращаться к Администратору по вопросам,
      связанным с использованием Платформы, оплатой и доставкой Продуктов питания.
      <p><br/></p> 6.1.5. Покупатели могут в любой момент удалить свой аккаунт и запросить
      удаление всех своих данных с Платформы в соответствии с Политикой
      конфиденциальности.
      <p><br/></p> 6.2. Обязанности Покупателей:
      <p><br/></p> 6.2.1. Покупатели обязаны предоставлять точную и актуальную информацию
      при регистрации на Платформе и оформлении заказов, а также следить за актуальностью
      данных.  <p><br/></p>6.2.2. Покупатели обязаны оплачивать заказы в соответствии с установленными
      тарифами и условиями, указанными на Платформе.
      <p><br/></p> 6.2.3. Покупатели обязаны использовать Платформу исключительно в законных
      целях и не злоупотреблять её функциями (например, не оформлять ложные заказы).
      <p><br/></p> 6.2.4. Покупатели несут ответственность за действия третьих лиц, если те
      использовали их аккаунт, включая случаи несанкционированного доступа.
      <p><br/></p> 6.2.5. Покупатели имеют право оставлять честные и корректные отзывы о
      приобретённых Продуктов питания и услугах, соблюдая этику общения.
      <p><br/></p> 6.2.6. Покупатели не могут использовать Платформу для рекламных или
      коммерческих целей без согласия Администратора Платформы.
      <p><br/></p> 6.2.7. Покупатели обязаны соблюдать условия настоящего Соглашения, а также
      правила и политики, установленные Администратором Платформы, и являющиеся
      неотъемлемой частью настоящего Соглашения.
      <p><br/></p> 6.3. Покупателю запрещено:
      <p><br/></p>6.3.1. Воспроизводить, копировать, продавать, использовать для коммерческих
      целей любые части Платформы, а также нарушать права других Пользователей и
      Поваров, включая интеллектуальные права на объекты, принадлежащие Администратору
      Платформы (товарные знаки, логотипы и другие охраняемые законом объекты).
      <p><br/></p>6.3.2. Выдавать себя за представителя другого Пользователя или иного физического
      или юридического лица.
      <p><br/></p> 6.3.3. Использовать учетную запись для выполнения функций Платформы в
      интересах другого Покупателя без его полномочий.
      <p><br/></p> 6.3.4. Передавать информацию о средствах доступа (логин и пароль) к учетной
      записи третьим лицам без согласия Покупателя.
      <p><br/></p>6.3.5. Собирать и хранить персональные данные третьих лиц с нарушением
      законодательства Республики Казахстан.
      <p><br/></p> 6.3.6. Содействовать действиям, направленным на нарушение ограничений и
      запретов, установленных настоящим Соглашением.
      <p><br/></p> 6.3.7. Нарушать нормы действующего законодательства Республики Казахстан
      и иных нормативных актов, указанных в Соглашении.
    </p>
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7 Ответственность сторон</h3>
      <p>
        <p><br/></p>  7.1. Покупатель самостоятельно несет ответственность перед третьими лицами за
        свои действия, связанные с использованием Платформы, в том числе в случаях, если такие
        действия приведут к нарушению прав и законных интересов третьих лиц, а также за
        соблюдение законодательства Республики Казахстан при использовании Платформы.
        <p><br/></p>  7.2. Покупатель несет ответственность за все действия (а также их последствия),
        совершаемые Покупателем, включая случаи добровольной передачи Покупателем данных
        для доступа к аккаунту Пользователя третьим лицам на любых условиях (в том числе по
        договорам или соглашениям).
        <p><br/></p> 7.3. Покупатель подтверждает, что Администратор Платформы не несет
        ответственности за прямые или косвенные убытки, которые были причинены в результате
        доступа третьих лиц к аккаунту Покупателя.
        <p><br/></p>  7.4. Администратор Платформы не несет никакой ответственности за задержки,
        сбои, неверную или несвоевременную доставку, удаление или несохранность каких-либо
        уведомлений. При этом Администратор оставляет за собой право повторного направления
        любого уведомления, в случае его неполучения Покупателем.
        <p><br/></p> 7.5. Администратор Платформы также не гарантирует бесперебойную работу и
        доступность системы. В случае технических сбоев Администратор обязуется приложить
        все возможные усилия для скорейшего восстановления работы, однако не несет
        ответственности за убытки, вызванные такими сбоями.7.6. Администратор Платформы не несёт никакой ответственности за
        персональные данные Покупателя, которые были им предоставлены сторонним ресурсам
        и/или иным третьим лицам в случае перехода на них с Платформы.
        <p><br/></p>  7.7. Администратор Платформы не отвечает за возможную потерю и/или порчу
        данных, которая может произойти из-за нарушения Покупателем положений настоящего
        Соглашения, а также неправильного доступа и/или использования персонализированных
        сервисов Платформы.
        <p><br/></p>  7.8. Администратор Платформы не несет ответственности за задержки или сбои в
        предоставлении услуг, вызванные форс-мажорными обстоятельствами, такими как
        стихийные бедствия, технические сбои, перебои в работе интернет-провайдеров и прочие
        обстоятельства, находящиеся вне контроля Администратора Платформы.
        <p><br/></p> 7.9. Ответственность за действия несовершеннолетних, включая приобретение
        ими Продуктов питания из каталога на Платформе, лежит на законных представителях
        несовершеннолетних, и такие Продукты питания подлежат оплате в полном объеме, как
        если бы их заказал сам Покупатель.
        <p><br/></p>  7.10. Администратор Платформы не несет ответственности в случае
        неисполнения и/или ненадлежащего исполнения Поваром обязательств по продаже
        Покупателю Продуктов питания надлежащего качества, в том числе в связи с
        предоставлением последним недостоверных и/или недействительных данных о себе, а
        равно невыполнение им условий настоящего Соглашения, при этом за такие нарушения
        прямая ответственность лежит непосредственно на Поваре.
        <p><br/></p>  7.11. Администратор Платформы не несет ответственность за сроки доставки
        Продуктов питания, а также за любой ущерб или порчу продуктов в период доставки.
        <p><br/></p>  7.12. Администратор Платформы не несет ответственности за перебои в работе
        сервиса, ошибки подключения, недоступность или сбои в работе службы доступа к
        Интернету, перебои в работе Интернета или любые другие вопросы, находящиеся за
        пределами ее контроля.
        <p><br/></p>  7.13. Администратор Платформы не контролирует и не несет ответственности за
        контент, загружаемый Покупателями и/или Поварами, его актуальность, полноту и
        актуальность данных, а также иную информацию, предоставляемую Покупателями и
        Поварами на Платформу.
        <p><br/></p>  7.14. В связи с тем, что Администратор Платформы не несет ответственность за
        действия или бездействия третьих лиц, включая, но не ограничиваясь иных Покупателей и
        своих Поваров, Покупатели соглашаются, что все имеющиеся у них претензии по качеству
        Продуктов питания и его доставке должны быть предъявлены непосредственно к
        Поварам. Никакие претензии и иски по качеству полученных Покупателем Продуктов
        питания и услуг посредством Платформы к Администратору Платформы удовлетворению
        не подлежат в виду определения Администратора Платформы как ненадлежащего
        ответчика.
        <p><br/></p>   7.15. Администратор Платформы не несет ответственности за содержание
        отзывов, опубликованных Покупателями в приложении и за его пределами, и не несет
        ответственности за любой ущерб и/или оскорбления, вызванные такими отзывами.
        <p><br/></p>    7.16. Администратор Платформы не занимается предварительной модерацией или
        цензурой информации Покупателей и предпринимает действия по защите прав и интересов
        лиц и обеспечению соблюдения требований законодательства Республики Казахстан
        только после обращения заинтересованного лица к Администратору Платформы в
        установленном порядке.
        <p><br/></p>   7.17. Администратор Платформы несет ответственность за убытки, причиненные
        Покупателю прямыми неправомерными действиями работниками Администратора
        Платформы. В случае возникновения убытков по вине Платформы, её ответственность
        ограничивается суммой заказа, который вызвал убытки. Администратор не несет
        ответственности за косвенные убытки или упущенную выгоду. <p><br/></p>  7.18. Администратор Платформы не дает никаких гарантий в отношении каких-
        либо рекомендаций. Любые рекомендации Поваров, Покупателей или третьих лиц не
        имеют юридической силы и носят только рекомендательный характер, выражая
        субъективное мнение лица, давшего рекомендацию.
        <p><br/></p>   7.19. Администратор Платформы вправе без предварительного уведомления
        Покупателя передавать информацию о Покупателях правоохранительным органам по их
        запросам, согласно действующему законодательству.
        <p><br/></p>  7.20. Покупатели несут ответственность за собственные действия в связи с
        использованием Платформы в соответствии с действующим законодательством
        Республики Казахстан. Нарушение настоящего Соглашения и действующего
        законодательства Республики Казахстан влечет за собой гражданско-правовую,
        административную и уголовную ответственность.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. Условия конфиденциальности</h3>
<p>
  <p><br/></p>  8.1. Администратор Платформы осуществляет сбор и обработку персональных
  данных Покупателей в соответствии с Политикой конфиденциальности персональных
  данных. Персональные данные могут включать сведения о заказах, платежах,
  местоположении и другую информацию, необходимую для предоставления услуг.
  <p><br/></p>  8.2. Платформа использует файлы cookie и аналогичные технологии для сбора
  данных о взаимодействии Покупателей с Платформой с целью улучшения работы сайта и
  предоставления персонализированных рекомендаций.
  <p><br/></p>  8.3. Персональные данные Покупателей используются исключительно для
  выполнения заказов, обеспечения безопасности транзакций и улучшения качества
  обслуживания.
  <p><br/></p>   8.4. Платформа может использовать данные Покупателей для анализа, улучшения
  работы системы, а также для предоставления персонализированных рекомендаций,
  уведомлений и маркетинговых предложений.
  <p><br/></p> 8.5. Платформа принимает все необходимые меры для защиты данных Покупателей,
  и в случае утечки данных по вине платёжных систем Администратор не несет
  ответственности.
  <p><br/></p>  8.6. Персональные данные Покупателей не передаются третьим лицам без их
  согласия, за исключением случаев, предусмотренных законодательством Республики
  Казахстан или необходимости выполнения заказа (например, передача данных курьерам
  для доставки, передача данных платежным системам в рамках обработки транзакций).
  <p><br/></p> 8.7. Персональные данные Покупателей хранятся на защищённых серверах в
  течение всего периода использования Платформы и ещё 2 лет после закрытия аккаунта
  Покупателя, если иное не предусмотрено законодательством Республики Казахстан.
  <p><br/></p> 8.8. Платформа принимает все необходимые меры для обеспечения безопасности
  персональных данных Покупателей, включая использование шифрования и современных
  методов защиты информации.
  <p><br/></p> 8.9. Покупатели имеют право запрашивать доступ к своим персональным данным,
  их исправление, удаление или ограничение обработки в соответствии с Политикой
  конфиденциальности.
  <p><br/></p> 8.10. Покупатели могут в любой момент отозвать согласие на обработку данных,
  однако это может ограничить возможность использования определённых функций
  Платформы.
  <p><br/></p>  8.11. Покупатель подтверждает согласие с тем, что его персональная информация,
  предоставленная при использовании Платформы, может быть передана партнёрам, с
  которыми Покупатель заключает договор.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">9.
        Иные положения</h3>
<p><p><br/></p>9.1. Настоящее Соглашение представляет собой договор, определяющий права,
  обязанности и порядок использования онлайн Платформы между Покупателем и
  Администратором Платформы.
  <p><br/></p> 9.2. Настоящее Соглашение регулируется и толкуется в соответствии с
  законодательством Республики Казахстан. Стороны обязуются предпринять все разумные
  меры для урегулирования спорных вопросов до обращения в судебные органы, чтобы
  минимизировать риски и затраты. В случае, если стороны не пришли к соглашению в
  досудебном порядке, спор подлежит рассмотрению в судебном порядке в
  Специализированном межрайонном экономическом суде города Астаны (договорная
  подсудность).
  <p><br/></p> 9.3. Настоящее Соглашение вступает в силу для Покупателя с момента размещения на
  онлайн Платформе и действует в течение неопределенного срока на все последующие
  обновления онлайн Платформы.</p>
    </div>
  )
}

export default UserAgreement
