import React, { useState, useRef } from "react";
import { useFormik } from 'formik';
// import SingleSelectFormField from "../SingleSelectFormField";
// import YandexMap from "../YandexMap";
// import UploadInput from "../Dropzone";
import axios from 'axios';
import classNames from "../../utils";
import toast from 'react-hot-toast'
// import SelectRadius from "../SelectRadius";
import ErrorMassage from "../ErrorMassage";
import {Link} from "react-router-dom";
import Fio from '../../images/Frame.svg'
import Email from '../../images/Frame (1).svg'
// import Nik from '../../images/fi_7585583.svg'
import Phone from '../../images/Frame (2).svg'

const CookerForm = ({ setIsOpenMobileNav }) => {
  const [access, setAccess] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  const fieldRefs = {
    full_name: useRef(null),
    email: useRef(null),
    phone: useRef(null),
    // restaurant_name: useRef(null),
    // zip_code: useRef(null),
    // state_id: useRef(null),
    // city_id: useRef(null),
    // radius: useRef(null),
  };

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      // restaurant_name: '',
      // state_id: '',
      // city_id: '',
      // address: '',
      // webpage: '',
      // lat: '',
      // lng: '',
      // zip_code: '',
      // radius: '',
      // questions: [
      //   { question: "Если у вас действующее ИП?", answer: "" },
      //   { question: "Готовы ли Вы зарегистрировать ИП?", answer: "" },
      // ],
      // licenses: [],
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const payload = { ...values };

      // if (values.questions[0].answer === "да") {
      //   payload.questions = [values.questions[0]];
      // }
      try {
        const response = await axios.post('https://api.dom-food.com/page/register-restaurant', payload, {
          headers: {
            "Content-Type": 'multipart/form-data'
          }
        });
        console.log('Form submitted successfully:', response.data);
        setIsOpenMobileNav(false)
        toast.success('Your account has been created, waiting for improvement from admin')
      } catch (error) {
        setErrors(error.response.data.errors);
        focusFirstError(error.response.data.errors);

        console.error('Error submitting form:', error);
      }
      finally {
        setIsSubmitting(false);
      }
    },
  });

  // const handleAnswerChange = (index, answer) => {
  //   formik.setFieldValue(`questions[${index}].answer`, answer);
  //   if (index === 0 && answer === "no") {
  //     formik.setFieldValue("questions[1].answer", "");
  //   }
  // };

  const checkAccess = () => {
    setAccess(!access);
  };

  const focusFirstError = (errors) => {
    for (const key in errors) {
      if (fieldRefs[key]) {
        fieldRefs[key].current.focus();
        break;
      }
    }
  };

  return (
    <>
      <p className="text-base md:text-2xl text-[#333F51] font-normal my-4 sm:my-0 font-custom">Мы перезвоним вам в течение <span className="text-[#333F51] font-bold">10 минут</span></p>
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4 px-8 md:px-[60px]">
        <div className="px-3 flex hover:border-orange-500 border border-1 border-gray-400 rounded-full max-w-[460px] bg-white">
          <img alt="svg" src={Fio} />
          <input
            ref={fieldRefs.full_name}
            name="full_name"
            value={formik.values.full_name}
            onChange={formik.handleChange}
            className="h-[42px] md:h-[52px] px-[20px] w-full rounded-full outline-none font-custom bg-white"
            placeholder="ФИО*"
          />
        </div>
        { errors.full_name && <ErrorMassage message={errors.full_name} /> }
        <div className="px-3 flex hover:border-orange-500 border border-1 border-gray-400 rounded-full max-w-[460px]">
          <img alt="svg" src={Email} />
          <input
            ref={fieldRefs.email}
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className="h-[42px] md:h-[52px] px-[20px] w-full rounded-full outline-none font-custom bg-white"
            placeholder="Ваш e-mail*"
          />
        </div>
        { errors.email && <ErrorMassage message={errors.email} /> }
        {/*<div className="px-3 flex hover:border-orange-500 border border-1 border-gray-400 rounded-full max-w-[460px]">*/}
        {/*  <img alt="svg" src={Nik} />*/}
        {/*  <input*/}
        {/*    ref={fieldRefs.restaurant_name}*/}
        {/*    name="restaurant_name"*/}
        {/*    value={formik.values.restaurant_name}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    className="h-[45px] px-[20px] w-full rounded-full outline-none"*/}
        {/*    placeholder="Никнейм*"*/}
        {/*  />*/}
        {/*</div>*/}
        {/*{ errors.restaurant_name && <ErrorMassage message={errors.restaurant_name} /> }*/}
        <div className="px-3 flex hover:border-orange-500 border border-1 border-gray-400 rounded-full max-w-[460px]">
          <img alt="svg" src={Phone} />
          <input
            ref={fieldRefs.phone}
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            className="h-[42px] md:h-[52px] px-[20px] w-full rounded-full outline-none font-custom bg-transparent"
            placeholder="+7 (xxx) xxx xx xx"
          />
        </div>
        { errors.phone && <ErrorMassage message={errors.phone} /> }

        {/*<input*/}
        {/*  ref={fieldRefs.zip_code}*/}
        {/*  name="zip_code"*/}
        {/*  value={formik.values.zip_code}*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"*/}
        {/*  placeholder="Zip Code*"*/}
        {/*/>*/}
        {/*{ errors.zip_code && <ErrorMassage message={errors.zip_code} /> }*/}

        {/*<input*/}
        {/*  type="text"*/}
        {/*  name="webpage"*/}
        {/*  value={formik.values.webpage}*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"*/}
        {/*  placeholder="Веб-страница/Инстаграм"*/}
        {/*/>*/}

        {/*<p className="text-[#333F51] text-lg text-start" ref={fieldRefs.state_id}>Область <span className="text-red-500">*</span></p>*/}
        {/*<SingleSelectFormField*/}
        {/*  formik={formik}*/}
        {/*/>*/}
        {/*{ errors.state_id && <ErrorMassage message={errors.state_id} /> }*/}
        {/*{ errors.city_id && <ErrorMassage message={errors.city_id} /> }*/}

        {/*<p className="text-[#333F51] text-lg text-start" ref={fieldRefs.radius}>Выберите радиус (метр) <span className="text-red-500">*</span></p>*/}
        {/*<SelectRadius*/}
        {/*  formik={formik}*/}
        {/*/>*/}
        {/*{ errors.radius && <ErrorMassage message={errors.radius} /> }*/}

        {/*<p ref={fieldRefs?.errors?.['questions.0.answer']} className="text-[#333F51] text-lg text-start">{formik.values.questions[0].question} <span className="text-red-500">*</span></p>*/}
        {/*<div className="flex gap-x-4">*/}
        {/*  <div className="flex gap-x-4">*/}
        {/*    <input*/}
        {/*      id="q1-yes"*/}
        {/*      type="radio"*/}
        {/*      name="question1"*/}
        {/*      value="yes"*/}
        {/*      checked={formik.values.questions[0].answer === 'да'}*/}
        {/*      onChange={() => handleAnswerChange(0, "да")}*/}
        {/*      className=""*/}
        {/*    />*/}
        {/*    <p>да</p>*/}
        {/*  </div>*/}
        {/*  <div className="radio-group flex gap-x-4">*/}
        {/*    <input*/}
        {/*      id="q1-no"*/}
        {/*      type="radio"*/}
        {/*      name="question1"*/}
        {/*      value="no"*/}
        {/*      checked={formik.values.questions[0].answer === 'нет'}*/}
        {/*      onChange={() => handleAnswerChange(0, "нет")}*/}
        {/*      className=""*/}
        {/*    />*/}
        {/*    <p>нет</p>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*{errors['questions.0.answer'] && <ErrorMassage message={errors['questions.0.answer']} />}*/}

        {/*{formik.values.questions[0].answer === 'нет' && (*/}
        {/*  <>*/}
        {/*    <p className="text-[#333F51] text-lg text-start">{formik.values.questions[1].question} <span className="text-red-500">*</span></p>*/}
        {/*    <div className="flex gap-x-4">*/}
        {/*      <div className="flex gap-x-4">*/}
        {/*        <input*/}
        {/*          id="q2-yes"*/}
        {/*          type="radio"*/}
        {/*          name="question2"*/}
        {/*          value="yes"*/}
        {/*          checked={formik.values.questions[1].answer === 'да'}*/}
        {/*          onChange={() => handleAnswerChange(1, "да")}*/}
        {/*          className=""*/}
        {/*        />*/}
        {/*        <p>да</p>*/}
        {/*      </div>*/}
        {/*      <div className="radio-group flex gap-x-4">*/}
        {/*        <input*/}
        {/*          id="q2-no"*/}
        {/*          type="radio"*/}
        {/*          name="question2"*/}
        {/*          value="no"*/}
        {/*          checked={formik.values.questions[1].answer === 'нет'}*/}
        {/*          onChange={() => handleAnswerChange(1, "нет")}*/}
        {/*          className=""*/}
        {/*        />*/}
        {/*        <p>нет</p>*/}
        {/*      </div>*/}
        {/*      <div className="radio-group flex gap-x-4">*/}
        {/*        <input*/}
        {/*          id="q2-maybe"*/}
        {/*          type="radio"*/}
        {/*          name="question2"*/}
        {/*          value="maybe"*/}
        {/*          checked={formik.values.questions[1].answer === 'может быть'}*/}
        {/*          onChange={() => handleAnswerChange(1, "может быть")}*/}
        {/*          className=""*/}
        {/*        />*/}
        {/*        <p>может быть</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}
        {/*{errors['questions.1.answer'] && <ErrorMassage message={errors['questions.1.answer']} />}*/}


        {/*<p ref={fieldRefs.licenses} className="text-[#333F51] text-lg text-start">Добавьте Ваши сертификаты и лицензии в качестве повара.</p>*/}

        {/*<UploadInput formik={formik} />*/}
        {/*{ errors.lat && <ErrorMassage message={errors.licenses} /> }*/}

        {/*<YandexMap errors={errors} formik={formik} />*/}
        {/*{ errors.lat && <ErrorMassage message={errors.lat} /> }*/}
        {/*{ errors.lng && <ErrorMassage message={errors.lng} /> }*/}

        <div className="flex gap-x-4">
          <input onChange={checkAccess} type="checkbox" />
          <label className="text-[#333F51] text-sm text-start">Я принимаю условия <Link className="text-[#FDA110] underline" target="_blank" to="https://dom-food.com/public_offer_company">Публичной Оферты</Link> и <Link className="text-[#FDA110] underline" target="_blank" to="https://dom-food.com/user_agreement">Пользовательского Соглашения</Link>  а также соглашаюсь с <Link className="text-[#FDA110] underline" target="_blank" to="https://dom-food.com/privacy_policy">Политикой Конфиденциальности</Link> .</label>
        </div>
        <button
          disabled={access || isSubmitting}
          type="submit"
          className={classNames(
            access || isSubmitting ? 'cursor-not-allowed' : 'cursor-pointer',
            'w-full mt-8 rounded-[30px] h-[46px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold'
          )}>
          {isSubmitting ? 'Загрузка...' : 'Отправить заявку'}
        </button>
        {/*<p className="text-[#556987] text-[14px] text-center font-normal">Мы будем рады видеть вас в нашей команде талантливых поваров!</p>*/}
      </form>
    </>
  );
};

export default CookerForm;
