import Logo from '../images/logo.png';
import Watsapp from '../images/watsapp.svg';
import Instagram from '../images/instagram.svg';
import Phone from '../images/phone.svg';
import Email from '../images/email.svg';
import { Link as RouterLink } from 'react-router-dom'; // Import as RouterLink
import { Link as ScrollLink } from 'react-scroll'; // Import as ScrollLink
import CustomModalNew from "./CustomModalNew";
import { useState } from "react";
import Close from '../images/close_small.png';
import Burger from '../images/menu.png';
import CustomDrawer from "./CustomDrawer";
import CookerForm from "./Forms/CookerForm";

// Menu items for smooth scroll
const menu = [
  { title: 'Главная', to: 'blockOne' },
  { title: 'О нас', to: 'about' },
  { title: 'Условия', to: 'forFooder' },
  { title: 'Возможности', to: 'howItsWork' },
  { title: 'FAQ', to: 'questionsBlock' },
  { title: 'Обратная связь', to: 'contactUsBlock' }
];

// Icons block for external links and router navigation
const iconsBlock = [
  { title: 'WhatsApp', icon: Watsapp, url: '+77779001033' },
  { title: 'Instagram', icon: Instagram, url: 'https://www.instagram.com/domfood.kaz/profilecard/?igsh=a3c5azJmYm9hdjd2' },
  { title: 'Phone', icon: Phone, url: '+77779001033' },
  { title: 'Email', icon: Email, url: 'support@dom-food.com' }
];

const Header = () => {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);
  const [showBurger, setShowBurger] = useState(false);

  const toggleMobileNav = () => {
    setShowBurger(!showBurger);
  };

  return (
    <div className='mt-4 md:mt-10 flex justify-between lg:justify-center gap-x-4 md:gap-x-2 lg:gap-x-12 items-center px-7 md:px-16 lg:px-20 xl:px-36'>
      <RouterLink to="/">
        <img className="max-w-16" src={Logo} alt="png" />
      </RouterLink>
      <div className='hidden md:flex gap-x-[18px]'>
        {menu.map((item, index) => (
          <ScrollLink
            key={index}
            to={item.to}
            smooth={true}
            offset={-70}
            duration={500}
            className="cursor-pointer text-[#333F51] text-sm lg:text-base text-nowrap"
          >
            {item.title}
          </ScrollLink>
        ))}
      </div>
      <div className='hidden lg:flex lg:gap-x-[15px] items-center'>
        {iconsBlock.map((item, index) => {
          if (item.title === 'Email') {
            return (
                <div className="min-w-8 min-h-8">
                  <RouterLink key={index} target="_blank" to={`mailto:${item.url}`}>
                    <img className="w-8 h-8" src={item.icon} alt="svg" />
                  </RouterLink>
                </div>
            );
          }
          if (item.title === 'WhatsApp') {
            return (
                <div className="min-w-8 min-h-8">
                  <RouterLink key={index} target="_blank" to={`https://api.whatsapp.com/send?phone=${item.url}`}>
                    <img className="w-8 h-8" src={item.icon} alt="svg" />
                  </RouterLink>
                </div>
            );
          }
          if (item.title === 'Phone') {
            return (
                <div className="min-w-8 min-h-8">
                  <RouterLink key={index} target="_blank" to={`tel:${item.url}`}>
                    <img className="w-8 h-8" src={item.icon} alt="svg" />
                  </RouterLink>
                </div>
            );
          }
          return (
              <div className="min-w-8 min-h-8">
                <RouterLink key={index} target="_blank" to={item.url}>
                  <img className="w-8 h-8" src={item.icon} alt="svg" />
                </RouterLink>
              </div>
          );
        })}
        <div onClick={() => setIsOpenMobileNav(true)}
             className="flex justify-center items-center border rounded-[30px] h-[41px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] ml-3">
          <button className="min-w-36 m-px border rounded-[30px] h-[35px] bg-white">Я повар!</button>
        </div>
      </div>
      <div className="block lg:hidden">
        {showBurger ? (
          <div onClick={toggleMobileNav} className="close-icon open">
            <img className="h-full w-full" src={Close} alt="svg" />
          </div>
        ) : (
          <div onClick={toggleMobileNav} className="burger-icon open">
            <img className="h-full w-full" src={Burger} alt="svg" />
          </div>
        )}
      </div>
      <CustomModalNew setIsOpenMobileNav={setIsOpenMobileNav} isOpenMobileNav={isOpenMobileNav} title="Заполните заявку на нашем сайте или свяжитесь с нами по телефону для получения дополнительной информации.">
        <CookerForm setIsOpenMobileNav={setIsOpenMobileNav} />
      </CustomModalNew>
      <CustomDrawer setIsOpenMobileNav={setIsOpenMobileNav} setShowBurger={setShowBurger} showBurger={showBurger} />
    </div>
  );
};

export default Header;
