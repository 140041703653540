import {useEffect} from "react";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
      <p className="mt-14">Настоящая Политика конфиденциальности персональных данных (далее — «Политика конфиденциальности»)
        действует в отношении всей информации, которую Интернет-платформа сервиса гастрономического
        маркетплейса «DOM FOOD (ДОМ ФУД)», расположенная на доменных именах https://dom-food.com
        и https://domfood.kz, может получить о Пользователе в процессе использования Сайта, программных продуктов и сервисов платформы.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
      <p className="mt-6"> <strong>1.1. </strong>В настоящей Политике конфиденциальности используются следующие термины:</p>

      <p>
        <p><br/></p>  1.1.1. Администратор
        Платформы/Администратор
        —
        уполномоченные
        сотрудники, действующие от имени ТОО «DOM FOOD (ДОМ ФУД)», БИН
        240940031813, местонахождения: Республика Казахстан, город Алматы, Алмалинский
        район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008, которые осуществляют
        управление онлайн Платформой, организуют и (или) осуществляют обработку
        персональных данных. Администратор определяет цели и объем обработки персональных
        данных, а также действия, совершаемые с персональными данными.
        <p><br/></p>  1.1.2. «Персональные данные» — любая информация, относящаяся прямо или
        косвенно к Покупателю.
        <p><br/></p>  1.1.3. «Обработка персональных данных» — любое действие или совокупность
        действий, совершаемых с использованием средств автоматизации или без них, в отношении
        персональных данных, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение
        (обновление,
        изменение),
        извлечение,
        использование,
        передачу
        (распространение, предоставление, доступ), обезличивание, блокирование, удаление или
        уничтожение данных.
        <p><br/></p>  1.1.4. «Конфиденциальность персональных данных» — обязательное для
        соблюдения требование, возлагаемое на Администратора в части недопущения их
        распространения без согласия субъекта данных или наличия иного законного основания.
        <p><br/></p>  1.1.5 «Покупатель» — дееспособное физическое лицо, достигшее 18 лет,
        присоединившееся к Пользовательскому соглашению в собственном интересе либо
        юридическое лицо в лице своего представителя, выступающего от имени и в интересах
        представляемого им юридического лица, который оформляет заказы на Продукты питания
        и услуги через онлайн Платформу.
        <p><br/></p> 1.1.6. «Cookies» — небольшой фрагмент данных, отправляемый веб-сервером и
        сохраняемый на устройстве Покупателя. При каждом повторном обращении к онлайн
        Платформе веб-клиент или веб-браузер пересылают эти данные на сервер в составе HTTP-
        запроса.
        <p><br/></p>  1.1.7. «IP-адрес» — уникальный сетевой адрес устройства в компьютерной сети,
        построенной с использованием протокола IP, используемый для идентификации узла в
        сети.
        <p><br/></p> 1.1.8. «Оператор персональных данных» — юридическое или физическое лицо,
        которое самостоятельно или совместно с другими лицами организует и (или) осуществляет
        обработку персональных данных, а также определяет цели и средства обработки
        персональных данных. В данном случае Оператором выступает ТОО «DOM FOOD (ДОМ
        ФУД)».
        <p><br/></p>  1.1.9. «Третьи лица» — физические или юридические лица, государственные
        органы, агентства или иные организации, которым могут быть переданы персональные
        данные Покупателя на основании настоящей Политики или законодательства Республики
        Казахстан.
        <p><br/></p>  1.1.10. «Согласие на обработку персональных данных» — добровольное,
        конкретное, информированное и однозначное выражение воли Покупателя, посредством
        которого он дает согласие на обработку его персональных данных.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p>  2.1. Согласие с настоящей Политикой: Использование Покупателем онлайн
        Платформы означает его полное и безоговорочное согласие с настоящей Политикой, а
        также с условиями обработки его персональных данных.
        <p><br/></p> 2.2. Прекращение использования: В случае несогласия с условиями настоящей
        Политики, Покупатель обязан прекратить использование онлайн Платформы и удалить
        свою учетную запись (если она была создана).
        <p><br/></p> 2.3. Ограничение ответственности за сторонние ресурсы: Настоящая Политика
        распространяется исключительно на онлайн Платформу. Администратор не контролирует
        и не несет ответственности за содержимое, политику конфиденциальности и действия
        сторонних сайтов, на которые Покупатель может перейти по ссылкам, размещенным на
        онлайн Платформе.
        <p><br/></p>2.4. Проверка достоверности данных: Администратор не несёт ответственности за
        точность и актуальность персональных данных, предоставленных Покупателем. Вся
        ответственность за предоставление достоверной информации лежит на Покупателе.
        <p><br/></p>  2.5. Изменение настоящей Политики: Администратор оставляет за собой право
        вносить изменения в настоящую Политику в одностороннем порядке. Уведомление о
        внесении изменений публикуется на сайте. Продолжение использования онлайн
        Платформы после публикации изменений считается согласием Покупателя с новой
        редакцией настоящей Политики.
        <p><br/></p>  2.6. Язык и толкование: Если текст настоящей Политики доступен на нескольких
        языках, приоритет является версия на русском языке. Все вопросы, связанные с
        толкованием положений настоящей Политики, регулируются законодательством
        Республики Казахстан.
        <p><br/></p> 2.7. Возрастные ограничения: Онлайн Платформа предназначена для использования
        лицами, достигшими возраста 18 лет. Если Администратор обнаружит, что персональные
        данные были предоставлены несовершеннолетним лицом без согласия родителей или
        законных представителей, такие данные будут немедленно удалены.
        <p><br/></p> 2.8. Файлы Сookie и технологии отслеживания: Онлайн Платформа использует
        файлы Сookie и аналогичные технологии для улучшения пользовательского опыта и
        предоставления персонализированного контента. Покупатель может отключить
        использование файлов Сookie в настройках браузера, но это может повлиять на
        функциональность Онлайн Платформы.
        <p><br/></p>2.9. Передача данных при реорганизации Администратора: В случае
        реорганизации Администратора, персональные данные Покупателей могут быть переданы
        новому владельцу при условии соблюдения всех положений настоящей Политики.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. ПРЕДМЕТ ПОЛИТИКИ</h3>
        <p>
          <p><br/></p>3.1. Обязательства по защите данных: Настоящая Политика устанавливает
          обязательства Администратора по неразглашению и обеспечению защиты персональных
          данных, которые Покупатель предоставляет при регистрации на онлайн Платформе или при
          оформлении заказа на приобретение Продуктов питания.
          <p><br/></p>3.2. Персональные данные, подлежащие обработке: Персональные данные,
          разрешенные к обработке в рамках настоящей Политики, предоставляются Покупателем
          путем заполнения регистрационной формы или оформления заказа на онлайн Платформе и
          включают в себя:
          <p><br/></p>3.2.1. Личная информация: имя, фамилия, номер телефона, адрес доставки, адрес
          электронной почты.
          <p><br/></p>3.2.2. Платежная информация: данные банковских карт и транзакций, которые
          обрабатываются через защищенные платежные системы.
          <p><br/></p> 3.2.3. История заказов: данные о предыдущих заказах, предпочтениях, отзывах и
          рейтингах Покупателя.
          <p><br/></p> 3.2.4. Технические данные: информация об устройстве Покупателя, IP-адрес,
          файлы cookie и геолокационные данные (при наличии согласия Покупателя).
          <p><br/></p>3.2.5. Аналитические данные: информация о взаимодействии с онлайн
          Платформой, включая посещенные страницы, время на сайте, использование функций, для
          улучшения работы сервиса.
          <p><br/></p>3.2.6. Предпочтения в еде: информация о предпочтениях и аллергиях Покупателя
          для персонализированных рекомендаций.
          <p><br/></p> 3.2.7. Социальные сети: данные профиля (например, имя, фото), если Покупатель
          авторизуется через социальные сети и предоставляет на это согласие.
          <p><br/></p> 3.2.8. Маркетинговая информация: данные для отправки рекламных
          предложений, уведомлений и рассылок, основанных на поведении и предпочтениях
          Покупателя.
          <p><br/></p>3.3. Автоматически собираемая информация: Онлайн Платформа защищает
          данные, которые автоматически передаются при просмотре страниц и рекламных блоков,
          на которых установлен статистический скрипт (пиксель). Эти данные включают:
          <p><br/></p>  • IP-адрес;
          <p><br/></p> • информация из файлов cookie;
          <p><br/></p>  • данные о браузере или программе, используемой для доступа к сайту;
          <p><br/></p>  • время доступа;
          <p><br/></p>  • адрес страницы, где размещен рекламный блок;
          <p><br/></p>  • Реферер (адрес предыдущей страницы посещённого сайта).
          <p><br/></p>  3.3.1. Отключение Сookies: Отключение файлов cookie в настройках браузера
          может повлечь невозможность доступа к отдельным функциям и разделам онлайн
          Платформы, требующим авторизации.
          <p><br/></p> 3.3.2. Использование IP-адресов: Онлайн Платформа собирает и анализирует IP-
          адреса Покупателей для выявления и решения технических проблем, контроля легальности
          финансовых транзакций и предотвращения мошенничества.
          <p><br/></p> 3.4. Защита дополнительной информации: Любая иная персональная информация,
          не указанная выше (например, используемые браузеры, операционные системы, история
          покупок), подлежит надежному хранению и нераспространению, за исключением случаев,
          предусмотренных пунктами 5.2 и 5.3 настоящей Политики.
          <p><br/></p> 3.5. Обработка данных третьими лицами: В случае привлечения третьих лиц для
          выполнения услуг, персональные данные могут быть переданы таким лицам при условии
          соблюдения требований конфиденциальности и безопасности.
          <p><br/></p> 3.6. Хранение персональных данных: Онлайн Платформа хранит персональные
          данные Покупателя только в течение времени, необходимого для достижения целей
          обработки, указанных в данной Политике. По истечении этого срока данные "подлежат
          удалению или обезличиванию, если иное не предусмотрено законодательством.
          <p><br/></p> 3.7. Обезличивание персональных данных: Администратор вправе использовать
          персональные данные в обезличенном виде для проведения статистических и
          аналитических исследований. Обезличенные данные не позволяют идентифицировать
          Покупателя и не требуют дополнительного согласия.
          <p><br/></p>3.8. Действия в случае утечки данных: При утечке персональных данных
          Администратор обязуется:
          <p><br/></p> 3.8.1. Немедленно уведомить уполномоченные государственные органы в
          соответствии с законодательством;
          <p><br/></p> 3.8.2. Информировать пострадавших Покупателей о произошедшей утечке;
          <p><br/></p> 3.8.3. Принять все необходимые
          предотвращения повторных утечек.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОКУПАТЕЛЯ</h3>
        <p> <p><br/></p>4.1. Персональные данные Покупателя могут использоваться Администратором в
          следующих целях:
          <p><br/></p> 4.1.1. Идентификация Покупателя для оформления заказа и (или) заключения
          Пользовательского соглашения.
          <p><br/></p>4.1.2. Предоставление доступа к персонализированным ресурсам онлайн
          Платформы.
          <p><br/></p>4.1.3. Установление обратной связи с Покупателем, включая направление
          уведомлений и запросов, касающихся использования онлайн Платформы, оказания услуг, а
          также обработка заявок и обращений от Покупателя.
          <p><br/></p> 4.1.4. Определение местоположения Покупателя для доставки Продуктов
          питания в целях обеспечения безопасности и предотвращения мошенничества.
          <p><br/></p>Подтверждение достоверности и полноты персональных данных,
          предоставленных Покупателем.
          <p><br/></p>4.1.6. Создание учетной записи для совершения покупок и использования
          функций онлайн Платформы при наличии согласия Покупателя.
          <p><br/></p>4.1.7. Уведомление Покупателя о состоянии его заказа и предоставление
          актуальной информации о доставке.
          <p><br/></p>4.1.8. Предоставление
          клиентской
          и
          технической
          поддержки
          при
          возникновении проблем с использованием онлайн Платформы.
          <p><br/></p> 4.1.9. Информирование Покупателя о новых продуктах, специальных
          предложениях, акциях и новостях онлайн Платформы или Поваров при наличии согласия
          Покупателя.
          <p><br/></p> 4.1.10. Осуществление рекламной деятельности на основе согласия Покупателя,
          включая отправку персонализированных предложений.
          <p><br/></p> 4.1.11. Предоставление доступа к сервисам Поваров, позволяющим Покупателю
          получать Продукты питания, обновления и услуги.
          <p><br/></p> 4.1.12. Проведение аналитики и статистических исследований, направленных на
          улучшение работы онлайн Платформы и разработку новых сервисов.
          <p><br/></p>4.1.13. Соблюдение юридических обязательств, включая выполнение требований
          законодательства Республики Казахстан.
          <p><br/></p> 4.1.14. Контроль качества обслуживания и улучшение работы онлайн
          Платформы, включая анализ отзывов и предпочтений Покупателей.
          <p><br/></p> 4.1.15. Персонализация взаимодействия с Покупателем: Данные могут
          использоваться для формирования персонализированных рекомендаций, предложений и
          интерфейсов, а также для улучшения пользовательского опыта на основе предпочтений и
          истории взаимодействий.
          <p><br/></p>4.1.16. Отслеживание и предотвращение мошенничества: Платформа использует
          персональные и технические данные для мониторинга подозрительных действий и
          предотвращения мошеннических операций.
          <p><br/></p>4.1.17. Участие в маркетинговых и бонусных программах: Данные Покупателей
          могут использоваться для регистрации в акциях, конкурсах и других программах
          лояльности с согласия Покупателя.
          <p><br/></p>4.1.18. Улучшение алгоритмов доставки: геолокационные данные и информация
          о заказах могут использоваться для оптимизации маршрутов и сокращения времени
          доставки.
          <p><br/></p> 4.1.19. Соблюдение правовых требований по защите данных: обработка данных
          может осуществляться для выполнения требований законодательства о защите данных,
          включая управление запросами Покупателей о доступе или удалении данных.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <p>
          <p><br/></p> 5.1. Сроки обработки: Обработка персональных данных Покупателя осуществляется
          без ограничения срока до момента достижения целей обработки или до отзыва согласия
          Покупателя. Обработка данных производится любыми законными способами, включая
          автоматизированные и неавтоматизированные системы.
          <p><br/></p> 5.2. Передача данных государственным органам: Персональные данные
          Покупателя могут быть переданы уполномоченным органам государственной власти
          Республики Казахстан только на законных основаниях и в порядке, установленном
          законодательством Республики Казахстан.
          <p><br/></p> 5.3. Уведомление об утрате или разглашении данных: В случае утраты или
          разглашения персональных данных Администратор обязуется незамедлительно уведомить
          Покупателя и принять меры по минимизации последствий.
          <p><br/></p>  5.4. Меры по защите персональных данных: Администратор принимает все
          необходимые организационные и технические меры для защиты персональных данных от:
          <p><br/></p>  5.4.1. Неправомерного или случайного доступа;
          <p><br/></p>  5.4.2. Уничтожения, изменения или блокировки;
          <p><br/></p>  5.4.3. Копирования, распространения или иных неправомерных действий третьих
          лиц.
          <p><br/></p> 5.5. Совместные меры по предотвращению ущерба: Администратор и Покупатель
          обязуются сотрудничать и предпринимать все необходимые меры для предотвращения
          убытков и иных негативных последствий, вызванных утратой или разглашением
          персональных данных.
          <p><br/></p> 5.6. Отзыв согласия на обработку данных: Покупатель имеет право в любое время
          отозвать свое согласие на обработку персональных данных путем направления
          Администратору письменного уведомления. После получения уведомления обработка
          персональных данных будет прекращена, за исключением случаев, предусмотренных
          законодательством Республики Казахстан.
          <p><br/></p>  5.7. Информирование о правах Покупателя: Администратор обязуется
          информировать Покупателя о его правах в отношении персональных данных, включая:
          <p><br/></p> 5.7.1. Право на доступ к данным и их исправление;
          <p><br/></p> 5.7.2. Право на удаление данных («право быть забытым»);
          <p><br/></p> 5.7.3. Право на ограничение обработки данных;
          <p><br/></p> 5.7.4. Право
          на
          возражение
          против
          обработки
          данных,
          включая
          автоматизированное принятие решений и профилирование;
          <p><br/></p>  5.7.5. Право на передачу данных в машиночитаемом формате.
          <p><br/></p> 5.8. Порядок действий при инцидентах:
          <p><br/></p>5.8.1. В случае инцидента, связанного с утратой или компрометацией данных,
          Администратор немедленно запускает внутреннее расследование, уведомляет
          уполномоченные органы (при необходимости), информирует всех пострадавших
          Покупателей и предоставляет рекомендации по минимизации рисков.
          <p><br/></p> 5.9. Использование сторонних сервисов и обработчиков данных: Онлайн
          Платформа может привлекать сторонних обработчиков данных для выполнения отдельных
          функций (например, хранение данных в облаке). В таких случаях Администратор обязуется
          заключать с обработчиками соответствующие соглашения, обеспечивающие соблюдение
          стандартов безопасности и конфиденциальности.
          <p><br/></p>  5.10. Актуализация данных Покупателя: Покупатель обязуется своевременно
          обновлять предоставленные персональные данные в случае их изменения, чтобы
          обеспечить точность и актуальность информации.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБЯЗАТЕЛЬСТВА СТОРОНЫ</h3>
      <p>
        <p><br/></p>  6.1. Покупатель обязан:
        <p><br/></p>  6.1.1. Предоставить достоверные и актуальные данные, необходимые для
        регистрации и использования онлайн Платформы.
        <p><br/></p> 6.1.2. Своевременно обновлять и дополнять предоставленную информацию о
        персональных данных в случае их изменения, чтобы обеспечить точность и актуальность.
        <p><br/></p>  6.1.3. Использовать онлайн Платформу и предоставленные сервисы в соответствии
        с условиями настоящей Политики и действующим законодательством Республики
        Казахстан.
        <p><br/></p> 6.1.4. Обеспечивать конфиденциальность своих учетных данных (логина и пароля)
        и не передавать их третьим лицам. В случае утраты доступа или подозрения на
        несанкционированный доступ к аккаунту, Покупатель обязан незамедлительно уведомить
        Администратора через онлайн Платформу.
        <p><br/></p>  6.1.5. Покупатель обязуется предоставлять только свои персональные данные.
        Передача данных третьих лиц без их согласия не допускается, за исключением случаев,
        предусмотренных законодательством Республики Казахстан.
        <p><br/></p>  6.1.6. В случае нарушения условий настоящей Политики или злоупотребления
        функционалом онлайн Платформы, Покупатель обязан устранить последствия такого
        нарушения и возместить возможный ущерб.
        <p><br/></p> 6.2. Администратор обязан:
        <p><br/></p>   6.2.1. Использовать полученные персональные данные исключительно для целей,
        указанных в настоящей Политике.
        <p><br/></p> 6.2.2. Обеспечить сохранность конфиденциальной информации, не разглашать ее
        без предварительного письменного согласия Покупателя, а также не осуществлять
        продажу, обмен, публикацию или иное разглашение персональных данных, за исключением
        случаев, предусмотренных настоящей Политикой.
        <p><br/></p>  6.2.3. Принимать все необходимые организационные и технические меры
        предосторожности для защиты конфиденциальности персональных данных Покупателя, в
        соответствии с передовыми стандартами безопасности, используемыми в деловом обороте.
        <p><br/></p>   6.2.4. Блокировать персональные данные Покупателя по запросу самого
        Покупателя, его законного представителя или уполномоченного органа на время
        проведения проверки в случае выявления недостоверности данных или неправомерных
        действий.
        <p><br/></p>  6.2.5. Обеспечить возможность Покупателю реализовать свои права на доступ,
        исправление, ограничение обработки и удаление персональных данных в соответствии с
        законодательством Республики Казахстан.
        <p><br/></p>  6.2.6. Удалять или обезличивать персональные данные после достижения целей их
        обработки, если иное не предусмотрено законодательством Республики Казахстан.
        <p><br/></p>  6.2.7. Администратор обязуется информировать Покупателя о любых изменениях в
        Политике конфиденциальности и предоставлять ему возможность ознакомиться с
        обновленной версией до продолжения использования онлайн Платформы.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
        <p>
          <p><br/></p>  7.1. Ответственность Администратора: Администратор, не исполнивший свои
          обязательства, несёт ответственность в соответствии с законодательством Республики
          Казахстан при наличии вины. Ответственность не наступает в случаях, предусмотренных
          пунктами 5.2., 7.2. и 7.4. настоящей Политики.
          <p><br/></p>  7.2. Освобождение от ответственности Администратора: Администратор не несет
          ответственности за утрату или разглашение конфиденциальной информации, если:
          <p><br/></p> 7.2.1. Данная информация стала публичным достоянием до ее утраты или
          разглашения.
          <p><br/></p>   7.2.2. Информация была получена от третьей стороны до момента ее получения
          Администратором.
          <p><br/></p> 7.2.3. Информация была раскрыта с согласия Покупателя.
          <p><br/></p>  7.2.4. Утечка информации произошла в результате несанкционированного доступа
          третьих лиц (например, в результате хакерской атаки), при условии, что Администратор
          принял все необходимые меры для защиты данных.
          <p><br/></p>  7.2.5. Покупатель сам добровольно передал свои учетные данные (логин, пароль)
          третьим лицам или не обеспечил их конфиденциальность.
          <p><br/></p>  7.3. Ответственность Пользователя:
          <p><br/></p>  7.3.1. Покупатель несет ответственность за достоверность предоставленных
          данных и своевременное их обновление.
          <p><br/></p>  7.3.2. В случае неправомерных действий Покупателя, которые привели к
          нарушению настоящей Политики или законодательных норм, Администратор вправе
          приостановить или ограничить доступ Покупателя к сервисам онлайн Платформы, а также
          потребовать возмещения причиненного ущерба.
          <p><br/></p> 7.3.3. Покупатель несет ответственность за последствия передачи своих учетных
          данных третьим лицам и обязуется уведомить Администратора при подозрении на
          несанкционированный доступ.
          <p><br/></p>   7.4. Ограничение ответственности: В случае форс-мажорных обстоятельств, таких
          как природные катастрофы, сбои в работе сети Интернет, действия государственных
          органов или иные обстоятельства непреодолимой силы, Администратор не несет
          ответственности за неисполнение обязательств, предусмотренных настоящей Политикой.
          <p><br/></p>   7.5. Уведомление о нарушениях: В случае утраты, утечки или неправомерного
          использования данных Администратор обязуется:
          <p><br/></p>  7.5.1. Уведомить Покупателя в течение 72 часов с момента обнаружения
          инцидента;
          <p><br/></p>  7.5.2. Предоставить Покупателю подробную информацию о характере инцидента и
          принятых мерах по устранению его последствий;
          <p><br/></p>  7.5.3. Предложить Покупателю рекомендации по минимизации возможных рисков.
          <p><br/></p> 7.6. Взаимодействие с государственными органами: При возникновении
          необходимости сотрудничества с государственными органами в связи с нарушениями прав
          Покупателей или утечкой данных, Администратор вправе предоставлять соответствующим
          органам необходимую информацию в пределах, установленных законодательством, и
          информировать об этом Покупателя.
          <p><br/></p>  7.7. Компенсация убытков: Если Покупатель понес убытки в результате нарушения
          обязательств Администратором, компенсация может быть выплачена только в пределах
          доказанных прямых убытков. Упущенная выгода, моральный ущерб или косвенные убытки
          не подлежат возмещению.
          <p><br/></p>  7.8. Уведомление о передаче данных третьим лицам: если персональные данные
          передаются третьим лицам, Администратор обязуется уведомить об этом Покупателя, за
          исключением случаев, предусмотренных законодательством Республики Казахстан или
          настоящей Политикой.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. РАЗРЕШЕНИЕ СПОРОВ</h3>
        <p>
          <p><br/></p> 8.1. Досудебное урегулирование: До обращения в суд с иском по спорам,
          возникающим из отношений между Покупателем и Администратором, обязательным
          является предъявление претензии (письменного предложения о добровольном
          урегулировании спора).
          <p><br/></p>8.2. Сроки рассмотрения претензии: Получатель претензии обязан в течение 30
          календарных дней с момента получения письменно уведомить заявителя о результатах
          рассмотрения претензии и предложенных мерах по урегулированию.
          <p><br/></p> 8.3. Судебное разбирательство: Если стороны не пришли к соглашению в
          досудебном порядке, спор подлежит рассмотрению в судебном порядке в
          Специализированном межрайонном экономическом суде города Астаны (договорная
          подсудность).
          <p><br/></p> 8.4. Применимое право: К настоящей Политике и всем отношениям между
          Покупателем и Администратором применяется действующее законодательство
          Республики Казахстан.
          <p><br/></p>  8.5. Язык и толкование споров: если текст Политики конфиденциальности доступен
          на нескольких языках, приоритетной является версия на русском языке. В случае
          разногласий по толкованию положений настоящей Политики, стороны руководствуются
          версией, опубликованной на русском языке.
          <p><br/></p>  8.6. Форс-мажор: Администратор освобождается от ответственности за нарушение
          условий Политики конфиденциальности, вызванное обстоятельствами непреодолимой
          силы (форс-мажор), такими как:
          <p><br/></p>  8.6.1.природные катастрофы, пожары, наводнения;
          <p><br/></p>  8.6.2.военные действия или акты терроризма;
          <p><br/></p> 8.6.3.действия государственных органов или изменения в законодательстве;
          <p><br/></p>  8.6.4.сбои в работе сетей связи или инфраструктуры, находящиеся вне контроля Администрации
          сайта.
          <p><br/></p>  8.7. Сроки давности: Стороны соглашаются, что срок исковой давности для подачи
          претензий и рассмотрения споров, связанных с нарушением условий настоящей Политики
          конфиденциальности, составляет один год с момента обнаружения нарушения.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
      <p>
        <p><br/></p>  9.1. Право на внесение изменений: Администратор оставляет за собой право
        вносить изменения в настоящую Политику без предварительного согласия Покупателя.
        Покупатель обязан самостоятельно отслеживать актуальные изменения.
        <p><br/></p>  9.2. Вступление изменений в силу: Новая редакция настоящей Политики вступает в
        силу с момента ее размещения на онлайн Платформе, если иное не предусмотрено новой
        редакцией.
        <p><br/></p> 9.3. Уведомление об изменениях (при необходимости): В случае значительных
        изменений, касающихся обработки персональных данных, Администратор вправе
        уведомить Покупателя через электронную почту или другим доступным способом, чтобы
        обеспечить осведомленность Покупателя.
        <p><br/></p>  9.4. Обратная связь и вопросы: Все предложения, вопросы или жалобы, связанные
        с настоящей Политикой, следует направлять на электронную почту: support@dom-
        food.com.
        <p><br/></p>  9.5. Доступ к актуальной версии Политики: Актуальная версия Политики всегда
        доступна по следующим адресам: https://dom-food.com/ и https://domfood.kz/.
        <p><br/></p>  9.6. Архив версий Политики: В целях прозрачности Администратор вправе хранить
        архив предыдущих версий Политики конфиденциальности. Доступ к архиву может быть
        предоставлен Покупателю по запросу.
        <p><br/></p>  9.7. Применение в случае реорганизации компании: В случае реорганизации
        Администратора все права и обязательства в отношении персональных данных будут
        переданы новому владельцу на условиях, не менее строгих, чем предусмотренные
        настоящей Политикой.
        <p><br/></p> 9.8. Отдельные положения: Если какое-либо из положений настоящей Политики
        будет признано недействительным или утратит юридическую силу, это не повлияет на
        действительность остальных её положений.
        <p><br/></p> 9.9. Настоящая Политика является неотъемлемой частью Пользовательского
        соглашения, размещенного на сайтах DOM FOOD. При оформлении заказа Покупатель
        подтверждает свое согласие с условиями настоящей Политики.
        <p><br/></p> 9.10. В случае несогласия с условиями настоящей Политики Покупатель обязан
        прекратить использование онлайн Платформы и воздержаться от оформления заказов на
        онлайн Платформе.
      </p>
    </div>
  )
}

export default PrivacyPolicy;
