import {useEffect} from "react";

const PublicOfferCompany = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-xl md:text-3xl">ПУБЛИЧНАЯ ОФЕРТА ОКАЗАНИЯ ЭЛЕКТРОННЫХ УСЛУГ
        ТОО «DOM FOOD (ДОМ ФУД)»</h1>
      <p className="mt-4">
        Настоящее предложение Товарищества с ограниченной ответственностью «DOM
        FOOD (ДОМ ФУД)» (далее — Администратор), БИН 240940031813, является публичной
        офертой, в соответствии с которой Администратор предлагает заключить Публичный
        договор с использованием информационных технологий на условиях, изложенных в
        настоящем предложении, с любым лицом, принявшим оферту.
        Настоящая Публичная оферта содержит все существенные условия, признанные
        таковыми законодательством, а также условия, необходимые для оказания услуг по
        размещению Продуктов питания на онлайн Платформе DOM FOOD (ДОМ ФУД), её
        реализации и продвижению.
      </p>
      <h3 className="font-bold text-xl md:text-2xl mt-4 text-center">1. ПОНЯТИЯ И ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕЙ
        ПУБЛИЧНОЙ ОФЕРТЕ</h3>
    <p>
      <p><br/></p> 1.1. Услуги — размещение на онлайн Платформе DOM FOOD (ДОМ ФУД)
      Продуктов питания, их реализация и продвижение, то есть электронные услуги (включая
      информационные, интерактивные, транзакционные), предоставляемые на территории
      Республики Казахстан совершеннолетним дееспособным физическим и юридическим
      лицам с применением информационных технологий.
      <p><br/></p>1.2. Публичная оферта — настоящее предложение, которое Администратор делает
      любому лицу, согласному на присоединение к Публичной оферте, и которое содержит все
      существенные условия. Данное предложение включает все условия, признанные
      существенными законодательством или необходимыми для оказания Услуг, а также те
      условия, относительно которых по заявлению Администратора должно быть достигнуто
      соглашение.
      <p><br/></p> 1.3. Акцепт — ответ Повара или совершение Поваром действий, определенных
      Публичной офертой, свидетельствующих о полном и безоговорочном принятии условий
      Публичной оферты.
      <p><br/></p>1.4. Информационная технология — совокупность методов, производственных
      процессов и программно-технических средств, объединенных в технологический комплекс,
      обеспечивающий сбор, создание, хранение, накопление, обработку, поиск, вывод,
      копирование, передачу и распространение информации.
      <p><br/></p>1.5. Онлайн Платформа — интернет - ресурс https://dom-food.com/ и
      https://domfood.kz/ Администратора, представляющий собой информацию (в текстовом,
      графическом, аудиовизуальном или ином виде), размещённую на аппаратно-программном
      комплексе, имеющем уникальный сетевой адрес и (или) доменное имя и
      функционирующем в Интернете.
      <p><br/></p>  1.6. Покупатель — лицо, посетившее онлайн Платформу с целью ознакомления
      и/или покупки Продуктов питания, изготовленных Поваром, а также для цели регистрации
      и оформления Заказа.
      <p><br/></p> 1.7. Повар — ресторан, розничный магазин, повар или иного рода Повар
      предлагающий в качестве продавца свои Продукты питания (готовые блюда еды,
      безалкогольные напитки, сырые мясные продукты и полуфабрикаты из них),
      приготовленные Поварами, и принявший условия Публичной оферты Администратора
      посредством Акцепта.
      <p><br/></p> 1.8. Заказ — оформленная заявка Покупателя на покупку Продуктов питания,
      оформленная в соответствии с условиями Публичной оферты и размещённая на Платформе.
      <p><br/></p> 1.9. Продукты питания — продукция, изготовленная Поваром (готовые блюда еды,
      безалкогольные напитки, сырые мясные продукты и полуфабрикаты из них),
      приготовленные Поварами, и прошедшая предварительное согласование с
      Администратором на соответствие стандартам качества.
      <p><br/></p> 1.10. Стороны — Администратор и Повар, совместно являющиеся сторонами
      Публичной оферты.
      <p><br/></p> 1.11. Персональные данные — сведения, регламентированные Публичной офертой,
      относящиеся к определенному физическому лицу и зафиксированные на электронном,
      бумажном или ином материальном носителе.
      <p><br/></p> 1.12. Сбор Персональных данных — действия, связанные с получением
      Персональных данных для выполнения обязательств по Публичной оферты.
      <p><br/></p>  1.13. Обработка персональных данных — действия, направленные на накопление,
      хранение, изменение, дополнение, использование, распространение, обезличивание,
      блокирование и уничтожение персональных данных.
      <p><br/></p> 1.14. Третьи лица — лица, связанные с Администратором в настоящем или будущем
      по обстоятельствам или правоотношениям, касающимся Сбора, Обработки и защиты
      Персональных данных Повара.
      <p><br/></p>1.15. Правила отбора и оказания услуг Поваром — приложение к настоящей
      Публичной оферте, где изложены все правила оказания услуг, требования, права и
      обязанности Повара, данные правила являются неотъемлемой частью настоящей
      Публичной оферты (далее по тексту «Правила»).
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ПРЕДМЕТ ПУБЛИЧНОЙ ОФЕРТЫ</h3>
      <p>
        <p><br/></p> 2.1. Администратор предоставляет Повару доступ к онлайн Платформу для
        размещения, реализации и продвижения Продуктов питания за вознаграждение,
        предусмотренное настоящей Публичной офертой (далее – Услуги). Условия оказания Услуг
        определены в настоящей Публичной оферте и могут быть приняты Поваром исключительно
        путем присоединения к предложенной Публичной оферте в целом через принятие (Акцепт)
        предложения Администратора.
        <p><br/></p>2.2. Порядок совершения Акцепта:
        Предложение Администратора считается принятым Поваром с момента совершения
        им любого из следующих действий:
        <p><br/></p> 2.2.1. Совершение Поваром действий, указывающих на принятие условий настоящей
        Публичной оферты, в частности, проставление отметки (галочки) в соответствующем
        поле на онлайн Платформе;
        <p><br/></p> 2.2.2. Регистрация Повара на онлайн Платформе;
        <p><br/></p>2.2.3. Размещение Продуктов питания на онлайн Платформе.
        <p><br/></p> 2.3. Правовые последствия Акцепта:
        <p><br/></p>  С момента совершения Поваром Акцепта вышеуказанным образом, наступают
        следующие правовые последствия:
        <p><br/></p>   2.3.1. Повар ознакомлен, согласен и принял предложение Администратора с
        условиями настоящей Публичной оферты и Правил, являющихся неотъемлемой частью
        настоящей Публичной оферты.
        <p><br/></p>  2.3.2. Акцепт Повара получен.
        <p><br/></p>2.3.3. Настоящая Публичная оферта считается заключенной и имеет юридическую
        силу в электронном виде без необходимости двустороннего подписания.
        <p><br/></p> 2.4. Право Администратора на отзыв Публичной оферты: Администратор имеет
        право отозвать свое предложение о заключении настоящей Публичной оферты в любое
        время, что не является основанием для отказа от выполнения обязательств по уже принятым
        Заказам. Администратор обязуется разместить уведомление об отзыве настоящей
        Публичной оферты на онлайн Платформе с указанием точного времени отзыва не менее
        чем за 12 часов до фактического отзыва (приостановления) действия Публичной оферты.
        <p><br/></p> 2.5. Онлайн Платформа может быть временно недоступна, частично или полностью,
        и соответственно, оказание Услуг может быть временно прекращено, частично или
        полностью, в связи с проведением профилактических или иных работ, а также по любымдругим причинам технического характера. Администратор имеет право периодически
        проводить необходимые профилактические или иные работы, с предварительным
        уведомлением Повара или без такового, в зависимости от обстоятельств.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ</h3>
      <p>
        3.1. Правила пользования онлайн Платформой: Правила пользования онлайн
        Платформой отражены в Правилах, являющихся неотъемлемой частью настоящей
        Публичной оферты. Данные Правила обязательны для исполнения Поваром, и
        несоблюдение их может повлечь временное ограничение доступа к Услугам Платформы.
      </p>
      <p>3.2. Виды Услуг и их особенности:</p>
      <div id="table-scroll">

      <table className="service-table mt-4 overflow-y-auto">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги</th>
          <th>Особенности оказываемой Услуги, сведения об основных свойствах Услуги</th>
          <th>Срок оказания Услуги и порядок его исчисления</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Регистрация на Сайте</td>
          <td>Регистрация личного кабинета, создание и настройка профиля</td>
          <td>Мгновенно при принятии условий Публичной оферты</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Размещение Продуктов питания</td>
          <td>Размещение фотографий, описания, состава, количества и сроков готовности Продуктов питания</td>
          <td>Осуществляется в течение всего периода течение всего периода Платформе</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Продвижение Продуктов питания</td>
          <td>Маркетинговое продвижение Продуктов питания, включая участие в акциях и скидках</td>
          <td>Продолжительность — до прекращения сотрудничества</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Оформление Продуктов питания (упаковка, оплата, доставка)</td>
          <td>Оформление заказа на Продукты питания, упаковка и подготовка к доставке</td>
          <td>Осуществляется в установленные сроки,, указанные в карточке заказа</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Доставка Продуктов питания Покупателю</td>
          <td>Доставка осуществляется курьерами или самим Поваром (при необходимости)</td>
          <td>Срок зависит от выбранного способа доставки и места назначения</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Закрытие заказа</td>
          <td>Завершение заказа, подведение итогов и подтверждение выполнения</td>
          <td>Осуществляется после успешной доставки и оплаты</td>
        </tr>
        </tbody>
      </table>
      </div>
<p>
  <p><br/></p>  3.3. Оказание Услуг на постоянной основе: В период сотрудничества между
  Сторонами, Исполнитель оказывает Услуги на регулярной основе до момента прекращения
  сотрудничества между Сторонами.
  <p><br/></p> 3.4. Неограниченное количество размещений Продуктов питания и
  обслуживания Заказов: В рамках исполнения настоящей Публичной оферты Поваром
  может быть размещено неограниченное количество Продуктов питания и обслуживания
  Заказов для Покупателей. При этом исполнение определенного Заказа (оказание Услуги) не
  прекращает действие настоящей Публичной оферты.
  <p><br/></p> 3.5. Порядок принятия Заказа: Повар принимает Заказ путем выполнения действий,
  описанных в Правилах отбора и оказания услуг Повара, которые являются
  неотъемлемой частью настоящей Публичной оферты.
  <p><br/></p>  3.6. Привлечение соисполнителей:
  <p><br/></p>  3.6.1. Совершая Акцепт настоящей Публичной оферты, Повар выражает согласие на
  привлечение Администратором третьих лиц (соисполнителей) для исполнения
  обязательств, предусмотренных настоящей Публичной офертой. В частности, услуги по
  доставке Продуктов питания Покупателям могут осуществляться Курьерами либо самимПоваром.
  <p><br/></p> 3.6.2. В случае, если Администратор по какой-либо причине не сможет предоставить
  услугу доставки Курьерами, Повар обязуется самостоятельно организовать доставку
  Продуктов питания Покупателю в согласованные сроки, обеспечивая их качество и
  своевременность в соответствии с требованиями Администратора.
  <p><br/></p>  3.7. Технические сбои: Администратор не несёт ответственности за задержки или
  невозможность оказания Услуг, вызванные техническими сбоями на онлайн Платформе,
  перебоями в работе интернета или другими форс-мажорными обстоятельствами. При этом
  Повар обязуется самостоятельно следить за обновлениями онлайн Платформы
  необходимых для надлежащего функционирования своего аккаунта.
  <p><br/></p>   33.8. Контроль достоверности информации: Повар несёт ответственность за
  достоверность информации, размещённой на онлайн Платформе, включая описание
  Продуктов питания, её состав, сроки годности и другие характеристики. В случае
  размещения на онлайн Платформе недостоверной информации Администратор вправе
  приостановить доступ к онлайн Платформе до устранения нарушений. Потребитель вправе
  требовать с Повара возмещение убытков (ущерба, компенсации), вызванные в результате
  размещения на онлайн Платформе такой информации.
  <p><br/></p>  3.9. Гибкость комиссии: Повар имеет право обратиться в службу поддержки
  Администратора по вопросам, связанным с размещением Продуктов питания, обработкой
  Заказов и доставкой. Администратор обязуется обеспечить своевременное реагирование на
  запросы Повара.
  <p><br/></p>  3.10. Приоритетное продвижение: В случае самостоятельной доставки Поваром,
  общая комиссия за размещение и реализацию Продуктов питания на онлайн Платформе
  может быть пересмотрена Администратором. О таких изменениях Повар будет уведомлен
  заранее.
  <p><br/></p> 3.11. Поддержка и коммуникация: Администратор может предлагать Повару
  дополнительные платные услуги, включая, но не ограничиваясь, такие как приоритетное
  продвижение Продуктов питания или участие в специальных акциях. Повар вправе
  согласиться на такие условия через личный кабинет на онлайн Платформе.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ЦЕНА, СРОК И ПОРЯДОК ОПЛАТЫ УСЛУГ</h3>

      <div id="table-scroll">

      <table className="service-table mt-4 overflow-y-auto">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги</th>
          <th>Единица измерения</th>
          <th>Стоимость Услуги</th>
          <th>Комментарии по стоимости и особенностям</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Размещение  Продуктов  питания на онлайн Платформе</td>
          <td>количество размещённых позиций (блюд или напитков))</td>
          <td>Определяется  Администратором</td>
          <th>Цена на Продукты питания отображается для Покупателя как окончательная..</th>
        </tr>
        <tr>
          <td>2</td>
          <td>Продвижение и маркетинговая поддержка</td>
          <td>рекламная  кампания / акция</td>
          <td>Включено в стоимость</td>
          <th>Включает участие в акциях,маркетинговую поддержку и рекламное продвижение на  онлайн Платформе.</th>
        </tr>
        <tr>
          <td>3</td>
          <td>Доставка Продуктов питания</td>
          <td>заказ (единица доставки)</td>
          <td>Оплачивается отдельно</td>
          <th>Стоимость доставки варьируется в зависимости от расстояния и типа Продуктов питания.. Если курьеры недоступны, доставка может быть осуществлена осуществлена.</th>
        </tr>
        <tr>
          <td>4</td>
          <td>Регистрация на Платформе</td>
          <td>действие (создание  личного кабинета) </td>
          <td>Бесплатно</td>
          <th>Регистрация личного кабинета и создание профиля на онлайн Платформе.</th>
        </tr>
        <tr>
          <td>5</td>
          <td>Оформление заказа</td>
          <td>заказ</td>
          <td>Входит в общую стоимость Продуктов питания</td>
          <th>Включает упаковку, прием оплаты и координацию доставки.</th>
        </tr>
        <tr>
          <td>6</td>
          <td>Закрытие заказа</td>
          <td>действие (подтверждение завершения заказа)</td>
          <td>Бесплатно</td>
          <th>Оформление завершения заказа через сервис онлайн
            Платформы.</th>
        </tr>
        </tbody>
      </table>
      </div>
      <p>
        <p><br/></p>  4.1. Описание оказываемых Услуг:
        <p><br/></p>  4.1.1. Администратор оказывает Повару комплексные Услуги, включающие:
        • Регистрация на онлайн Платформе: Регистрация личного кабинета, настройка
        профиля, публикация информации о Продуктах питания.
        • Размещение Продуктов питания: Публикация фотографий, описание, состав,
        характеристики и сроки готовности.
        • Продвижение Продуктов питания: Маркетинговая поддержка, участие в
        акциях и скидках, рекламные кампании для увеличения продаж.
        • Контроль качества: Контроль качества размещённых Продуктов питания,
        упаковки, соответствия заявленным стандартам.
        • Оформление и закрытие заказов: Координация заказов, включая упаковку,
        прием оплаты и доставку Продуктов питания.
        <p><br/></p>  4.2. Формирование цены и комиссия Администратора:
        <p><br/></p>  4.2.1. Повар указывает цену на Продукты питания в карточке, видимой для
        Повара. Конечная стоимость, которую видит Покупатель, включает все дополнительные
        расходы и отображается как единая цена.
        <p><br/></p>  4.2.2. Администратор регулирует цену, добавляя свои расходы, связанные с
        продвижением, поддержанием онлайн Платформы и другими услугами. Размер
        корректировки не раскрывается Повару.
        <p><br/></p>  4.2.3. Повар получает выплату согласно указанной им цене на Продукты
        питания, независимо от размера комиссии Администратора. Это гарантирует прозрачность
        для Повара без раскрытия внутренних расчётов онлайн Платформы. Повар соглашается с
        возможными изменениями, принимая настоящую Публичную оферту.
        <p><br/></p>   4.2.4. Комиссия, взимаемая Администратором, покрывает широкий спектр услуг и
        расходов, связанных с предоставлением комплексной поддержки Повару, включая, но не
        ограничиваясь, следующими аспектам.
        4.2.4.1. Маркетинговые операции:
        • Организация и проведение рекламных кампаний для повышения видимости
        Продуктов питания Поваров на онлайн Платформе.
        • Продвижение Продуктов питания через участие в акциях и скидках, которые
        привлекают больше Покупателей.
        • Разработка и реализация стратегий увеличения продаж, включая
        таргетированную рекламу, сотрудничество с Поварами и привлечение новых Покупателей
        на онлайн Платформу.
        • И другие услуги по соглашению Сторон.
        4.2.4.2. Техническое обеспечение и поддержание онлайн Платформы включает в
        себя:
        • Обслуживание и поддержка работы онлайн Платформы, включая разработку,
        обновление и улучшение функционала для удобства и эффективности работы Поваров.
        • Обеспечение бесперебойной работы онлайн Платформы, защита данных и
        поддержка безопасности на всех этапах взаимодействия.
        • Внедрение новых технологий и инструментов, улучшающих Покупательский
        опыт, таких как улучшенная система уведомлений, функции для анализа продаж и обратной
        связи от Покупателей.
        4.2.4.3. Поддержка Поваров включает в себя:
        • Предоставление Поварам доступа к службе поддержки, которая помогает
        решать вопросы, связанные с размещением продукции, продвижением, оформлением
        заказов и другими аспектами работы на онлайн Платформе.
        • Юридическая, бухгалтерская и организационная поддержка в течение первого
        месяца сотрудничества, а также предоставление консультаций по использованию онлайн
        Платформы.
        4.2.4.4. Организация и управление процессами продаж включает в себя:
        • Координацию Заказов, включая помощь в оформлении и обработке Заказов,
        проверку соответствия размещенных Продуктов питания стандартам качества, а также
        координацию процессов упаковки и доставки.
        • Автоматизация процессов оформления и закрытия Заказов, что позволяет
        Поварам сосредоточиться на приготовлении и качестве Продуктов питания, оставляя
        административные задачи на онлайн Платформе.
        4.2.4.5. Контроль качества и безопасность:
        • Контроль качества Продуктов питания, обеспечивающий соответствие
        заявленным стандартам и требованиям онлайн Платформы.
        • Поддержание санитарных стандартов и обеспечение безопасности пищевых
        продуктов, включая аудит продукции и упаковки.
        4.2.4.6. Маркетинговый анализ и рекомендации:
        • Проведение маркетингового анализа и предоставление Поварам рекомендаций
        по улучшению ассортимента Продуктов питания, а также по повышению
        привлекательности и конкурентоспособности их предложений на онлайн Платформе.
        • Сбор и анализ обратной связи от Покупателей для улучшения позиций
        Продуктов питания Поваров на рынке.
        <p><br/></p>  4.3. Комиссия удерживается Администратором автоматически при каждой
        транзакции, и соответствующая сумма переводится на счет Повара после вычета
        комиссии.
        <p><br/></p> 4.4. Условия и порядок оплаты:
        <p><br/></p>  4.4.1. Оплата Заказа: Повар обязуется обеспечить оплату Услуг по
        соответствующему Заказу на условиях 100% предоплаты со стороны Покупателя. Сумма
        предоплаты поступает на счет Администрации.
        <p><br/></p>   4.4.2. Срок оплаты: Администратор обязуется перечислить полученные денежные
        средства Повару в течение 1 (одного) календарного дня с момента поступления оплаты от
        Покупателя. Повар соглашается с тем, что в случае форс-мажорных обстоятельств или
        технических неполадок возможны задержки в сроках перечисления средств.
        Администратор обязуется уведомлять Повара о возможных задержках и прилагать усилия
        для минимизации их длительности.
        <p><br/></p>  4.4.3. Выплата Повару: Повар получает выплату согласно заявленной им цене за
        вычетом внутренних расходов Администратора.
        <p><br/></p> 4.4.4. Ответственность за неисполнение обязательств: Неисполнение или
        ненадлежащее исполнение Покупателем своих обязательств по оплате приводит к тому, что
        Администратор освобождается от выполнения данного Заказа. В таком случае Заказсчитается неоформленным, не полученным и не принятым к исполнению.
        <p><br/></p>  4.5. Дополнительные расходы и комиссии:
        • Дополнительные расходы на оплату: Некоторые способы оплаты могут предполагать
        дополнительные комиссии или платежи, связанные с использованием банковских услуг или
        платежных систем. Все данные расходы несёт Пользователь. Пользователь обязан учитывать эти
        затраты при выборе способа оплаты.
        <p><br/></p> 4.5. Способы оплаты:
        <p><br/></p>  4.4.1. Оплата за Услуги осуществляется в безналичном порядке следующими
        способами:
        •
        Платежной картой (VISA, MasterCard).
        •
        Банковский перевод.
        •
        Электронные кошельки (если доступны на онлайн Платформе).
        •
        Другие способы оплаты, доступные на онлайн Платформе и указанные при
        оформлении Заказа.
        <p><br/></p> 4.6. Дополнительные расходы и комиссии:
        <p><br/></p>  Дополнительные расходы на оплату: Некоторые способы оплаты могут
        предполагать дополнительные комиссии или платежи, связанные с использованием
        банковских услуг или платежных систем. Все данные расходы несёт Повар. Повар обязан
        учитывать эти затраты при выборе способа оплаты.
        <p><br/></p>  4.7. Адаптация условий и изменение цен:
        <p><br/></p>  4.7.1. Администратор оставляет за собой право корректировать условия
        сотрудничества и цену в зависимости от объёма продаж, участия в акциях и других
        факторов. Эти изменения вступают в силу без отдельного уведомления Повара и
        автоматически отражаются в ценах на Платформе.
        <p><br/></p>  4.7.2. Продолжая пользоваться онлайн Платформой, Повар соглашается с такими
        изменениями.
        <p><br/></p> 4.8. Прозрачность и контроль расчётов:
        4.8.1. Повар имеет доступ к отчётам в личном кабинете, где отображаются все
        операции, связанные с его продукцией, включая суммы выплат за заказы.
        <p><br/></p>  4.8.2. Расчёты обновляются автоматически при изменении условий или запуске
        маркетинговых акций, что гарантирует своевременную выплату Повару.
        <p><br/></p> 4.9. Индивидуальные условия для отдельных категорий Поваров:
        <p><br/></p>  4.9.1. Индивидуальные условия: Администратор может предлагать специальные
        условия и ставки для отдельных категорий Поваров (например, Поваров с большим
        оборотом или участников специальных программ).
        <p><br/></p>  4.9.2. Дополнительные соглашения: При необходимости заключаются
        индивидуальные соглашения с Поварами, которые оформляются в письменном или
        электронном виде и являются неотъемлемой частью настоящей Публичной оферты.
        <p><br/></p>
        <p><br/></p>  4.10. Логистика и управление доставкой:
        <p><br/></p>  4.10.1. Гибкие условия доставки: Если доставка Курьерами временно недоступна,
        Повар обязан самостоятельно организовать доставку Продуктов питания до Покупателя.
        <p><br/></p>  4.10.2. Координация логистики: Администратор может предложить помощь в
        организации логистики при необходимости, включая подключение к внешним курьерским
        службам.
        <p><br/></p>  4.10.3. Расходы на доставку: Стоимость доставки указывается отдельно и видна
        Повару при оформлении заказа. Эти расходы не входят в цену Продуктов питания и
        регулируются отдельно.
        <p><br/></p>   4.11. Политика возвратов и претензий:
        <p><br/></p>  4.11.1. Возврат средств Покупателю: В случае отмены Заказа или претензий от
        Покупателя, Администратор может удержать часть суммы на покрытие расходов на
        маркетинг и операционные издержки.
        <p><br/></p>  4.11.2. Рассмотрение претензий: Претензии по Заказам обрабатываются через сервис
        онлайн Платформы. Администратор выступает посредником между Поваром и
        Покупателем для разрешения споров.
        <p><br/></p>  4.12. Изменение и приостановка предоставления Услуг:
        <p><br/></p>  4.12.1. Изменение структуры стоимости: Администратор оставляет за собой право
        изменять стоимость и структуру Услуг в зависимости от текущих условий рынка.
        <p><br/></p>  4.12.2. Временная приостановка Услуг: Администратор может временно
        приостановить предоставление Услуг на онлайн Платформе в случае технических проблем
        или форс-мажорных обстоятельств. Повара будут уведомлены о таких ситуациях через
        личный кабинет или электронную почту.
        <p><br/></p>  4.13. Политика налоговых обязательств:
        <p><br/></p>   4.13.1. Налоговые обязательства: Повара несут самостоятельную ответственность
        за уплату всех налогов и сборов, предусмотренных законодательством Республики
        Казахстан, связанных с их деятельностью на онлайн Платформе.
        <p><br/></p>  4.13.2. Возможность администрирования налогов Администратором: При
        необходимости Администратор может предложить услугу администрирования налогов за
        Повара на основании отдельного соглашения. Размер комиссии в таких случаях
        устанавливается индивидуально.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">5. ПРИЕМКА-ПЕРЕДАЧА ОКАЗАННЫХ УСЛУГ</h3>
      <p>
        <p><br/></p> 5.1. Общий порядок подтверждения услуг: Поскольку основная деятельность
        онлайн Платформы связана с продажей домашней продукции через Покупателей,
        традиционная практика оформления актов выполненных работ заменяется электронными
        подтверждениями и транзакционными отчетами в системе Платформы. Закрытие заказа в
        электронном виде является достаточным подтверждением факта оказания услуг и
        выполнения обязательств.
        <p><br/></p>5.2. Электронные подтверждения и отчёты:
        <p><br/></p> 5.2.1. Оформление заказов, их выполнение и передача фиксируются через сервис
        онлайн Платформы и подтверждаются закрытием заказа в системе.
        <p><br/></p> 5.2.2. Каждая операция будет отражена в виде транзакционного отчета в личном
        кабинете Повара, который может использоваться для внутренней отчетности и
        налогообложения.
        <p><br/></p> 5.3. Акт выполненных работ (по требованию):
        <p><br/></p> 5.3.1. Если Повар является юридическим лицом или Повара требуют дополнительные
        документы для бухгалтерской или налоговой отчётности, Администратор предоставляет
        Акт выполненных работ на основании запроса.
        <p><br/></p> 5.3.2. Такой Акт оформляется в двух экземплярах, подписывается Администратором
        и передаётся Повару для подписания в течение 10 (десяти) рабочих дней после закрытия
        заказа.
        <p><br/></p> 5.3.3. В случае отсутствия претензий или подписанного Акта в указанный срок, услуги
        будут считаться оказанными надлежащим образом и принятыми Поварами без возражений.
        <p><br/></p> 5.4. Обоснованный отказ и устранение нарушений:
        <p><br/></p> 5.4.1. В случае расхождений или выявления несоответствий между заказом и
        фактически оказанными услугами, Повар должен уведомить Администратора и
        предоставить обоснование в течение 5 (пяти) календарных дней.
        <p><br/></p> 5.4.2. Администратор, в случае согласия и наличия таких несоответствий, обязуется
        устранить установленные несоответствия в согласованный срок, но не позднее 10 (десяти)
        рабочих дней с момента получения уведомления. После этого процедура передачи услуг
        повторяется.
        <p><br/></p>5.5. Прозрачность и удобство:
        <p><br/></p>5.5.1. Все отчеты и транзакционные данные будут доступны в личном кабинете
        Повара.
        <p><br/></p> 5.5.2. Онлайн Платформа обеспечивает автоматизацию процессов подтверждения и
        закрытия
        заказов,
        что
        снижает
        необходимость
        использования
        бумажного
        документооборота и ускоряет обработку заявок.
        <p><br/></p> 5.6. Если Продукты питания доставлены не полностью, не соответствует заявленному
        наименованию, или упаковка не соответствует требованиям, Покупатель вправе отказаться
        от заказа с полным возвратом суммы или потребовать уменьшения цены заказа.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</h3>
      <p>
        <p><br/></p> 6.1. Освобождение от ответственности: Сторона освобождается от ответственности
        за частичное или полное неисполнение, или ненадлежащее исполнение обязательств, если
        это явилось следствием обстоятельств непреодолимой силы, возникших после заключения
        настоящей Публичной оферты в результате обстоятельств чрезвычайного характера,
        которые Сторона не могла предвидеть или предотвратить.
        <p><br/></p>6.2. Перечень обстоятельств непреодолимой силы: Под обстоятельствами
        непреодолимой силы понимаются следующие события, не зависящие от воли Сторон:
        <p><br/></p>6.2.1. Природные катастрофы: наводнение, пожар, землетрясение, ураган,
        стихийные явления и иные события природного характера.
        <p><br/></p>6.2.2. Социальные и политические события: эпидемии, пандемии, война, военные
        действия, массовые беспорядки, протесты.
        <p><br/></p> 6.2.3. Государственные акты и распоряжения: Введение карантина, локдаунов,
        изменение законодательства, запреты или ограничения, введённые государственными
        органами, делающие выполнение обязательств невозможным.
        <p><br/></p> 6.3. Обязанность уведомления другой Стороны: Сторона, пострадавшая от
        обстоятельств непреодолимой силы, обязана уведомить другую Сторону в течение пяти (5)
        рабочих дней с момента наступления таких обстоятельств. Уведомление должно содержать:
        <p><br/></p>6.3.1. Описание обстоятельств с указанием даты их наступления и степени влияния на
        исполнение обязательств.
        <p><br/></p>6.3.2. Оценку влияния на исполнение обязательств.
        <p><br/></p> 6.4. Подтверждение обстоятельств непреодолимой силы: Сторона, ссылающаяся
        на обстоятельства непреодолимой силы, должна представить другой Стороне официальные
        документы, удостоверяющие наличие этих обстоятельств, а также, по возможности, оценку
        их влияния на возможность исполнения Стороной своих обязательств предусмотренных
        настоящей Публичной офертой. Обстоятельства непреодолимой силы, имеющие
        общеизвестный характер, не требуют документального подтверждения. Тем не менее,
        Сторона обязана уведомить другую Сторону о наступлении таких обстоятельств в течение
        24 часов.
        <p><br/></p>6.5. Лишение права ссылаться на форс-мажор: Если Сторона, подвергшаяся
        воздействию обстоятельств непреодолимой силы, не направит в срок, предусмотренный
        настоящей Публичной офертой, документы, удостоверяющие наличие этих обстоятельств,
        то такая Сторона лишается права ссылаться на такие обстоятельства как на основание для
        освобождения от ответственности за неисполнение или ненадлежащее исполнение
        обязательств предусмотренных настоящей Публичной офертой.
        <p><br/></p>6.6. Изменение сроков выполнения обязательств: При наступлении обстоятельств
        непреодолимой силы срок выполнения обязательств предусмотренных настоящей
        Публичной офертой отодвигается на время, в течение которого действуют эти
        обстоятельства и их последствия. Если обстоятельства непреодолимой силы продолжают
        действовать более тридцати (30) календарных дней с момента направления уведомления,
        Стороны обязаны провести дополнительные переговоры для определения приемлемых
        альтернативных способов исполнения настоящей Публичной оферты. Если соглашение не
        будет достигнуто, обязательства Сторон прекращаются ввиду невозможности исполнения,
        за исключением денежных обязательств, которые остаются в силе.
        <p><br/></p>6.7. Продолжение работы при частичном форс-мажоре: Если обстоятельства
        непреодолимой силы влияют только на часть обязательств, остальные обязательства
        остаются в силе. В случае невозможности доставки, но при готовности Продуктов питания,
        Повар обязан организовать их самостоятельную передачу Покупателю.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. РАЗРЕШЕНИЕ СПОРОВ</h3>
      <p>
        <p><br/></p>7.1. Досудебное урегулирование споров:
        <p><br/></p>7.1.1. Досудебное урегулирование споров между Сторонами осуществляется вобязательном порядке путём проведения переговоров и предъявления претензий.
        <p><br/></p>7.1.2. Стороны обязуются предпринять все разумные меры для урегулирования
        спорных вопросов до обращения в судебные органы, чтобы минимизировать риски и
        затраты.
        <p><br/></p>7.2. Порядок досудебного урегулирования: Стороны договорились о следующем
        обязательном порядке досудебного урегулирования споров:
        <p><br/></p> 7.2.1. Форма и содержание претензии: Претензия должна быть представлена в
        письменной форме и подписана должным образом уполномоченным лицом Стороны,
        предъявляющей претензию.
        <p><br/></p> •
        Претензия рассматривается в течение десяти (10) календарных дней с даты её
        получения другой Стороной.
        <p><br/></p>  •
        По результатам рассмотрения претензии Сторона, получившая претензию,
        обязана уведомить Сторону, предъявившую претензию, в письменной форме, указав
        мотивы принятого решения и, при необходимости, предложения о порядке дальнейшего
        урегулирования спора.
        <p><br/></p> 7.3. Судебное разбирательство:
        <p><br/></p> 7.3.1. В случае, если стороны не пришли к соглашению в досудебном порядке, спор
        подлежит рассмотрению в судебном порядке в Специализированном межрайонном
        экономическом суде города Астаны (договорная подсудность).
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ШТРАФНЫЕ САНКЦИИ</h3>
<p>
  <p><br/></p> 8.1. Общие положения: Администратор вправе применять штрафные санкции к
  Повару в случае нарушения обязательств, установленных настоящей Публичной офертой.
  Штрафы применяются за нарушение сроков выполнения заказов, санитарных норм,
  условий конфиденциальности, ненадлежащее качество Продуктов питания, а также за отказ
  от выполнения доставки.
  <p><br/></p> 8.2. Штраф за нарушение сроков выполнения заказов:
  <p><br/></p>8.2.1. Первое нарушение: Повар получает письменное предупреждение с
  разъяснением допущенного нарушения и рекомендациями по устранению недостатков.
  <p><br/></p> 8.2.2. Повторное нарушение: В случае повторного нарушения Повар уплачивает
  штраф 5% от стоимости заказа за каждый 15 минут просрочки, но не более 60% от общей
  стоимости заказа.
  <p><br/></p>8.2.3. В случае систематического нарушения сроков (3 или более случаев в течение
  одного месяца) Администратор вправе временно ограничить доступ Повара к онлайн
  Платформе до устранения нарушений.
  <p><br/></p>8.2.4. Систематические нарушения: В случае, если нарушения сроков происходят
  более пяти раз в течение одного месяца, Администратор вправе временно ограничить
  доступ Повара к онлайн Платформе до устранения всех нарушений и согласования
  дальнейших действий для предотвращения подобных ситуаций в будущем.
  <p><br/></p>8.3. Штраф за ненадлежащее качество Продуктов питания:
  <p><br/></p>8.3.1. В случае поступления жалоб на ненадлежащее качество Продуктов питания
  (включая упаковку или состояние блюд при доставке), Администратор вправе наложить на
  Повара штраф в размере 10% от общей стоимости такого Заказа.
  <p><br/></p>8.3.2. При повторных нарушениях (более 3 раз в течение одного календарного месяца)
  Администратор оставляет за собой право увеличить размер штрафа до 15% от общей
  стоимости Заказа и временно приостановить доступ Повара к онлайн Платформе до
  устранения всех нарушений.
  <p><br/></p>8.3.3. В случае пищевого отравления Покупателей, в результате действия или
  бездействия Повара, то Повар обязан возместить Покупателю связанные с этим понесенные
  убытки, ущерб, компенсировать вред причиненный жизни и здоровью, компенсировать
  моральный вред.
  <p><br/></p> 8.4. Штраф за нарушение санитарных норм: В случае выявления нарушенийсанитарных правил, гигиенических нормативов, и использования испорченных продуктов,
  Повар обязан уплатить Администратору штраф в размере 150 000 (сто пятьдесят тысяч)
  тенге за каждый выявленный случай нарушения. При повторных нарушениях
  Администратор имеет право полностью прекратить сотрудничество с Поваром без права
  восстановления. При этом, в случае выявления таких нарушений, в результате которых был
  причинен вред жизни и здоровью Покупателю, то Повар обязан возместить Покупателю
  связанные с этим понесенные убытки, ущерб, компенсировать вред причиненный жизни и
  здоровью, компенсировать моральный вред.
  <p><br/></p>8.5. Штраф за нарушение условий конфиденциальности: В случае разглашения
  конфиденциальной информации, Повар обязан уплатить штраф в размере 500 000 (пятьсот
  тысяч) тенге за каждый случай нарушения. Повар также несет ответственность за убытки,
  причиненные Администратором, которые могут быть взысканы дополнительно к штрафу.
  <p><br/></p>8.6. Штраф за отказ от выполнения обязательств по доставке:
  <p><br/></p> 8.6.1. Если Повар обязан осуществить доставку самостоятельно по причине
  отсутствия доступных Курьеров, но отказывается это сделать, на него накладывается штраф
  в размере 15% от общей стоимости такого заказа.
  <p><br/></p>8.6.2. В таких случаях заказ аннулируется, а Повар обязан компенсировать все
  понесённые Администратором убытки.
  <p><br/></p>8.7. Способы взыскания штрафов: Все штрафы удерживаются Администратором из
  сумм, подлежащих выплате Повару по завершённым Заказам. В случае, если сумма
  штрафов превышает общую стоимость завершенных заказов, Повар обязан погасить
  штрафные санкции в течение 5 (пяти) календарных дней с момента получения
  соответствующего уведомления от Администратора.
  <p><br/></p> 8.8. Уведомление о наложении штрафов: Повар уведомляется о наложении
  штрафов в письменной форме, включая электронное уведомление. Уведомление должно
  содержать основание для наложения штрафа, его размер и сроки, в течение которых
  штраф должен быть погашен.
  <p><br/></p> 8.9. Администратор не несет ответственности в случае неисполнения и/или
  ненадлежащего исполнения Поваром обязательств по продаже Покупателю
  Продуктов питания надлежащего качества, в том числе в связи с предоставлением
  последним недостоверных и/или недействительных данных о себе, а равно
  невыполнение им условий настоящей Публичной оферты, при этом за такие
  нарушения прямая ответственность лежит непосредственно на Поваре.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p> 9.1. Изменение местонахождения Администратора: В случае изменения
        местонахождения Администратора, последний обязуется уведомить Повара не позднее, чем
        за 10 (десять) рабочих дней до изменения. Уведомление может быть отправлено в
        письменной форме или по электронной почте. Данные изменения также будут отображены
        на сервисе онлайн Платформы.
        <p><br/></p> 9.2. Передача прав и обязанностей:
        <p><br/></p> 9.2.1. Повар не имеет права передавать свои права и обязательства по настоящей
        Публичной оферте третьим лицам без предварительного письменного согласия
        Администратора.
        <p><br/></p> 9.2.2. Администратор имеет право передавать свои права и обязательства по
        настоящей Публичной оферте, уведомив Повара об этом не позднее, чем за 10 (десять)
        рабочих дней до момента передачи.
        <p><br/></p> 9.3. Автономность положений настоящей Публичной оферты: В случае, если одно
        или несколько положений настоящей Публичной оферты будут признаны
        недействительными или не подлежащими исполнению, это не влияет на действительность
        и исполнение остальных положений настоящей Публичной оферты. Недействительное
        положение заменяется на законное и исполнимое, максимально соответствующее
        первоначальному намерению Сторон.
      </p>

    </div>)
}

export default PublicOfferCompany;

