import {useEffect} from "react";

const RulesChef = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПРАВИЛА
        ОТБОРА И ОКАЗАНИЯ УСЛУГ ПОВАРА</h1>
      <p className="mt-4">
        Настоящие Правила отбора и оказания услуг Повара (далее — Правила) являются
        неотъемлемой частью Публичной оферты.
        Принятие условий настоящих Правил осуществляется Поваром путем присоединения
        к условиям Публичной оферты и проставления галочки в соответствующем поле на онлайн
        Платформе либо на сайтах https://dom-food.com/ или https://domfood.kz/.
        Такое действие считается полным и безоговорочным принятием условий настоящих
        Правил и обязательным условием для исполнения Поваром принятых на себя обязательств.
        Настоящие Правила вступают в силу с момента акцепта Публичной оферты Повара.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОТБОР ПОВАРА</h3>
        <p>
          <p><br/></p> 1.1. Кто может участвовать: Поваром может быть физическое лицо (самозанятый),
          индивидуальный предприниматель или юридическое лицо, обладающее соответствующей
          квалификацией и готово реализовывать Продукты питания на онлайн Платформе. Повар
          обязуется выполнять все требования законодательства Республики Казахстан в области
          приготовления и продажи Продуктов питания.
          <p><br/></p>1.2. Требования к документам: Повар обязан предоставить основные документы,
          подтверждающие квалификацию (сертификаты о санитарной безопасности, дипломы и т.
          д.) в течение 5 рабочих дней со дня акцепта Публичной оферты. Дополнительные
          документы и корректировки могут быть внесены в течение 10 рабочих дней с момента
          регистрации на онлайн Платформе. Повар несет ответственность за достоверность и
          актуальность представленных документов.
          <p><br/></p>1.3. Тестирование: Повар может приступить к размещению своих Продуктов питания
          на онлайн Платформе сразу после регистрации и получения доступа к личному кабинету.
          Однако окончательное одобрение его профиля и Продуктов питания проводится после
          тестирования, которое включает проверку Администратором представленных документов
          и оценку качества предложенных Продуктов питания. Если Продукты питания не
          соответствуют стандартам качества, Повар будет уведомлен Администратором о
          необходимых доработках. Администратор имеет право отказать в допуске блюда к
          продажам, если нарушения не были устранены.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. АССОРТИМЕНТ ПРОДУКТОВ ПИТАНИЯ, РАЗМЕЩАЕМЫХ
        НА ПЛАТФОРМЕ</h3>
        <p>
          <p><br/></p> 2.1. Виды Продуктов питания: разнообразные блюда, включая халяль,
          национальные кухни, сырые мясные продукты и полуфабрикаты из них, авторские рецепты,
          веганские блюда, кондитерские изделия, а также напитки (соки, смузи, чаи, кофе и другие
          безалкогольные напитки), по выбору Повара. Каждый Повар может выбирать ассортимент
          в зависимости от своих предпочтений и специализации, но обязан соблюдать все
          установленные законодательством Республики Казахстан стандарты качества и
          безопасности Продуктов питания.
          <p><br/></p>2.2. Описание продукции: Повар обязан предоставить подробное описание каждого
          Продукта питания, включающее:
          <p><br/></p>2.2.1. Состав (ингредиенты): Полный перечень ингредиентов, используемых при
          приготовлении Продуктов питания.
          <p><br/></p> 2.2.2. Наличие аллергенов: Указание на возможное присутствие аллергенов
          (например, орехи, молочные продукты, глютен и т. д.).
          <p><br/></p> 2.2.3. Порции и примерное время приготовления: Информация о размере порции и
          ориентировочном времени приготовления.
          <p><br/></p> 2.2.4. Вид подачи напитков: Для напитков указываются особенности подачи,
          например, «горячий», «холодный», «с льдом» и другие детали.
          <p><br/></p>  2.2.5. Фотография: Каждый Продукт питания должен сопровождаться качественной
          фотографией, которая будет отображаться на онлайн Платформе. Повар должен стремиться
          к тому, чтобы товарный вид Продуктов питания при передаче Покупателю максимально
          соответствовал изображению, однако допускаются небольшие изменения, вызванные
          процессом транспортировки.
          <p><br/></p>  2.3. Требования к качеству ингредиентов: Повар обязуется использовать только
          свежие и качественные ингредиенты для приготовления Продуктов питания.
          Использование просроченных, испорченных или некачественных ингредиентов
          категорически недопустимо.
          <p><br/></p> 2.4. Отзывы Покупателей: Повар обязан следить за отзывами Покупателей,
          оставленными на онлайн Платформе. В случае регулярных жалоб на качество Продуктов
          питания Администратор имеет право инициировать проверку качества, связаться с Поваром
          для получения разъяснений и при необходимости внести изменения в карточку Повара,
          включая временное приостановление продаж до устранения выявленных недостатков.
          <p><br/></p> 2.5. Гибкость в описании: Описание блюд может быть информативным и
          креативным, но не должно вводить в заблуждение. Повар может добавлять интересные
          факты о блюде, его истории или предложить нестандартные варианты подачи, что поможет
          привлечь внимание Покупателей.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. ПРОЦЕДУРА ТЕСТИРОВАНИЯ ПРОДУКТОВ ПИТАНИЯ</h3>
        <p>
          <p><br/></p> 3.1. Тестирование Продуктов питания: Перед размещением на онлайн Платформе
          каждый Продукт питания должен быть протестирован. Повар обязан предоставить
          Администратору образцы для тестирования. Администратор проводит проверку на
          соответствие вкуса, товарного вида, ингредиентов и заявленным стандартам качества. Это
          тестирование также включает оценку уровня подготовки блюда в сравнении с
          представленным описанием и фотографией.
          <p><br/></p>  3.2. Периодическое тестирование и контрольные закупки: Для обеспечения
          стабильного качества Продуктов питания Платформа может проводить повторное
          тестирование Продуктов питания. Это касается как новых предложений Повара, так и ранее
          добавленных Продуктов питания, чтобы гарантировать, что качество остается на высоком
          уровне. Администратор оставляет за собой право проводить контрольные закупки, чтобы
          убедиться в соблюдении стандартов качества на постоянной основе. В случае регулярных
          несоответствий повар может быть заблокирован или отстранён от платформы.
          <p><br/></p> 3.3. Обратная связь и исправление недостатков: По результатам тестирования
          Администратор предоставляет Повару рекомендации и комментарии. Повар обязан
          учитывать обратную связь и вносить необходимые изменения перед размещением
          Продуктов питания на онлайн Платформе. В случае, если Продукт питания не прошел
          тестирование, Повар может внести корректировки и предоставить Продукт питания на
          повторную проверку. Окончательное решение о допуске Продуктов питания к продаже
          принимается на основании результатов повторного тестирования.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ПРОЦЕСС ПРИЕМА ЗАКАЗОВ</h3>
      <p>
        <p><br/></p>4.1. Регистрация на онлайн Платформе:
        <p><br/></p> •
        Повар регистрируется на платформе, создаёт профиль, заполняет карточку с
        блюдами, загружает фотографии, описания, цены и предоставляет все обязательные
        документы в течение 5 рабочих дней после акцепта оферты.
        <p><br/></p> •
        Дополнительные документы должны быть загружены в течение 10 рабочих дней.
        <p><br/></p> •
        Профили поваров могут быть опубликованы, но с отметкой о необходимости
        завершения проверки.
        <p><br/></p>4.2. Прием заказов: Заказы на Продукты питания размещаются через онлайн
        Платформу. Повар обязан оперативно реагировать на поступающие заказы, подтверждая их
        в течение указанного времени (например, 5–10 минут) с момента получения уведомления.В случае задержки Повар должен немедленно уведомить Администратора через сервис
        онлайн Платформы.
        <p><br/></p>4.3. Уведомления: Повар получает автоматические уведомления о новых заказах
        через сервис онлайн Платформы. Повар обязан следить за исправностью системы
        уведомлений, обновлять приложение и поддерживать стабильное интернет-соединение,
        чтобы избежать сбоев при приёме заказов.
        <p><br/></p>4.4. Обработка заказов: Повар обязуется подготовить заказ в срок, указанный в
        сервисе онлайн Платформы. Время приготовления Продуктов питания должно
        соответствовать заявленному в карточке Продукта питания. Любое изменение времени
        приготовления требует согласования с Администратором.
        <p><br/></p> 4.5. Чрезвычайные ситуации: В случае невозможности выполнения заказа
        (например, из-за отсутствия ингредиентов или технических проблем), Повар обязан
        незамедлительно уведомить Администратора через сервис онлайн Платформы.
        Администратор в свою очередь уведомляет Покупателя о задержке или отмене заказа.
        <p><br/></p>4.6. Готовность заказа: После того как Заказ готов, Повар обязан отметить это в
        системе, кликнув «Заказ готов». Далее заказ должен быть передан Курьеру для доставки
        или, если доставка осуществляется Поваром, отправлен до покупателя в кратчайшие сроки.
        <p><br/></p> 4.7. Упаковка: Повар обязан использовать собственные упаковочные материалы до
        тех пор, пока Администратор не предоставит фирменные упаковки. Расходы на
        упаковочные материалы несет Повар. Все Продукты питания, особенно жидкие блюда,
        должны быть упакованы таким образом, чтобы предотвратить порчу или разлив. Упаковка
        должна быть герметичной и надежной, чтобы избежать повреждений во время
        транспортировки.
        <p><br/></p> 4.8. Фотографирование заказа: Перед отправкой Повар обязан сфотографировать
        готовый заказ и отправить фото Администратору через сервис онлайн Платформы. Это
        необходимо для контроля качества упаковки и разрешения возможных споров о состоянии
        заказа при получении Покупателем.
        <p><br/></p>  4.9. Коммуникация с Покупателем: Повар не имеет права общаться с Покупателями
        напрямую. Вся коммуникация Повара с Покупателем должна осуществляться через сервис
        онлайн Платформы. Повар имеет право взаимодействовать исключительно с
        Администратором, чтобы обеспечить единый стандарт обслуживания и избегать
        недоразумений.
        <p><br/></p> 4.10. Оплата: Повар принимает оплату исключительно через сервис онлайн
        Платформы. Любые другие формы расчета вне системы категорически запрещены.
        Администратор осуществляет выплаты Повару на основании выполненных Заказов.
        <p><br/></p>4.11. Изменение информации: Повар обязан своевременно обновлять информацию
        о Продуктах питания, а также актуализировать данные о наличии Продуктов питания, если
        какой-либо продукт временно недоступен. Актуальность информации — ключевой аспект
        для поддержания качества обслуживания Покупателей.
        <p><br/></p>4.12. Скидки и акции: Повар может участвовать в акциях и скидках, организованных
        Администратором. Участие в акциях требует своевременного обновления информации на
        странице Повара, а также соблюдения всех условий, установленных Администратором.
        <p><br/></p>4.13. Поддержка: Повар может обратиться в службу поддержки онлайн Платформы
        для решения любых вопросов, связанных с заказами, техническими неполадками или
        другими операционными моментами. Администратор предоставляет поддержку,
        касающуюся технических вопросов, на постоянной основе бесплатно. Юридическая,
        бухгалтерская и другая поддержка предоставляется в течение первого месяца
        сотрудничества бесплатно, после чего может быть оказана на платной основе.
        <p><br/></p> 4.14. Контроль за выполнением: Администратор имеет право проверять
        своевременность и качество выполнения заказов. Повар обязан соблюдать политики,
        правила, соглашения, являющиеся неотъемлемой частью Публичной оферты, обеспечивая
        высокий уровень качества и безопасности Продуктов питания.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. ОСУЩЕСТВЛЕНИЕ ДОСТАВКИ ЗАКАЗОВ</h3>
      <p>
        <p><br/></p> 5.1. Ответственность за доставку:
        <p><br/></p>  •
        Доставка Продуктов питания может осуществляться через курьерские службы
        либо силами Повара.
        <p><br/></p>  •
        Повар несет полную ответственность за своевременную передачу заказа Курьеру
        или непосредственно Покупателю.
        <p><br/></p>•
        Если доставка осуществляется Курьером, Повар должен обеспечить готовность
        заказа в сроки, указанные в сервисе онлайн Платформы.
        <p><br/></p> 5.2. Передача заказа Курьеру:
        <p><br/></p> •
        После того как заказ готов, Повар обязан отметить это в системе, нажав «Заказ
        готов».
        <p><br/></p> •
        После подтверждения готовности заказ должен быть передан Курьеру в
        кратчайшие сроки без необоснованных задержек.
        <p><br/></p>•
        Если Курьер уже ожидает заказ, Повар обязан передать его немедленно, чтобы
        избежать задержек в доставке.
        <p><br/></p> 5.3. Собственная доставка: Если Повар самостоятельно осуществляет доставку, он
        полностью отвечает за своевременность и качество доставки. Повар обязан корректно
        взаимодействовать с Покупателем и при необходимости с курьерской службой, если она
        привлекается. Повар должен соблюдать все установленные стандарты и правила
        Администратора, касающиеся доставки.
        <p><br/></p> 5.4. Обратная связь по доставке: В случае возникновения проблем с доставкой
        (например, задержка, проблемы с курьером или отсутствие Покупателя по адресу), Повар
        обязан незамедлительно уведомить Администратора через сервис онлайн Платформы,
        чтобы Покупатели могли быть оперативно проинформированы о статусе своего заказа.
        Администратор координирует все действия, направленные на минимизацию задержек и
        неудобств для Покупателей.
        <p><br/></p> 5.5. Контроль за сохранностью заказа: Повар несет полную ответственность за
        сохранность Заказа до момента его передачи Курьеру или Покупателю. Любые проблемы,
        возникшие из-за неправильной упаковки Продуктов питания, несоответствующих условий
        хранения, а также повреждения Заказа до момента передачи курьеру или Покупателю,
        являются ответственностью Повара. Администратор не несет ответственности за утрату
        или порчу Заказов, произошедшие по причине нарушения Поваром правил упаковки и
        передачи. В случае возникновения споров Администратор оставляет за собой право
        выступать в роли посредника, однако Повар может понести ответственность, включая
        временное приостановление доступа к онлайн Платформе или штрафные санкции, в случае
        систематических нарушений.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. КОНТРОЛЬ КАЧЕСТВА И ПОДДЕРЖКА АДМИНИСТРАТОРА</h3>
      <p>
        <p><br/></p>6.1. Аудит и контроль:
        <p><br/></p> 6.1.1. Администратор оставляет за собой право проводить аудит деятельности Повара
        в случае возникновения подозрений на нарушение условий Публичной оферты, таких как
        использование сторонних каналов для оплаты, несоблюдение стандартов качества или
        нарушение санитарных норм.
        <p><br/></p> 6.1.2. Аудиторская проверка может включать контроль качества Продуктов питания,
        соблюдение стандартов санитарии и сроков выполнения заказов, а также соблюдение иных
        обязательств, установленных Публичной офертой. Цель аудита — обеспечить соответствие
        предоставляемых услуг установленным стандартам и защитить интересы Покупателей.
        <p><br/></p> 6.2. Разрешение споров:
        <p><br/></p> 6.2.1. В случае споров, связанных с качеством Продуктов питания или доставкой,
        Администратор проводит анализ ситуации, опираясь на предоставленные фотографии и
        данные о Заказе.6.2.2. Если упаковка оказалась недостаточно надежной и это стало причиной порчи
        заказа, ответственность за это несет Повар. Администратор выступает посредником в таких
        спорах, чтобы защитить интересы Покупателей и обеспечить справедливое решение.
        <p><br/></p> 6.2.3. Платформа не несёт ответственности за качество приготовленных блюд или
        ошибок повара.
        <p><br/></p> 6.3. Меры за несоблюдение стандартов:
        <p><br/></p> 6.3.1. В случае систематического нарушения стандартов качества (например,
        регулярные жалобы Покупателей на плохую упаковку или порчу Продуктов питания),
        Администратор оставляет за собой право принять меры в отношении Повара. Эти меры
        могут включать временное приостановление его доступа к онлайн Платформе, наложение
        штрафных санкций, а также прекращение сотрудничества.
        <p><br/></p> 6.4. Поддержка Повара:
        <p><br/></p> 6.4.1. В первый месяц сотрудничества Администратор оказывает Поварам
        бесплатную поддержку по налоговым, бухгалтерским и юридическим вопросам. Это
        позволяет Поварам сосредоточиться на своей основной деятельности и быстро
        адаптироваться к работе на онлайн Платформе. Для получения поддержки Повар может
        обратиться через сервис онлайн Платформы.
        <p><br/></p>6.4.2. После первого месяца вся дополнительная поддержка оказывается только на
        платной основе.
        <p><br/></p> 6.5. Дополнительное обучение:
        <p><br/></p> 6.5.1. Администратор может предоставлять рекомендации по улучшению качества
        работы повара, но они носят исключительно рекомендательный характер.
        <p><br/></p> 6.5.2. Обучающие мероприятия и тренинги могут проводиться на платной основе.
        <p><br/></p> 6.6. Ограничение ответственности Администратора:
        <p><br/></p> 6.6.1. Администратор не несёт ответственности за:
        <p><br/></p> •
        Некачественные продукты или нарушения санитарных норм повара.
        <p><br/></p> •
        Жалобы клиентов, если они вызваны действиями или бездействием повара.
        <p><br/></p> •
        Финансовые потери повара, вызванные блокировкой аккаунта или санкциями.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. СТОИМОСТЬ ПРОДУКТОВ ПИТАНИЯ</h3>
      <p>
        <p><br/></p> 7.1. Установка цены: Повар самостоятельно устанавливает цену на свои Продукты
        питания, учитывая затраты на ингредиенты, время на приготовление, упаковку и другие
        связанные с этим расходы. При этом Повар понимает и соглашается, что итоговая цена,
        отображаемая Покупателю, может отличаться от установленной им цены.
        <p><br/></p>  7.2. Добавление комиссии: Администратор добавляет к установленной Поваром
        цене свою комиссию. Размер комиссии варьируется в зависимости от условий
        сотрудничества и указан в условиях Публичной оферты, с которыми Повар соглашается
        при регистрации. Окончательная цена, отображаемая на онлайн Платформе для
        Покупателя, уже включает как цену Продукта питания, так и комиссию Администратора,
        при этом эти компоненты не выделяются отдельно. Покупатель видит только итоговую
        стоимость Продуктов питания без указания комиссии.
        <p><br/></p>  7.2.1. Комиссия Администратора включена в окончательную стоимость,
        отображаемую для Покупателя, и не выделяется отдельно.
        <p><br/></p>  7.2.2. Повар соглашается, что он получает выплату за вычетом комиссии, и не вправе
        требовать разъяснений или изменения порядка расчётов.
        <p><br/></p> 7.3. Стоимость доставки: Стоимость доставки отображается отдельно при
        оформлении заказа. Покупатель видит стоимость доставки как отдельную позицию,
        добавляемую к стоимости заказа.
        <p><br/></p>  7.4. Изменение цен: Администратор вправе изменять механизм расчёта цен и
        комиссий в одностороннем порядке, уведомляя Повара путём публикации обновлённых
        условий на онлайн Платформе. Продолжение использования платформы после изменения
        условий означает полное согласие с новыми правилами. <p><br/></p> 7.5. Исключение ответственности: Администратор не несёт ответственности за
        любые убытки или ожидания Повара, связанные с ценообразованием и комиссией. Все
        расчёты ведутся согласно условиям, установленным на момент оформления заказа.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ УСЛОВИЙ ПРАВИЛ</h3>
      <strong>8.1. Повар несет ответственность за:</strong>
      <p>
        <p><br/></p>8.1.1. Качество приготовленных Продуктов питания – все продукты должны
        соответствовать заявленным стандартам качества и требованиям онлайн Платформы.
        <p><br/></p> 8.1.2. Соблюдение сроков выполнения заказов – повар обязан готовить и передавать
        заказы в установленные сроки.
        <p><br/></p>8.1.3. Точность информации, предоставленной Покупателям — Повар обязан
        обеспечивать актуальность и точность информации о Продуктах питания, включая
        ингредиенты, порции, цены и прочие важные детали.
        <p><br/></p> 8.1.4. Соблюдение санитарных норм – повар обязан соблюдать санитарные и
        гигиенические требования в соответствии с законодательством Республики Казахстан.
        <p><br/></p>8.1.5. Надлежащее хранение и упаковку продуктов – если нарушение упаковки
        привело к порче заказа, повар несёт ответственность за возврат или компенсацию.
        <p><br/></p> 8.2. Ответственность за нарушение:
        <p><br/></p>• Нарушение этих требований может привести к временной приостановке доступа
        Повара к онлайн Платформе, наложению штрафных санкций или прекращению
        сотрудничества в одностороннем порядке.
        <p><br/></p>• Повторные или серьёзные нарушения могут привести к постоянному удалению
        профиля Повара без права на восстановление.
        <p><br/></p>• Повар обязан возместить Покупателю убытки и ущерб, понесённые в результате
        нарушения обязательств по Публичной оферте и настоящим Правилам, а также
        компенсировать вред, причинённый жизни и здоровью, и моральный вред.
        <p><br/></p>• Повар несет прямую ответственность перед Покупателем и уполномоченными
        органами в случае неисполнения и/или ненадлежащего исполнения Поваром обязательств
        по продаже Покупателю Продуктов питания надлежащего качества, в том числе в связи с
        предоставлением последним недостоверных и/или недействительных данных о себе, а
        также за невыполнение им условий Публичной оферты и настоящих Правил.
        <p><br/></p> 8.3. Ответственность Администратора:
        <p><br/></p>• Администратор не несёт ответственности за некачественную продукцию,
        нарушение сроков или санитарных норм Повара.
        <p><br/></p>• В случае претензий покупатели должны обращаться напрямую к Повару.
        <p><br/></p> • Администратор не несет ответственности в случае неисполнения и/или
        ненадлежащего исполнения Поваром обязательств по продаже Покупателю Продуктов
        питания надлежащего качества, в том числе в связи с предоставлением последним
        недостоверных и/или недействительных данных о себе, а равно невыполнение им условий
        настоящей Публичной оферты, при этом за такие нарушения прямая ответственность лежит
        непосредственно на Поваре.
        <p><br/></p> 8.4. Определение размера компенсации:
        <p><br/></p> • Размер штрафов, убытков и компенсаций определяется Администратором или
        Покупателями в зависимости от тяжести нарушения.
        <p><br/></p> • Администратор имеет право удерживать суммы штрафов из выплат повару.
        Администратор оставляет за собой право произвести расчет суммы компенсации на
        основании фактических убытков, а также потенциальных издержек, связанных с
        урегулированием претензий Покупателей и восстановлением репутации Администратора и
        онлайн Платформы. <p><br/></p> 8.5. Дополнительные санкции:
        <p><br/></p> • В случае неоднократных нарушений Администратор может применить более
        строгие меры, включая запрет на регистрацию нового аккаунта.• Платформа вправе отказать повару в дальнейшем сотрудничестве без объяснения
        причин, если выявлены серьёзные нарушения.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">9. ЗАЩИТА КОНФИДЕНЦИАЛЬНОЙ ИНФОРМАЦИИ</h3>
      <strong>9.1. Конфиденциальность информации:</strong>
      <p><p><br/></p>9.1.1. Повар обязуется не разглашать и не использовать в личных целях или для
        передачи третьим лицам любую конфиденциальную информацию, полученную в ходе
        сотрудничества с Администратором или через онлайн Платформу, включая, но не
        ограничиваясь:
        <p><br/></p> • Коммерческой информацией о деятельности Администратора, об онлайн
        Платформе и ее Покупателях, Поварах и бизнес-процессах.
        <p><br/></p>• Личными данными Покупателей (имена, контактные данные, предпочтения в еде,
        история заказов, адрес и другое).
        <p><br/></p> • Внутренними документами и материалами Администратора и онлайн
        Платформы, которые могут включать технические, маркетинговые, финансовые и
        операционные данные.
        <p><br/></p> 9.1.2. Повар обязан принимать все необходимые меры для защиты конфиденциальной
        информации от утечек, взлома, передачи третьим лицам и использования не по назначению.
        <p><br/></p>9.2. Обработка и защита персональных данных:
        <p><br/></p>9.2.1. Администратор обрабатывает персональные данные Повара в рамках работы
        Платформы, обеспечивая их защиту в соответствии с законодательством.
        <p><br/></p> 9.2.2. Персональные данные Повара могут использоваться исключительно в целях,
        связанных с:
        <p><br/></p>• Политикой Администратора;
        <p><br/></p>• Обеспечением корректной работы онлайн Платформы и предоставлением
        необходимых услуг;
        <p><br/></p>• Улучшением Покупательского опыта, технической поддержки и предоставления
        информации о новых услугах.
        <p><br/></p> 9.2.3. Администратор обязуется не передавать персональные данные Повара третьим
        лицам без его согласия, за исключением случаев, предусмотренных законодательством или
        в рамках Публичной оферты и настоящих Правил (например, передача данных курьерским
        службам).
        <p><br/></p> 9.3. Обязанности Повара по защите данных Покупателей:
        <p><br/></p> 9.3.1. Повар обязуется соблюдать все требования Администратора и онлайн
        Платформы по защите персональных данных Покупателей. Повар не имеет права
        использовать, хранить или передавать данные Покупателей для каких-либо целей, кроме
        как для выполнения заказов через онлайн Платформу.
        <p><br/></p> 9.3.2. Все данные Покупателей, к которым Повар имеет доступ, должны
        обрабатываться с максимальной осторожностью и в соответствии с применимыми законами
        о защите данных. Передача данных Покупателей третьим лицам запрещена, за
        исключением случаев, предусмотренных законодательством или соглашением с
        Администратором.
        <p><br/></p> 9.4. Меры по защите данных и предотвращению утечек:
        <p><br/></p> 9.4.1. В случае обнаружения нарушения конфиденциальности данных (например,
        утечки персональных данных) Администратор и Повар обязаны незамедлительно
        уведомить друг друга о факте нарушения и принять все необходимые меры для
        минимизации ущерба и устранения последствий.
        <p><br/></p>9.4.2. Администратор обязан провести расследование инцидента, определить
        причины утечки и предпринять шаги для предотвращения подобных случаев в будущем.
        <p><br/></p>9.5. Ответственность за утечку данных: Если нарушение конфиденциальности
        данных произошло по вине Повара (например, вследствие неосторожного обращения сданными или их умышленного разглашения), он несет полную ответственность за все
        последствия, включая возможные юридические и финансовые санкции.</p>

      <h3 className="font-bold text-2xl mt-4 text-center">10. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТЕННОСТЬ</h3>
      <strong>10.1. Права на контент:</strong>
      <p>
        <p><br/></p>10.1.1. Повар сохраняет авторские права на все материалы, созданные им, включая
        рецепты, фотографии блюд и описания. Однако, размещая их на платформе, Повар передаёт
        Администратору исключительное имущественное право на использование, редактирование
        и распространение этих материалов в любых целях, связанных с деятельностью онлайн
        Платформы, включая маркетинг, рекламу и продвижение.
        <p><br/></p> 10.1.2. Администратор оставляет за собой право редактировать и использовать
        загруженные Поваром фотографии и другие материалы для улучшения визуального
        представления, адаптации к маркетинговым кампаниям и соответствия стандартам качества
        онлайн Платформы.
        <p><br/></p>10.2. Использование логотипов и бренда:
        <p><br/></p>10.2.1. Администратор имеет право использовать профиль Повара, его логотип,
        название и бренд в маркетинговых материалах, рекламных кампаниях и любых
        промоматериалах, связанных с продвижением онлайн Платформы и услуг Повара.
        <p><br/></p>10.2.2. Повар соглашается с тем, что его блюда, фотографии и любые загруженные им
        материалы могут быть использованы в рекламных видеороликах, баннерах, социальных
        сетях и других маркетинговых каналах Администратора без необходимости получения
        дополнительного согласия. Использование этих материалов не должно наносить ущерб
        деловой репутации Повара.
        <p><br/></p> 10.3. Фотографии Повара:
        <p><br/></p>10.3.1. Повар имеет возможность по своему желанию загрузить фотографию для
        своего профиля на онлайн Платформе. Эта фотография может быть использована в
        материалах Платформы без необходимости отдельного разрешения, если Повар загрузил её
        <p><br/></p> сам.10.3.2. Загрузка фотографии Повара не является обязательной, однако она может
        способствовать увеличению доверия Покупателей и повышению популярности
        предложений Повара на онлайн Платформе.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">11. МАРКЕТИНГОВАЯ ПОДДЕРЖКА</h3>

      <p>
        <p><br/></p>11.1. Рекламные материалы: Администратор оставляет за собой право создавать
        рекламные материалы, в которых могут быть использованы фотографии Продуктов
        питания Поваров, их профили, а также логотипы и названия. Эти материалы могут
        применяться для продвижения услуг онлайн Платформы в социальных сетях, на сайте и в
        других рекламных каналах без необходимости согласования с Поваром.
        <p><br/></p>11.2. Продвижение Продуктов питания:
        <p><br/></p>11.2.1. Администратор вправе проводить маркетинговые акции, скидки и
        специальные предложения для увеличения продаж Продуктов питания Поваров и
        привлечения Покупателей. Участие Повара в таких акциях может осуществляться на
        усмотрение Администратора.
        <p><br/></p>11.2.2. Администратор может предоставлять рекомендации Поварам по улучшению
        продаж и повышению привлекательности их Продуктов питания, используя анализ
        поведения Покупателей и их отзывы. Эти рекомендации помогут Поварам адаптировать
        свои предложения к предпочтениям Покупателей и улучшить видимость на онлайн
        Платформе.
        <p><br/></p>11.3. Использование имени и бренда Повара: Администратор имеет право
        использовать имя, бренд и отзывы Повара в маркетинговых целях, включая, но не
        ограничиваясь рекламой Платформы и продвижением конкретных Поваров. Повар
        соглашается на такое использование при регистрации на платформе. В случае возражений
        Повар может уведомить Администратора, однако Администратор оставляет за собой право
        самостоятельно принимать решения о дальнейшем использовании данных в рамках
        маркетинговой стратегии.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">12. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ В ПРАВИЛА</h3>
      <p>
        <p><br/></p>12.1. Право на изменение настоящих Правил: Администратор оставляет за собой
        право вносить изменения и дополнения в настоящие Правила.
        <p><br/></p> 12.2. Уведомление о внесении изменений: Изменения и дополнения вступают в силу
        с момента их публикации на онлайн Платформы.
        <p><br/></p>12.3. Принятие изменений Поварами: Если Повар продолжает пользоваться онлайн
        Платформой после вступления изменений в силу, это означает его согласие с новыми
        условиями. Если Повар не согласен с внесёнными изменениями, он имеет право прекратить
        пользоваться онлайн Платформой и уведомить об этом Администратора в установленном
        порядке.
      </p>
    </div>
  )
}

export default RulesChef
